import React from 'react';
import CheckBox from '../checkbox';
import Section from '../section';
import TermsConditions from '../documentViewer/termsConditions';
import ClientCaseActions from '../../store/clientCase/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StoreState } from '../../store';
import L from '../literal';

interface TermsCheckBoxProps {
  acceptedTerms: boolean;
  updateAcceptedTerms: (acceptedTerms: boolean) => any;
}

export const TermsCheckBox: React.SFC<TermsCheckBoxProps> = ({
  updateAcceptedTerms,
  acceptedTerms
}) => {
  return (
    <>
      <CheckBox
        label={<L>MODAL_SAVE_DATA__TERMS_CONSENT</L>}
        checked={acceptedTerms}
        onChange={updateAcceptedTerms}
        className="full-width"
      />
      <Section title="" className="terms" id="terms" isCollapsed={true}>
        <TermsConditions />
      </Section>
    </>
  );
};

export default connect(
  ({ clientCase }: StoreState) => ({
    ...clientCase
  }),
  dispatch => bindActionCreators(ClientCaseActions, dispatch)
)(TermsCheckBox);
