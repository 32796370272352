/* tslint:disable */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  GPlusIcon,
  EmailIcon
} from '../../assets/icons';
import LegalTermsLink from '../legalTermsLink';
import PrivacyPolicyLink from '../privacyPolicyLink/';
import { registerInteraction } from '../../utilities/analytics';
import L from '../literal';

export class Footer extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { openModal: '' };
  }
  render() {
    const currentPage = document.location.origin;
    var Fb = 'https://www.facebook.com/sharer.php?u=' + currentPage;
    var Twitter = 'https://twitter.com/intent/tweet?url=' + currentPage;
    var LinkedIn =
      'https://www.linkedin.com/shareArticle?mini=true&url=' + currentPage;
    var GPlus = 'https://plus.google.com/share?url=' + currentPage;
    var eSubj = encodeURI('Explore');
    var eBody = encodeURI(currentPage);
    var EmailSharing = 'mailto:explore@alfalaval.com?subject=' + eSubj + '&body=' + eBody;
    return (
      <footer className="text-muted">
        <div className="footer-top">
          <div className="container social-links text-right">
            <div className="row">
              <div className="footer-social">
                <div>
                  <L>SHARE</L>
                </div>
                <div>
                  <a href={Fb} target="_blank" className="footer-social__icons" rel="noopener">
                    <FacebookIcon />
                  </a>
                  <a href={Twitter} target="_blank" className="footer-social__icons" rel="noopener">
                    <TwitterIcon />
                  </a>
                  <a href={LinkedIn} target="_blank" className="footer-social__icons" rel="noopener">
                    <LinkedinIcon />
                  </a>
                  <a href={GPlus} target="_blank" className="footer-social__icons" rel="noopener">
                    <GPlusIcon />
                  </a>
                  <a href={EmailSharing} className="footer-social__icons" onClick={() => registerInteraction('Click-Email','explore@alfalaval.com')}>
                    <EmailIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-middle">
          <div className="container">
            <div className="row">
              <div className="col-md-4 links-block">
                <h4 className="footer-info">
                  <L>FOOTER__QUICK_LINKS</L>
                </h4>
                <ul>
                  <li>
                    <a target="_blank" href="http://www.alfalaval.com/about-us/our-company/" rel="noopener">
                      <L>FOOTER__ABOUT_US</L>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="http://www.alfalaval.com/media/here-magazine/" rel="noopener">
                      <L>FOOTER__HERE_MAGAZINE</L>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 links-block">
                <h4 className="footer-info">
                  <L>FOOTER__POPULAR_INDUSTRY_PAGES</L>
                </h4>
                <ul>
                  <li>
                    <a target="_blank" href="http://www.alfalaval.com/industries/chemicals/petrochemicals/" rel="noopener">
                      <L>FOOTER__PETROCHEMICALS</L>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="http://www.alfalaval.com/industries/energy-and-utilities/biofuels/" rel="noopener">
                      <L>FOOTER__BIOFUELS</L>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="http://www.alfalaval.com/industries/energy-and-utilities/oil-and-gas" rel="noopener">
                      <L>FOOTER__OIL_AND_GAS</L>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 links-block">
                <h4 className="footer-info">
                  <L>FOOTER__POPULAR_PRODUCT_PAGES</L>
                </h4>
                <ul>
                  <li>
                    <a target="_blank" href="http://www.alfalaval.com/products/separation/centrifugal-separators/separators" rel="noopener">
                      <L>FOOTER__CENTRIFUGAL_SEPARATORS</L>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="http://www.alfalaval.com/lube-oil-cleaning/" rel="noopener">
                      <L>FOOTER__LUBE_OIL_CLEANING</L>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-4 copyright">
                &copy; {new Date().getFullYear()}, Alfa Laval
              </div>
              <div className="col-md-8 footer-policy">
                <PrivacyPolicyLink />
                <a target="_blank" href="http://www.alfalaval.com/legal/cookies-policy" rel="noopener">
                  <L>COOKIES_POLICY</L>
                </a>
                <LegalTermsLink />
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
