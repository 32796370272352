import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TermsConditions from '../documentViewer/termsConditions';
import ModalActions from '../../store/modal/actions';

interface LegalTermsModalProps {
  modalActions: typeof ModalActions;
}

const LegalTermsModal: React.SFC<LegalTermsModalProps> = ({ modalActions }) => (
  <div className="modal-form legal-modal wrap-modal-buttons">
    <TermsConditions />
  </div>
);

export default connect(null, dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch)
}))(LegalTermsModal);
