import React from 'react';
import classNames from 'classnames';
import Ionicon from 'react-ionicons';

interface IconTextProps {
  className?: string;
  icon?: string;
  customIcon?: JSX.Element;
  text: string | React.ReactNode;
}

const IconText: React.SFC<IconTextProps> = ({
  className,
  icon,
  customIcon,
  text
}) => (
  <span className={classNames('icon-text', className)}>
    {customIcon ? customIcon : <Ionicon className="icon-text-icon" icon={icon} />}
    <span className="icon-text-label">{text}</span>
  </span>
);

export default IconText;
