import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../store";
import { replacePlaceholders } from "../../utilities/translations";

const Literal = ({
  children: key,
  translations,
  replacements,
  preserveWraps = false,
}) => {
  let value = translations[key] || `[${key}]`;

  if (replacements) {
    value = replacePlaceholders(value, replacements);
    if (typeof value !== "string") {
      return <>{value}</>;
    }
  }

  if (preserveWraps) {
    return <span style={{ whiteSpace: "pre-wrap" }}>{value}</span>;
  }

  return value;
};

export default connect(({ language }: StoreState) => ({
  translations: language.translations,
}))(Literal);
