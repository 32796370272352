import React from 'react';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import TopBar from './topBar';

export default function Nav() {
  return (
    <>
      <TopBar />
      <div className="navbar-joyride-hack">
        <header className="navbar">
          <nav>
            <div className="container">
              <Link className="navbar-brand" to="/">
                <img src={logo} alt="Alfa Laval Logo" />
              </Link>
            </div>
          </nav>
        </header>
      </div>
    </>
  );
}
