import React from 'react';
import classNames from 'classnames';
import PropertyList from '../../components/propertyList';
import SummarySection from './summarySection';
import ProgressSteps from '../../components/progressSteps';
import ProgressStepper from '../../components/progressStepper';
import SubHero from './subHero';
import FileUpload from '../../components/fileUpload';
import SaveAndClear from '../../components/saveAndClear';
import {
  getHazardousProcessAspectProperties,
  getProcessAspectDescription
} from '../../utilities/summary';
import SeparationTask from '../../components/separationTask';
import { ProcessDataState } from '../../store/processData/constants';
import { CaseFilesState } from '../../store/caseFiles/constants';
import { ProcessAspectState } from '../../store/processAspect/constants';
import { ClientCaseState } from '../../store/clientCase/constants';
import { RecommendedSeparatorState } from '../../store/recommendedSeparator/constants';
import { ProcessInfo } from '../../store/process/constants';
import CaseFileActions from '../../store/caseFiles/actions';
import LabTestActions from '../../store/labTest/actions';
import ModalActions from '../../store/modal/actions';
import SeparationPropertiesSummary from '../../components/summary/separationPropertiesSummary';
import PerformanceTargetSummary from '../../components/summary/performanceTargetSummary';
import SeparabilitySummary from '../../components/summary/separabilitySummary';
import Separator from '../../components/separator';
import { LabTestState } from '../../store/labTest/constants';
import CheckBox from '../../components/checkbox';
import ChooseSeparator from '../../components/chooseSeparator';
import SummaryPerformanceGraph from './summaryPerformanceGraph';
import AnalysisTable from '../labTest/analysisTable';
import LabTestGraph from '../labTest/labTestGraph';
import L from '../../components/literal';
import useLiteral from '../../hooks/useLiteral';

export interface SummaryProps extends ProcessDataState {
  activeProcess: ProcessInfo;
  allowFinalizing: boolean;
  caseFileActions: typeof CaseFileActions;
  caseFiles: CaseFilesState;
  clientCase: ClientCaseState;
  disallowFileUploads: boolean;
  hasLabTests: boolean;
  labTest: LabTestState;
  labTestActions: typeof LabTestActions;
  modalActions: typeof ModalActions;
  processAspects: ProcessAspectState;
  processData: ProcessDataState;
  recommendedSeparator: RecommendedSeparatorState;
}

const Summary: React.SFC<SummaryProps> = ({
  activeProcess,
  allowFinalizing,
  caseFileActions,
  caseFiles,
  clientCase,
  disallowFileUploads,
  hasLabTests,
  labTest,
  labTestActions,
  modalActions,
  processAspects,
  processData,
  recommendedSeparator
}) => {
  const fileUploadSaveFirstInfo = useLiteral('FILE_UPLOAD_SAVE_FIRST')

  return (
    <div className="summary-page">
      <div className="container">
        <ProgressSteps />
      </div>
      <SubHero />

      <SummarySection className="summary-separation-task-recommended">
        <SeparationTask summaryTitle={<L>PROGRESS_STEP__SEPARATION_TASK</L>} {...activeProcess!} />
        <Separator
          nameTranslationKey={recommendedSeparator.descriptionTranslationKey}
          title={<L>RECOMMENDATION</L>}
          separatorSize={recommendedSeparator.separatorSize}
        />
      </SummarySection>

      {/* Liquid/Solid properties */}
      <SummarySection className="summary-property-lists">
        <SeparationPropertiesSummary
          activeProcess={activeProcess!}
          processData={processData}
          propertyListProps={{ layoutDirection: 'horizontal-two-columns' }}
        />
      </SummarySection>

      {/* Performance target and separability data */}
      <SummarySection
        className={classNames('summary-perf-sep-data', {
          'has-labtests': hasLabTests,
          'no-labtests': !hasLabTests
        })}
      >
        <div className="summary-section-box">
          <PerformanceTargetSummary
            activeProcess={activeProcess!}
            processData={processData}
            includeOtherPerformanceAspects={true}
            propertyListProps={{ propertyLayout: 'stacked' }}
          />
        </div>
        {!hasLabTests && (
          <div
            className={classNames('summary-section-box', {
              'extended-separability':
                activeProcess!.separateLiquids && activeProcess!.separateSolids
            })}
          >
            <SeparabilitySummary
              activeProcess={activeProcess!}
              processData={processData}
              propertyListProps={{
                propertyLayout: 'stacked',
                layoutDirection: 'horizontal-two-columns'
              }}
            />
          </div>
        )}
      </SummarySection>

      {/* Performance graph */}
      {!hasLabTests && (
        <SummarySection
          className={classNames({
            'graph-toggle-section': activeProcess.id === 'liquid-liquid-solid'
          })}
        >
          <h3 className="summary-header">
            <L>PERFORMANCE_GRAPH</L>
          </h3>
          <SummaryPerformanceGraph
            processData={processData}
            activeProcess={activeProcess}
          />
        </SummarySection>
      )}

      {hasLabTests && (
        <SummarySection>
          <h3 className="summary-header">
            {labTest.isSpinTest ? <L>LAB_TEST__TEST_ANALYSIS__SPIN_TITLE</L> : <L>LAB_TEST__TEST_ANALYSIS__GRAVITY_TITLE</L>}
          </h3>
          <div className="labtest-padding">
            <LabTestGraph
              Ae={labTest.Ae}
              isSpinTest={labTest.isSpinTest}
              tests={labTest.tests}
            />
            <div className="pb-4">
              {labTest.tests.map(test => (
                <CheckBox
                  key={test.id}
                  label={test.name}
                  checked={test.active}
                  className={`labtest-toggle col-md-12 col-lg-4 ${test.colorId}`}
                  onChange={() => labTestActions.toggleTest(test)}
                />
              ))}
            </div>
            <ChooseSeparator onSelect={labTestActions.selectSeparatorSize} />
            <AnalysisTable
              allowDelete={false}
              tests={labTest.tests}
              datapointRange={labTest.datapointRange}
              datapointRangeUnit={labTest.datapointRangeUnit}
              onRemoveTest={labTestActions.removeTest}
            />
          </div>
        </SummarySection>
      )}

      {/* Process Aspects */}
      <SummarySection>
        <h3 className="summary-header">
          <L>PROGRESS_STEP__PROCESS_ASPECTS</L>
        </h3>
        <div className="process-aspects-summary-box">
          <PropertyList
            propertyLayout="stacked-indented"
            properties={getProcessAspectDescription(processAspects)}
          />
        </div>
        {processAspects.isHazardousArea && (
          <PropertyList
            className="half-width-on-desktop process-aspects-hazardous-properties"
            properties={getHazardousProcessAspectProperties(processAspects)}
          />
        )}
      </SummarySection>

      {/* File Upload */}
      <SummarySection
        className={classNames('dark', {
          'disabled-file-section': !clientCase.caseId
        })}
        data-disabled-text={fileUploadSaveFirstInfo}
      >
        <h3 className="summary-header">
          <L>FILE_UPLOAD</L>
        </h3>
        <span className="file-upload-description">
          <L>FILE_UPLOAD_INFO</L>
        </span>
        <FileUpload
          files={caseFiles.attachments}
          disallowFileUploads={disallowFileUploads}
          actions={caseFileActions}
        />
      </SummarySection>
      <SaveAndClear />
      <ProgressStepper allowFinalizing={allowFinalizing} />
    </div>
  );
}

export default Summary;
