import React from 'react';
import { LoadingSpinner } from '../../assets/icons';

const Loader = () => (
  <div className="loading-overlay">
    <LoadingSpinner />
    <div className="loader-text"><h2>Just a second...</h2></div>
  </div>
);

export default Loader;
