import React from 'react';
import { connect } from 'react-redux';
import { RouterProps, Redirect } from 'react-router';
import { StoreState } from '../../store';

interface ValidatorProps extends RouterProps {
  processHasStarted: boolean;
}

const EnsureProcessHasStarted = (Component: any) => {
  const Validator: React.SFC<ValidatorProps> = ({ processHasStarted, ...restProps }) =>
    processHasStarted ? <Component {...restProps} /> : <Redirect to="/process-selection" />;

  return connect(({ process, progress }: StoreState) => ({
    processHasStarted: progress.processHasStarted && process.activeProcessId
  }))(Validator as any);
};

export default EnsureProcessHasStarted;
