import {
  CLEAR_DATA,
  UPDATE_CLIENTCASE_FIELD,
  FETCH_SAVE_CLIENTCASE_SUCCESS,
  RESTORE_DATA
} from '../actionTypes';

import {
  ClientCaseState,
  UpdateClientCaseAction,
  SaveClientCaseAction
} from './constants';
import { StoreState } from '..';
import createActionHandler from '../createActionHandler';

const initialState: ClientCaseState = {
  caseId: '',
  firstName: '',
  lastName: '',
  email: '',
  companyName: '',
  phoneNumber: '',
  acceptedTerms: false,
  acceptedPrivacyPolicy: false
};

const actionHandlers = {
  [CLEAR_DATA]: (state: ClientCaseState): ClientCaseState => ({
    ...initialState
  }),

  [RESTORE_DATA]: (state: ClientCaseState, { clientCase: restoreData }: StoreState): ClientCaseState => ({
    ...state,
    ...restoreData
  }),

  [FETCH_SAVE_CLIENTCASE_SUCCESS]: (state: ClientCaseState, { caseId }: SaveClientCaseAction): ClientCaseState => ({
    ...state,
    caseId
  }),

  [UPDATE_CLIENTCASE_FIELD]: (state: ClientCaseState, { property, value }: UpdateClientCaseAction): ClientCaseState => ({
    ...state,
    [property]: value
  })
};

export const clientCase = createActionHandler(initialState, actionHandlers);
