import React from 'react';
import { SigmaGraphSmall, SigmaGraphMedium, SigmaGraphBig } from '../../assets/icons';
import L from '../literal';

interface SigmaGraphProps {
  distribution?: string;
  dv50?: string;
}
class SigmaGraph extends React.Component<SigmaGraphProps> {
  isValid = () => {
    if (this.props.dv50 && (Number.parseFloat(this.props.dv50!) !== NaN) && // eslint-disable-line use-isnan
      this.props.distribution && (Number.parseFloat(this.props.distribution) !== NaN)) { // eslint-disable-line use-isnan
      return true;
    }
    return false;
  }
  render() {
    if (!this.isValid()) {
      return null;
    }
    const dv50 = Number.parseFloat(this.props.dv50!);
    const sigma = Number.parseFloat(this.props.distribution!);
    if (!(sigma >= 0.2 && sigma <= 1.2)) {
      return null;
    }
    // =ROUNDDOWN(E1*EXP(-2,85*E2);1)
    const minValue = Math.round((dv50 * Math.exp(-2.85 * sigma)));
    const maxValue = Math.round((dv50 * Math.exp(2.85 * sigma)));
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-md-6 sigma-block">
              <p>
                <L>SIGMA_GRAPH_INFO</L>
              </p>
            </div>
            <div className="col-md-6 sigma-block">
              {sigma >= 0.2 && sigma < 0.8 && <SigmaGraphSmall />}
              {sigma >= 0.8 && sigma <= 1 && <SigmaGraphMedium />}
              {sigma > 1 && <SigmaGraphBig />}

              <div className="numbers">
                <span>{minValue}</span>
                <span>{dv50}</span>
                <span>{maxValue}</span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SigmaGraph;
