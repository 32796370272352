import React from 'react';
import classNames from 'classnames';
import Ionicon from 'react-ionicons';
import L from '../literal';

interface DataListProps {
  className?: string;
  dataset: Array<object>;
  dataKey: string;
  itemPrefix?: (index: number) => JSX.Element;
  onRemove?: () => any;
  showDelete?: boolean;
  subTitle?: string;
  title?: string;
  unit?: string;
}

const DataList: React.SFC<DataListProps> = ({
  className,
  dataset,
  dataKey,
  itemPrefix,
  onRemove,
  showDelete,
  subTitle,
  title,
  unit
}) => (
  <div className={classNames('data-list', className, { 'is-deleting': showDelete })}>
    <div className="data-list-header">
      {showDelete && (
        <>
          <span className="data-list-remove"><L>REMOVE_THIS_TEST</L></span>
          <Ionicon
            onClick={onRemove}
            color="currentColor"
            className="icon"
            icon="ios-close-circle-outline"
          />
        </>
      )}
      {!showDelete && (
        <>
          <span className="title">{title}</span>
          <span className="sub-title">{subTitle}</span>
        </>
      )}
    </div>
    {dataset.map((item, index) => (
      <div key={index} className="data-list-item">
        {itemPrefix && itemPrefix(index)}
        {item[dataKey]}
        {unit && <span className="data-list-unit">{unit}</span>}
      </div>
    ))}
  </div>
);

export default DataList;
