/*tslint:disable*/
import React from 'react';
import { connect } from "react-redux";
import classNames from 'classnames';
import Chart from '../chart';
import formula from '../../utilities/formula';
import noperformancechart from '../../assets/images/noperformancechart.png';
import { StoreState } from "../../store";

interface PerformanceGraphProps {
  className?: string;
  desiredConcentration?: any;
  primaryDensity: any;
  primaryViscosity: any;
  flowRate: any;
  dropletOrParticleSize: any;
  distribution: any;
  concentration: any;
  secondaryDensity: any;
  concentrationUnit?: string;
  customizedAe: string | undefined;
}

class PerformanceGraph extends React.PureComponent<PerformanceGraphProps> {
  isDataValidForRendering = () => {
    return (
      this.props.primaryDensity &&
      this.props.primaryViscosity &&
      this.props.flowRate &&
      this.props.dropletOrParticleSize &&
      this.props.distribution &&
      this.props.concentration &&
      this.props.secondaryDensity
    );
  }

  getSanitizedInput = (data: PerformanceGraphProps, Ae: number) => {
    return {
      particleConcentration: parseFloat(data.concentration),
      fluidDensity: parseFloat(data.primaryDensity),
      fluidViscosity: parseFloat(data.primaryViscosity) * parseFloat(data.primaryDensity) * 0.000001,
      particleDensity: parseFloat(data.secondaryDensity),
      Ae: this.props.customizedAe ? parseInt(this.props.customizedAe, 10) : Ae,
      Dv50: parseFloat(data.dropletOrParticleSize),
      sigma: parseFloat(data.distribution)
    };
  }

  generateDataSeriesForScale = (Scale: number, multiplier: number) => {
    const data: number[] = [];
    const length = Scale * multiplier;
    for (let i = 0; i < length; i++) {
      data.push((i + 1) / multiplier)
    }
    return data;
  }

  formatter = (value: number) => {
    return `${value.toFixed(5)} ${this.props.concentrationUnit}`;
  }

  labelFormatter = (value: number) => {
    return `Q: ${value.toFixed(1)} m³/h`;
  }

  render() {
    const { className, desiredConcentration, flowRate } = this.props;
    const validData = this.isDataValidForRendering();

    if (!validData) {
      return <img className={classNames('performance-graph', className)} alt='' style={{ width: '80%' }} src={noperformancechart} />;

    }

    const form = new formula();
    const separator = form.flowRateToSize(flowRate);
    const input = this.getSanitizedInput(this.props, separator.Ae);

    const dataSeries = this.generateDataSeriesForScale(separator.Scale, separator.Multiplier);
    const data = dataSeries
      .map((x: number) => {
        return {
          y: Math.max(form.crelativeAnalyticWithPC({ ...input, Q: x / 3600 /*Flow per second*/ }), 0),
          x
        };
      });

    const lineInfo = {
      name: "Centrate Quality",
      data
    };
    const getCentrateQualityString = () => {
      if (this.props.concentrationUnit)
        return `Centrate Quality (${this.props.concentrationUnit})`;
      else
        return 'Centrate Quality';
    };
    return (
      <div className={classNames('performance-graph', className)}>
        <Chart
          XAxisLabel="Q (m³/h)"
          YAxisLabel={getCentrateQualityString()}
          formatter={this.formatter}
          labelFormatter={this.labelFormatter}
          highlightClosestTo={desiredConcentration}
          line={lineInfo}
        />
      </div>
    );
  }
}

export default connect(
  (state: StoreState) => ({
    customizedAe: state.processData.customizedAe,
  })
)(PerformanceGraph);
