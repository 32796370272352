import { useEffect } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../store";

const chatScriptUrl = "https://productguide.alfalaval.com/exports/chat.js";
const chatGroupTag = "explore";
const chatDefaultLanguage = "en";

// TODO: Should not use LiveChat api directly, __al_chat should expose destroy function instead (to decouple chat provider)
interface LiveChatCallFunc {
  (method: string): void;
}

interface AlChatSetupFunc {
  (languageFamily: string, groupTag: string, environment: string): void;
}

declare global {
  interface Window {
    __al_chat: {
      setup: AlChatSetupFunc;
    };

    LiveChatWidget: {
      call: LiveChatCallFunc;
    };
  }
}

interface LiveChatProps {
  chatEnv: string;
  languageCode: string;
}

const LiveChat: React.FunctionComponent<LiveChatProps> = ({ chatEnv, languageCode }) => {
  const languageFamily = languageCode.split("-")[0] || chatDefaultLanguage;

  useEffect(() => {
    const alreadyLoaded = typeof window.LiveChatWidget === "undefined";

    if (alreadyLoaded) {
      var tag = document.createElement("script");
      tag.type = "text/javascript";
      tag.src = chatScriptUrl;
      tag.onload = function () {
        window.__al_chat.setup(languageFamily, chatGroupTag, chatEnv);
      };
      document.getElementsByTagName("head")[0].appendChild(tag);
    } else {
      window.LiveChatWidget.call("destroy");
      window.__al_chat.setup(languageFamily, chatGroupTag, chatEnv);
    }
  }, [chatEnv, languageFamily]);

  return null;
};

export default connect(({ config, language }: StoreState) => ({
  chatEnv: config.chatEnv || "test",
  languageCode: language.languageCode,
}))(LiveChat);
