import React from 'react';
import classNames from 'classnames';

interface TimeUnitProps {
  className?: string;
  value: any;
  unit?: string;
}

const TimeUnit: React.SFC<TimeUnitProps> = ({
  className,
  value,
  unit
}) => (
  <div className={classNames('time-unit', className)}>
    {value}
    <span className="unit">{unit}</span>
  </div>
);

export default TimeUnit;
