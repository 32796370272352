export const LOAD_CONFIG = 'LOAD_CONFIG';
export const ADD_ATTACHMENT = 'ADD_ATTACHMENT';
export const ADD_LABTEST = 'ADD_LABTEST';
export const CLEAR_DATA = 'CLEAR_DATA';
export const CLEAR_SEPARATION_TASK = 'CLEAR_SEPARATION_TASK';
export const FETCH_SAVE_CLIENTCASE_ERROR = 'FETCH_SAVE_CLIENTCASE_ERROR';
export const FETCH_SAVE_CLIENTCASE_REQUEST = 'FETCH_SAVE_CLIENTCASE_REQUEST';
export const FETCH_SAVE_CLIENTCASE_SUCCESS = 'FETCH_SAVE_CLIENTCASE_SUCCESS';
export const FINISH_PROCESS = 'FINISH_PROCESS';
export const HIDE_LOADER = 'HIDE_LOADER';
export const MODAL_OPEN = 'MODAL_OPEN';
export const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT';
export const REMOVE_LABTEST = 'REMOVE_LABTEST';
export const RESTORE_DATA = 'RESTORE_DATA';
export const SELECT_PROGRESS_STEP = 'SELECT_PROCESS_STEP';
export const SELECT_SEPARATION_TASK = 'SELECT_SEPARATION_TASK';
export const SET_LABTEST_SEPARATOR_SIZE = 'SET_LABTEST_SEPARATOR_SIZE';
export const SET_LABTEST_TYPE = 'TOGGLE_LABTEST_TYPE';
export const SHOW_LOADER = 'SHOW_LOADER';
export const TOGGLE_LABTEST = 'TOGGLE_LABTEST';
export const UPDATE_ATTACHMENT = 'UPDATE_ATTACHMENT';
export const UPDATE_CLIENTCASE_FIELD = 'UPDATE_CLIENTCASE_FIELD';
export const UPDATE_PROCESSASPECT_FIELD = 'UPDATE_PROCESSASPECT_FIELD';
export const UPDATE_PROCESSDATA_FIELD = 'UPDATE_PROCESSDATA_FIELD';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const LOAD_TRANSLATIONS = 'LOAD_TRANSLATIONS';
