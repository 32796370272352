import { ADD_ATTACHMENT, REMOVE_ATTACHMENT, CLEAR_DATA, UPDATE_ATTACHMENT, RESTORE_DATA } from '../actionTypes';
import {
  CaseFilesState,
  AddCaseFileAction,
  RemoveCaseFileAction,
  UpdateCaseFileAction
} from './constants';
import { StoreState } from '..';
import createActionHandler from '../createActionHandler';

const initialState: CaseFilesState = {
  maxAttachments: 5,
  attachments: []
};

const actionHandlers = {
  [CLEAR_DATA]: (state: CaseFilesState): CaseFilesState => ({
    ...initialState
  }),

  [RESTORE_DATA]: (state: CaseFilesState, { caseFiles: restoreData }: StoreState): CaseFilesState => ({
    ...state,
    ...restoreData
  }),

  [ADD_ATTACHMENT]: (state: CaseFilesState, { attachment }: AddCaseFileAction): CaseFilesState => ({
    ...state,
    attachments: state.attachments.concat(attachment)
  }),

  [REMOVE_ATTACHMENT]: (state: CaseFilesState, { id }: RemoveCaseFileAction): CaseFilesState => ({
    ...state,
    attachments: state.attachments.filter(attachment => attachment.id !== id)
  }),

  [UPDATE_ATTACHMENT]: (state: CaseFilesState, { id, updatedAttachment }: UpdateCaseFileAction): CaseFilesState => ({
    ...state,
    attachments: [
      ...state.attachments.map(attachment => attachment.id !== id ? attachment : updatedAttachment), // remove the attachment that needs updating
    ]
  })
};

export const caseFiles = createActionHandler(initialState, actionHandlers);
