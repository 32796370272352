import React from 'react';
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { closest } from './helpers';

interface ChartProps {
  formatter?: (value: number) => string;
  highlightClosestTo?: number;
  line: {
    name: string;
    color?: string;
    data: {
      y: number;
      x: number;
    }[];
  };
  labelFormatter?: (value: number) => string;
  XAxisLabel?: string;
  YAxisLabel?: string;
}

export default class Chart extends React.PureComponent<ChartProps> {
  dotStyle = (matchObject?: { y: number; x: number }) => {
    if (matchObject === undefined) {
      return false;
    }

    return (dot: {
      payload: { y: number; x: number };
      cx: number;
      cy: number;
    }) => {
      if (matchObject === undefined) {
        return false;
      }

      if (dot.payload.x === matchObject.x) {
        return <circle key={matchObject.x} r="7" className="recharts-dot" cx={dot.cx} cy={dot.cy} />;
      }

      return false;
    };
  }

  render() {
    const {
      formatter,
      highlightClosestTo,
      labelFormatter,
      line,
      XAxisLabel,
      YAxisLabel
    } = this.props;
    const closestsMatch = (highlightClosestTo && closest(highlightClosestTo, line.data, a => a.y)) || undefined;

    const margins = { top: 20, right: 20, left: 10, bottom: 20 };

    return (
      <ResponsiveContainer>
        <LineChart
          data={[this.props.line]}
          margin={margins}
        >
          <XAxis dataKey="x" type="number">
            <Label value={XAxisLabel} position="bottom" />
          </XAxis>
          <YAxis>
            <Label
              className="y-axis"
              content={(attributes: any) =>
                <text textAnchor="middle" transform={`translate(15,${attributes.viewBox.height / 2 + margins.top}) rotate(-90)`}>{YAxisLabel}</text>}
            />
          </YAxis>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            labelFormatter={labelFormatter}
            formatter={formatter}
          />

          <Line
            dataKey="y"
            data={line.data}
            name={line.name}
            type="natural"
            activeDot={{ r: 7 }}
            dot={this.dotStyle(closestsMatch)}
            stroke={line.color || 'currentColor'}
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
