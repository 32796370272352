import { MODAL_OPEN } from '../actionTypes';
import { ModalAction, MODAL_TYPES } from './constants';

export default {
  showModal,
  closeModal
};

export function showModal(modalType: MODAL_TYPES): ModalAction {
  return {
    type: MODAL_OPEN,
    modalType
  };
}

export function closeModal(): ModalAction {
  return {
    type: MODAL_OPEN,
    modalType: ''
  };
}
