import React from 'react';
import TextBox from '../textBox';
import ClientCaseActions from '../../store/clientCase/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StoreState } from '../../store';
import { ClientCaseState } from '../../store/clientCase/constants';
import { validateEmail, validateNotEmpty } from '../../utilities/validators';
import TermsBlock from '../termsCheckBox';
import PrivacyBlock from './privacyCheckBox';
import L from '../literal';
import useLiteral from '../../hooks/useLiteral';

interface SaveDataFormProps extends ClientCaseState {
  actions: typeof ClientCaseActions;
  url?: string;
  children?: any;
}

const SaveDataForm: React.SFC<SaveDataFormProps> = ({
  actions,
  acceptedTerms,
  acceptedPrivacyPolicy,
  companyName,
  email,
  firstName,
  lastName,
  phoneNumber,
  children
}) => {
  const companyPlaceholder = useLiteral('MODAL_SAVE_DATA__COMPANY_PLACEHOLDER')

  const isEmailValid = validateEmail(email);
  const isFirstNameRequired = validateNotEmpty(firstName);
  const isLastNameRequired = validateNotEmpty(lastName);
  const isCompanyRequired = validateNotEmpty(companyName);
  const isPhoneRequired = validateNotEmpty(phoneNumber);
  const isFormValid =
    acceptedTerms &&
    acceptedPrivacyPolicy &&
    isFirstNameRequired &&
    isLastNameRequired &&
    isCompanyRequired &&
    isPhoneRequired &&
    isEmailValid;

  return (
    <div className="modal-form save-data-form two-column-desktop">
      <span className="full-width form-description">
        <L preserveWraps>MODAL_SAVE_DATA__INFO</L>
      </span>
      <TextBox
        label={<L>FIRST_NAME</L>}
        value={firstName}
        onChange={actions.updateFirstName}
      />
      <TextBox
        label={<L>LAST_NAME</L>}
        value={lastName}
        onChange={actions.updateLastName}
      />
      <TextBox
        label={<L>EMAIL_ADDRESS</L>}
        value={email}
        onChange={actions.updateEmailAddress}
        isInvalid={!isEmailValid}
      />
      <TextBox
        label={<L>PHONE_NUMBER</L>}
        value={phoneNumber}
        onChange={actions.updatePhoneNumber}
      />
      <TextBox
        label={<L>COMPANY</L>}
        value={companyName}
        placeholder={companyPlaceholder}
        onChange={actions.updateCompany}
      />
      <PrivacyBlock />
      <TermsBlock />
      <div className="modal-actions">
        <button
          className="btn-light modal-action-button smallButton"
          disabled={!isFormValid}
          onClick={actions.saveCase}
        >
          <L>MODAL_SAVE_DATA__BUTTON</L>
        </button>
      </div>
    </div>
  );
};

export default connect(
  ({ clientCase }: StoreState) => ({
    ...clientCase
  }),
  dispatch => ({
    actions: bindActionCreators(ClientCaseActions, dispatch)
  })
)(SaveDataForm);
