import React from 'react';
import { connect } from 'react-redux';
import ModalActions from '../../store/modal/actions';
import ProgressActions from '../../store/progress/actions';
import { bindActionCreators } from 'redux';
import L from '../literal';

interface UnsavedDataFormProps {
  modalActions: typeof ModalActions;
  progressActions: typeof ProgressActions;
  redirectTo: string;
}

const UnsavedDataForm: React.SFC<UnsavedDataFormProps> = ({
  modalActions,
  progressActions,
  redirectTo
}) => (
  <div className="modal-form wrap-modal-buttons">
    <span className="form-description">
      <L>MODAL_LEAVE_PAGE__INFO</L>
    </span>
    <div className="modal-actions">
      <button className="btn-light modal-action-button smallButton" onClick={() => progressActions.clearAndNavigate(redirectTo)}>
        <L>MODAL_LEAVE_PAGE__YES</L>
      </button>

      <button className="modal-action-button smallButton" onClick={modalActions.closeModal}>
        <L>MODAL_LEAVE_PAGE__NO</L>
      </button>
    </div>
  </div>
);

export default connect(
  null,
  dispatch => ({
    progressActions: bindActionCreators(ProgressActions, dispatch),
    modalActions: bindActionCreators(ModalActions, dispatch),
  })
)(UnsavedDataForm);
