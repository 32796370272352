import store, { StoreState } from './store';
import history from './routerHistory';
import { SELECT_PROGRESS_STEP } from './store/actionTypes';
import { ProgressAction } from './store/progress/constants';
import ProcessActions from './store/process/actions';
import { registerPageView } from './utilities/analytics';

// Handles back navigation in the browser. Restore state
history.listen((location, action) => {
  const { state = {} } = location;
  const storeState = store.getState() as StoreState;

  if (action === 'POP' || action === 'PUSH') {
    registerPageView();

    let { activeStep, activeProcessId } = state as any;

    if (location.pathname === '/process-selection') {
      activeStep = activeStep || 0;
      activeProcessId = activeProcessId || '';
    }

    // Restore active step if needed
    if (activeStep !== undefined && storeState.progress.activeStep !== activeStep) {
      store.dispatch<ProgressAction>({ type: SELECT_PROGRESS_STEP, activeStep });
    }

    // // Restore active process if needed
    if (activeProcessId !== undefined && storeState.process.activeProcessId !== activeProcessId) {
      store.dispatch<any>(ProcessActions.selectSeparationProcess(activeProcessId));
    }
  }
});
