import React from 'react';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import Separator from '../separator';
import { RecommendedSeparatorState } from '../../store/recommendedSeparator/constants';
import L from '../literal';

interface RecommendedSeparatorProps extends RecommendedSeparatorState {}

export const RecommendedSeparator: React.SFC<RecommendedSeparatorProps> = ({
  separatorSize: recommendedSeparatorSize,
  separators
}) => (
  <div className="recommended-separator">
    <span className="recommended-separator-label">
      <L>RECOMMENDED_SEPARATOR_LABEL</L>
    </span>
    {separators.map(separator => (
      <Separator
        key={separator.nameTranslationKey}
        {...separator}
        isActive={separator.separatorSize === recommendedSeparatorSize}
      />
    ))}
  </div>
);

export default connect(
  ({ recommendedSeparator }: StoreState) => ({
    ...recommendedSeparator
  })
)(RecommendedSeparator);
