import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalActions from '../../store/modal/actions';
import { MODAL_TYPES } from '../../store/modal/constants';
import L from '../literal';

interface LegalModal {
  openModal: () => any;
}

export const LegalTermsLink: React.SFC<LegalModal> = ({ openModal }) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a onClick={openModal}>
    <L>TERMS_AND_CONDITIONS</L>
  </a>
);

export default connect(null, dispatch =>
  bindActionCreators(
    {
      openModal: () => ModalActions.showModal(MODAL_TYPES.LEGAL)
    },
    dispatch
  )
)(LegalTermsLink);
