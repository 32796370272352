import { AnyAction } from 'redux';

interface ActionHandlerInterface<T> {
  [key: string]: (currentState: T, action: any) => T;
}

export default <T>(initialState: T, actionHandlers: ActionHandlerInterface<T>) => {
  return (state = initialState, action: AnyAction) => {
    const actionHandler = actionHandlers[action.type] || (() => state);
    return actionHandler(state, action);
  };
};
