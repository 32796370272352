import React from 'react';
import {
  getSeparabilityData,
  getExtendedSeparabilityData
} from '../../utilities/summary';
import { ProcessDataState } from '../../store/processData/constants';
import { ProcessInfo } from '../../store/process/constants';
import PropertyList from '../propertyList';
import L from '../literal';

interface PropertyListProps {
  layoutDirection?: 'horizontal-two-columns' | 'vertical';
  propertyLayout?: 'flat' | 'stacked' | 'stacked-indented';
}

interface SeparabilitySummaryProps {
  className?: string;
  activeProcess: ProcessInfo;
  processData: ProcessDataState;
  propertyListProps?: PropertyListProps;
}

const SeparabilitySummary: React.SFC<SeparabilitySummaryProps> = ({
  className,
  activeProcess,
  processData,
  propertyListProps
}) => (
  <>
    <h3 className="summary-header">
      <L>SEPARABILITY_DATA</L>
    </h3>
    <PropertyList
      {...propertyListProps}
      properties={getSeparabilityData(activeProcess, processData)}
    />
    {activeProcess.separateLiquids && activeProcess.separateSolids && (
      <>
        <hr className="separability-splitter" />
        <PropertyList
          {...propertyListProps}
          properties={getExtendedSeparabilityData(processData)}
        />
      </>
    )}
  </>
);

export default SeparabilitySummary;
