import React from 'react';
import DocumentViewer from './documentView';

const PrivacyPolicy = () => {
  return (
    <DocumentViewer>
      <h2>Privacy Policy for Alfa Laval website</h2>
      <p>
        Alfa Laval respects your privacy and protects the personal data we process
        about you. The purpose of this policy is to inform you of the types of
        personal data we collect when you register a profile on the website, and the
        steps we take to protect and safeguard your personal data. This policy and its
        principles apply to personal identifiable information that we ask for and you
        provide. By this we mean information that directly or indirectly identifies
        you, such as name, physical address, e-mail address, or other contact details.
      </p>
      <p>
        We aim to process personal data that is adequate, relevant and not excessive
        in relation to the purpose for which it has been collected. We believe in
        being transparent about the types of personal data we process and why we
        collect your data. We only collect and process personal data where we have
        lawful grounds to do so, and in accordance with applicable data protection
        requirements.
      </p>
      <p>
        On request, Alfa Laval will provide you with further information regarding the
        processing and protection of your personal data when visiting Alfa Laval’s
        webshop and/or Alfa Laval’s websites that enable customers and business
        partners to connect. Please see the ”What are your rights” section below for
        more information about your rights.
      </p>

      <h4>Information regarding the processing of your personal data</h4>
      <p>
        When you merely access and browse our websites, we do not require personal
        identifiable information. We may collect limited non-personal information that
        your browser makes available whenever you visit a website, e.g. browser
        language, geographical location data, date and time, and cookies. We use
        non-personal information to better understand user experience and interaction
        with the websites and to further improve our offerings and services. Our
        policy for usage of cookies is available <a href="/cookies">here</a>.
      </p>
      <p>
        When registering a profile on Alfa Laval’s webshop or using the live chat, you
        provide us with certain personal data, such as name and contact details and if
        relevant, the chat history. The personal data will only be collected directly
        from you.
      </p>
      <p>
        The personal data will be stored in the data base operated by Alfa Laval and
        used by Alfa Laval, its subsidiaries and business partners as set out in this
        privacy policy. If you want to de-register your profile, please send your name
        and e-mail to ebusiness@alfalaval.com.
      </p>

      <h4>What is the purpose of processing your personal data?</h4>
      <p>Your personal data is necessary for us and/or our subsidiaries to:</p>
      <ul>
        <li>
          provide you access to the website for our webshop and/or one of our websites
          that enable customers and business partners to connect;
        </li>
        <li>handle any requests for quotes or questions made by you; and</li>
        <li>
          handle any purchase orders placed by you and administrating the customer
          relationship.
        </li>
      </ul>
      <p>
        We and/or our subsidiaries will also use your personal data to contact you
        about products and offers which you may be interest in, including safety
        notices and for marketing purposes.
      </p>

      <h4>What is the legal basis for processing your personal data?</h4>
      <p>
        The processing of your personal data for the purpose of providing you access
        to our websites, handling any quotes or questions or a purchase order made by
        you is based on it being necessary in accordance with your wishes.
      </p>
      <p>
        The processing of your personal data for the purpose of providing you with
        offers or otherwise for marketing purposes is based on your consent to such
        processing. You give your consent by checking the consent box in connection
        with registering your profile. You can at any time withdraw your consent by
        contacting us at ebusiness@alfalaval.com. Please note that this may result in
        us not being able to fulfil our commitments.
      </p>

      <h4>Who has access to your personal data?</h4>
      <p>
        We have implemented appropriate technical and organisational measures to
        protect your personal data against loss or unlawful access etc. The number of
        persons with access to your personal data is limited. Only individuals
        associated that need to process your personal data in accordance with the
        purposes above will have access to your personal data.
      </p>
      <p>
        Your profile information can also be viewed globally by Alfa Laval and its
        subsidiaries for the purposes stated above.
      </p>
      <p>
        If you are a customer that have specifically agreed to share information with
        Alfa Laval business partners in connection with quotes, your name and contact
        information can be viewed globally by the Alfa Laval business partners that
        are connected to one of the websites. The information may be used by such Alfa
        Laval business partners to (i) contact you regarding quotes or questions
        submitted by you to the business partner, and (ii) offer maintenance and other
        services in relation to your Alfa Laval products.
      </p>
      <p>
        We will share your personal data with the external supplier of the website
        services for the purpose of making them available to you.
      </p>
      <p>
        If necessary to achieve the purposes set out above, your personal data may be
        transferred to countries outside of the EU/EEA where the Alfa Laval business
        partners or subsidiaries are located. For transfer of your information outside
        of the EU/EEA, we will ensure compliance with any privacy laws applicable to
        such personal data.
      </p>

      <h4>For how long is your personal data stored?</h4>
      <p>
        Your personal data will be stored for as long as you have a profile registered
        and will be deleted shortly after the de-registration of your profile or after
        completion of the latest purchase order or communication regarding a quote or
        question by you, or for such longer period of time as required to administrate
        any purchase orders and resulting agreements or as required by applicable laws
        (such as for book keeping purposes). You may however request that we delete
        your personal data earlier (see your rights below).
      </p>

      <h4>What are your rights?</h4>
      <p>
        You have the right to know what personal data we are processing regarding you,
        and to request a copy of such data. You are entitled to have incorrect
        personal data regarding you corrected and in some cases you may request that
        we delete your personal data (e.g. if you withdraw your consent). You also
        have the right to object to certain processing of your personal data, and
        request that the processing of your personal data be limited. Please note that
        restriction or deletion of your personal data may result in us not being able
        to fulfil our commitments. You are also entitled to extract your personal data
        in a machine-readable format and to transfer the personal data to another
        controller.
      </p>
      <p>
        Alfa Laval Corporate AB, Reg. No. 556007-7785, PO Box 73, SE-221 00 Lund,
        Sweden, is the controller of the processing of your personal data. If you have
        any queries regarding the processing of your personal data, please write to
        ebusiness@alfalaval.com.
      </p>
      <p>
        If you have any objections or complaints with the way we process your personal
        data, you have the right to report this to the Swedish Data Protection
        Authority (<i>Datainspektionen/Integritetsskyddsmyndigheten</i>) which is the
        supervisory authority for our processing of personal data.
      </p>
      <p>
        Please note that the Alfa Laval Business Partners that you may have agreed to
        share your personal data with will be deemed to be controllers of such
        personal data to the extent they process your personal data. Alfa Laval
        Business Partners will also be deemed to be controllers of personal data
        related to you registered by the Alfa Laval Business Partners in such
        partners’ own profiles on Alfa Laval’s websites. If you have any questions in
        relation to the Alfa Laval Business Partners’ processing of your personal data
        you should contact the relevant Alfa Laval Business Partner directly.
      </p>
    </DocumentViewer>
  );
};

export default PrivacyPolicy;
