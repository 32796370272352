import { Action } from 'redux';

export enum CLIENTCASE_FIELDS {
  ACCEPTED_PRIVACY_POLICY = 'acceptedPrivacyPolicy',
  ACCEPTED_TERMS = 'acceptedTerms',
  COMPANY = 'companyName',
  EMAIL_ADDRESS = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE = 'phoneNumber'
}

export interface ClientCaseState {
  caseId: string;
  [CLIENTCASE_FIELDS.ACCEPTED_PRIVACY_POLICY]: boolean;
  [CLIENTCASE_FIELDS.ACCEPTED_TERMS]: boolean;
  [CLIENTCASE_FIELDS.COMPANY]: string;
  [CLIENTCASE_FIELDS.EMAIL_ADDRESS]: string;
  [CLIENTCASE_FIELDS.FIRST_NAME]: string;
  [CLIENTCASE_FIELDS.LAST_NAME]: string;
  [CLIENTCASE_FIELDS.PHONE]: string;
}

export interface UpdateClientCaseAction extends Action {
  property: string;
  value: string |  boolean;
}

export interface SaveClientCaseAction extends Action {
  caseId: string;
}

export type ClientCaseAction = UpdateClientCaseAction | Action;
