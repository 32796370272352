import React from 'react';
import { FormElement, FormElementProps } from '../formElement';

interface TextAreaProps extends FormElementProps {
  onChange?: (value: string) => any;
  value?: string;
}

const TextArea: React.SFC<TextAreaProps> = ({
  onChange,
  value,
  ...restProps
}) => (
  <FormElement {...restProps} elementClassName="textfield">
    <textarea
      value={value}
      className="native-textarea"
      onChange={e => onChange!(e.target.value)}
    />
  </FormElement>
);

TextArea.defaultProps = {
  onChange: () => null
};

export default TextArea;
