import React from 'react';
import classNames from 'classnames';
import separatorImage from '../../assets/images/separator.png';
import { SeparatorSize } from '../../store/recommendedSeparator/constants';
import L from '../literal';

interface SeparatorProps {
  className?: string;
  isActive?: boolean;
  nameTranslationKey: string;
  onClick?: () => any;
  separatorSize: SeparatorSize;
  title?: string | React.ReactNode;
}

const Separator: React.SFC<SeparatorProps> = ({
  className,
  nameTranslationKey,
  isActive,
  separatorSize,
  title,
  onClick
}) => (
  <div onClick={onClick} className={classNames('separator', className, { active: isActive })}>
    <img src={separatorImage} alt="" className={classNames('separator-image', separatorSize)} />
    {title && <span className="separator-prefix">{title}</span>}
    <span className="separator-name"><L>{nameTranslationKey}</L></span>
  </div>
);

export default Separator;
