/* tslint:disable */
import React from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody
} from 'react-accessible-accordion';
export class Section extends React.Component<any> {
    render() {
        return (
            <SectionAccordionHelper
                title={this.props.title}
                body={
                    () => (
                        <>{this.props.children}</>
                    )
                } />
        );
    }
};

interface ReactChildrenProps {
    children?: any;
    title: string | React.ReactNode;
}
export class BroadSection extends React.Component<ReactChildrenProps> {
    render() {
        return (
            <div className="container">
                <h3 className="broadSection-header">{this.props.title}</h3>
                {this.props.children}
            </div>
        );
    }
}


export class Major extends React.Component<ReactChildrenProps> {
    render() {
        return (
            <div className="container">
                <MajorAccordionHelper
                    title={this.props.title}
                    body={
                        () => (
                            <>
                                {this.props.children}
                            </>
                        )
                    } />
            </div>
        );
    }

}


const SectionAccordionHelper = (props: { title: string, body: () => React.ReactNode }) => {
    return (
        <Accordion accordion={false}>
            <AccordionItem expanded={true}>
                {props.title && (
                    <AccordionItemTitle>
                        <legend className="form-header">
                            {props.title}
                            <div className="accordion__arrow" role="presentation" />
                        </legend>
                    </AccordionItemTitle>
                )}
                <AccordionItemBody>
                    <div className="form-group row">
                        {props.body()}
                    </div>
                </AccordionItemBody>
            </AccordionItem>
        </Accordion>
    )
};
const MajorAccordionHelper = (props: { title: string | React.ReactNode, body: () => React.ReactNode }) => {
    return (
        <div>
            <form className="custom">

                <Accordion accordion={false}>
                    <div className="wrapper-accordion__item">

                        <AccordionItem expanded={true}>
                            <AccordionItemTitle>
                                <legend className="form-header form-header-big">
                                    {props.title}
                                    <div className="accordion__arrow" role="presentation" />
                                </legend>
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                <div className="form-group row">
                                    {props.body()}
                                </div>
                            </AccordionItemBody>
                        </AccordionItem>
                    </div>
                </Accordion>
            </form>
        </div>
    )
};
export const Empty = (props: any) => (<>&nbsp;</>);
export { Field, NumericField } from "./field";
export { Checkbox } from "./checkbox";
export class ReactValueWarning extends React.Component<any> {
    render() {
        if (this.props.visible && this.props.visible()) {
            return (<div className="reactValueWarningValidation">{this.props.children}</div>);
        } else {
            return null;
        }
    }
}
export class ReactValueError extends React.Component<any> {
    render() {
        if (this.props.visible && this.props.visible()) {
            return (<div className="reactValueErrorValidation">{this.props.children}</div>);
        } else {
            return null;
        }
    }
}
