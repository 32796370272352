import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StoreState } from '../../store';
import ProgressActions from '../../store/progress/actions';
import { ProgressState } from '../../store/progress/constants';
import L from '../literal';

interface ProgressStepsProps extends ProgressState {
  actions: typeof ProgressActions;
}

export const ProgressSteps: React.SFC<ProgressStepsProps> = ({
  actions,
  activeStep,
  steps
}) => (
  <ul className="progress-steps">
    {steps.filter(step => !step.hidden).map(({ id, translationKey, activated }) => (
      <li
        key={id}
        onClick={() => actions.selectStep(id)}
        className={classNames(`progress-step progress-step-${id}`, {
          activated,
          active: activeStep === id
        })}
      >
        <L>{translationKey}</L>
      </li>
    ))}
  </ul>
);

export default connect(
  (state: StoreState) => ({
    ...state.progress
  }),
  dispatch => ({
    actions: bindActionCreators(ProgressActions, dispatch)
  })
)(ProgressSteps);
