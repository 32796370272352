import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClientCaseActions from '../../store/clientCase/actions';
import TextBox from '../textBox';
import { StoreState } from '../../store';
import L from '../literal';

const copyToClipBoard = (inputSelector: string): any => {
  const inputElement = document.querySelector(inputSelector) as HTMLInputElement;
  if (!inputElement) {
    return;
  }

  const isReadOnly = inputElement.hasAttribute('readonly');

  if (!isReadOnly) {
    inputElement.setAttribute('readonly', '');
  }

  inputElement.select();
  inputElement.setSelectionRange(0, inputElement.value.length);

  if (!isReadOnly) {
    inputElement.removeAttribute('readonly');
  }

  document.execCommand('copy');
};

interface DataSavedFormProps {
  actions: typeof ClientCaseActions;
  caseId: string;
}
function sendEmail(caseId: string) {
  const urlToCase = `${document.location.origin}/explore/${caseId}`;
  var eSubj = encodeURI('Explore');
  var eBody = encodeURI(urlToCase);
  var emailSharing = 'mailto:?subject=' + eSubj + '&body=' + eBody;

  window.location.href = emailSharing;
}
const DataSavedForm: React.SFC<DataSavedFormProps> = ({
  actions,
  caseId
}) => (
    <div className="modal-form data-saved-form">
      <span className="full-width form-description">
        <L>MODAL_DATA_SAVED__INFO</L>
    </span>
      <TextBox
        id="link-textbox"
        className="link-textbox"
        readOnly={true}
        value={`${document.location.origin}/explore/${caseId}`}
      />
      <div className="modal-actions">
        <button
          className="btn-light modal-action-button smallButton"
          onClick={() => copyToClipBoard('#link-textbox')}
        >
          <L>MODAL_DATA_SAVED__COPY</L>
        </button>
      </div>

      <hr className="modal-section-separator" />

      <div className="modal-actions send-email-actions">
        <button
          className="btn-light modal-action-button smallButton"
          onClick={e => sendEmail(caseId)}
        >
          <L>MODAL_DATA_SAVED__EMAIL_SHARE</L>
        </button>
      </div>
    </div>
  );

export default connect(
  ({ clientCase }: StoreState) => ({
    caseId: clientCase.caseId
  }),
  dispatch => ({
    actions: bindActionCreators(ClientCaseActions, dispatch)
  })
)(DataSavedForm);
