import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalActions from '../../store/modal/actions';
import { MODAL_TYPES } from '../../store/modal/constants';
import L from '../literal';

interface PrivacyModal {
  openModal: () => any;
}

export const PrivacyPolicyLink: React.SFC<PrivacyModal> = ({ openModal }) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a onClick={openModal}>
    <L>PRIVACY_POLICY</L>
  </a>
);

export default connect(null, dispatch =>
  bindActionCreators(
    {
      openModal: () => ModalActions.showModal(MODAL_TYPES.PRIVACY)
    },
    dispatch
  )
)(PrivacyPolicyLink);
