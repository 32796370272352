import { MODAL_OPEN, CLEAR_DATA } from '../actionTypes';
import { ModalState, ModalAction } from './constants';
import createActionHandler from '../createActionHandler';

const initialState: ModalState = {
  modalType: ''
};

const actionHandlers = {
  [CLEAR_DATA]: (state: ModalState): ModalState => ({
    ...initialState
  }),

  [MODAL_OPEN]: (state: ModalState, { modalType, modalArguments }: ModalAction) => ({
    ...state,
    modalType,
    modalArguments
  })
};

export const modal = createActionHandler(initialState, actionHandlers);
