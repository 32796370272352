import React from 'react';
import { withRouter, RouteProps } from 'react-router';

class ScrollToTop extends React.Component<RouteProps> {
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  shouldComponentUpdate (nextProps: RouteProps) {
    return nextProps.location !== this.props.location;
  }

  render () {
    return null;
  }
}

export default withRouter(ScrollToTop as any);
