import React from 'react';
import ReactTooltip from 'react-tooltip';
import Ionicon from 'react-ionicons';
import {
  Section,
  Field,
  NumericField,
  Major,
  Empty,
  ReactValueWarning,
  ReactValueError,
  BroadSection
} from './formHelpers';
import Checkbox from '../checkbox';
import SigmaGraph from '../sigmaGraph';
import ProcessDataActions from '../../store/processData/actions';
import { ProcessDataState } from '../../store/processData/constants';
import PerformanceGraph from '../performanceGraph';
import DontHaveSepData from '../dontHaveSepData';
import L from '../literal';
import GraphCustomization from '../graphCustomization';

interface LiquidLiquidSolidFormState extends ProcessDataState {
  actions: typeof ProcessDataActions;
}

const LiquidLiquidSolidForm: React.SFC<LiquidLiquidSolidFormState> = ({
  actions,
  otherPerformanceAspects,
  primaryLiquidCorrosive,
  primaryLiquidDensity,
  primaryLiquidDensityMeasuredAtTemperature,
  primaryLiquidProcessPressure,
  primaryLiquidProcessTemperature,
  primaryLiquidProductName,
  primaryLiquidTotalFlowRate,
  primaryLiquidViscosity,
  primaryLiquidViscosityMeasuredAtTemperature,
  secondaryLiquidConcentration,
  secondaryLiquidConcentrationAfterSeparation,
  liquidConcentrationUnit,
  secondaryLiquidDensity,
  secondaryLiquidDensityMeasuredAtTemperature,
  secondaryLiquidDistribution,
  secondaryLiquidDropletSize,
  secondaryLiquidProductName,
  solidConcentrationAfterSeparation,
  solidConcentrationInFeed,
  solidConcentrationUnit,
  solidDensity,
  solidDistribution,
  solidDropletSize,
  solidKnownToBeErosive,
  solidsType
}) => (
  <>
    <Major title={<L>FLUID_DATA</L>}>
      <Section title={<L>SPECIFY_PERFORMANCE_TARGET</L>}>
        <NumericField
          title={<L>SECONDARY_LIQUID_CONCENTRATION_AFTER_SEPARATION</L>}
          value={secondaryLiquidConcentrationAfterSeparation}
          change={actions.updateSecondaryLiquidConcentrationAfterSeparation}
          layout="full"
          tooltip={() => <L>SECONDARY_LIQUID_CONCENTRATION_AFTER_SEPARATION_HELP</L>}
          units={['%(v/v)', 'ppm', 'mg/l']}
          allowCustomUnit={true}
          unit={liquidConcentrationUnit}
          changeUnit={actions.updateLiquidConcentrationUnit}
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() => Number(value) <= 0 && value.length}
                >
                  <L>VALIDATION__ZERO_OR_LESS_NOT_ALLOWED</L>
                </ReactValueWarning>
              </>
            );
          }}
        />

        <NumericField
          title={<L>SOLIDS_CONCENTRATION_AFTER_SEPARATION</L>}
          value={solidConcentrationAfterSeparation}
          change={actions.updateSolidConcentrationAfterSeparation}
          layout="full"
          tooltip={() => <L>SOLIDS_CONCENTRATION_AFTER_SEPARATION_HELP</L>}
          units={['%(v/v)', 'ppm', 'mg/l']}
          allowCustomUnit={true}
          unit={solidConcentrationUnit}
          changeUnit={actions.updateSolidConcentrationUnit}
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() => Number(value) <= 0 && value.length}
                >
                  <L>VALIDATION__ZERO_OR_LESS_NOT_ALLOWED</L>
                </ReactValueWarning>
              </>
            );
          }}
        />

        <Field
          title={<L>OTHER_PERFORMANCE_ASPECTS</L>}
          value={otherPerformanceAspects}
          change={actions.updateOtherPerformanceAspects}
          layout="full"
          tooltip={() => <L>OTHER_PERFORMANCE_ASPECTS_HELP</L>}
        />
      </Section>
      <Section title={<L>SPECIFY_PRIMARY_LIQUID_PROPERTIES</L>}>
        <div className="col-md-12">
          <p className="under-title">
            <L>SPECIFY_PRIMARY_LIQUID_PROPERTIES_HELP</L>
          </p>
        </div>
        <NumericField
          title={<L>TOTAL_FLOW_RATE</L>}
          value={primaryLiquidTotalFlowRate}
          change={actions.updatePrimaryLiquidTotalFlowRate}
          layout="half"
          tooltip={() => <L>TOTAL_FLOW_RATE_HELP</L>}
          min={0}
          max={500}
          units={['m³/h']}
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() => Number(value) <= 0 && value.length}
                >
                  <L>VALIDATION__ZERO_OR_LESS_NOT_ALLOWED</L>
                </ReactValueWarning>
                <ReactValueWarning
                  visible={() => Number.parseInt(value, 10) >= 500}
                >
                  <L>VALIDATION__HIGH_FLOW_RATE</L>
                </ReactValueWarning>
              </>
            );
          }}
        />
        <Field
          title={<L>LIQUID_PRODUCT_NAME</L>}
          value={primaryLiquidProductName}
          change={actions.updatePrimaryLiquidProductName}
          layout="half"
          tooltip={() => <L>LIQUID_PRODUCT_NAME_HELP</L>}
        />

        <NumericField
          title={<L>PROCESS_TEMPERATURE</L>}
          value={primaryLiquidProcessTemperature}
          change={actions.updatePrimaryLiquidProcessTemperature}
          layout="half"
          tooltip={() => <L>PROCESS_TEMPERATURE_HELP</L>}
          units={['°C']}
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() => Number(value) <= 0 && value.length}
                >
                  <L>VALIDATION__LOW_TEMP</L>
                </ReactValueWarning>
                <ReactValueWarning
                  visible={() =>
                    Number.parseInt(value, 10) >= 100 &&
                    Number.parseInt(value, 10) <= 200
                  }
                >
                  <L>VALIDATION__HIGH_TEMP</L>
                </ReactValueWarning>
                <ReactValueError visible={() => Number.parseInt(value, 10) > 200}>
                  <L>VALIDATION__EXTREME_TEMP</L>
                </ReactValueError>
              </>
            );
          }}
        />

        <NumericField
          title={<L>PROCESS_PRESSURE</L>}
          value={primaryLiquidProcessPressure}
          change={actions.updatePrimaryLiquidProcessPressure}
          layout="half"
          tooltip={() => <L>PROCESS_PRESSURE_HELP</L>}
          min={0}
          units={['kPa']}
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() =>
                    Number.parseInt(value, 10) >= 50 &&
                    Number.parseInt(value, 10) <= 400
                  }
                >
                  <L>VALIDATION__HIGH_PRESSURE</L>
                </ReactValueWarning>
                <ReactValueError visible={() => Number.parseInt(value, 10) > 400}>
                  <L>VALIDATION__EXTREME_PRESSURE</L>
                </ReactValueError>
              </>
            );
          }}
        />

        <NumericField
          title={<L>DENSITY</L>}
          value={primaryLiquidDensity}
          change={actions.updatePrimaryLiquidDensity}
          layout="half"
          tooltip={() => <L>DENSITY_HELP</L>}
          units={['kg/m³']}
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() => Number.parseInt(value, 10) >= 1100}
                >
                  <L>VALIDATION__HIGH_DENSITY</L>
                </ReactValueWarning>
              </>
            );
          }}
        />

        <NumericField
          title={<L>DENSITY_MEASURED_TEMP</L>}
          value={primaryLiquidDensityMeasuredAtTemperature}
          change={actions.updatePrimaryLiquidDensityMeasuredAtTemperature}
          layout="half"
          tooltip={() => <L>DENSITY_MEASURED_TEMP_HELP</L>}
          min={0}
          units={['°C']}
        />

        <NumericField
          title={<L>VISCOSITY</L>}
          value={primaryLiquidViscosity}
          change={actions.updatePrimaryLiquidViscosity}
          layout="half"
          tooltip={() => <L>VISCOSITY_HELP</L>}
          units={['cSt']}
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() => Number(value) <= 0 && value.length}
                >
                  <L>VALIDATION__ZERO_OR_LESS_NOT_ALLOWED</L>
                </ReactValueWarning>
                <ReactValueWarning
                  visible={() => Number.parseInt(value, 10) >= 100}
                >
                  <L>VALIDATION__HIGH_VISCOSITY</L>
                </ReactValueWarning>
              </>
            );
          }}
        />

        <NumericField
          title={<L>VISCOSITY_MEASURED_TEMP</L>}
          value={primaryLiquidViscosityMeasuredAtTemperature}
          change={actions.updatePrimaryLiquidViscosityMeasuredAtTemperature}
          layout="half"
          tooltip={() => <L>VISCOSITY_MEASURED_TEMP_HELP</L>}
          min={0}
          units={['°C']}
        />
        <div className="col-md-12 cb-wrapper">
          <div className="row">
            <div className="col-md-12">
              <Checkbox
                label={<L>CORROSIVE</L>}
                checked={primaryLiquidCorrosive}
                onChange={actions.updatePrimaryLiquidCorrosive}
                tooltip={<L>CORROSIVE_HELP</L>}
              />
            </div>
          </div>
        </div>
      </Section>
      <Section title={<L>SPECIFY_SECONDARY_LIQUID_PROPERTIES</L>}>
        <div className="col-md-12">
          <p className="under-title">
            <L>SPECIFY_SECONDARY_LIQUID_PROPERTIES_HELP</L>
          </p>
        </div>
        <NumericField
          title={<L>CONCENTRATION_IN_FEED</L>}
          value={secondaryLiquidConcentration}
          change={actions.updateSecondaryLiquidConcentration}
          layout="full"
          tooltip={() => <L>CONCENTRATION_IN_FEED_HELP_LIQUID_LIQUID</L>}
          units={['%(v/v)', 'ppm', 'mg/l']}
          allowCustomUnit={true}
          unit={liquidConcentrationUnit}
          changeUnit={actions.updateLiquidConcentrationUnit}
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() => Number(value) <= 0 && value.length}
                >
                  <L>VALIDATION__ZERO_OR_LESS_NOT_ALLOWED</L>
                </ReactValueWarning>
              </>
            );
          }}
        />

        <Field
          title={<L>LIQUID_PRODUCT_NAME</L>}
          value={secondaryLiquidProductName}
          change={actions.updateSecondaryLiquidProductName}
          layout="half"
          tooltip={() => <L>LIQUID_PRODUCT_NAME_HELP_LIQUID_LIQUID</L>}
        />

        <NumericField
          title={<L>DENSITY</L>}
          units={['kg/m³']}
          value={secondaryLiquidDensity}
          change={actions.updateSecondaryLiquidDensity}
          layout="half"
          tooltip={() => <L>DENSITY_SECONDARY_HELP</L>}
          validation={(value: string) => {
            return (
              <>
                <ReactValueError
                  visible={() =>
                    Number.parseFloat(value) /
                      Number.parseFloat(primaryLiquidDensity!) <
                    0.75
                  }
                >
                  <L>VALIDATION__LARGE_DENSITY_DIFF</L>
                </ReactValueError>
              </>
            );
          }}
          min={0}
        />

        <NumericField
          title={<L>DENSITY_MEASURED_TEMP</L>}
          value={secondaryLiquidDensityMeasuredAtTemperature}
          change={actions.updateSecondaryLiquidDensityMeasuredAtTemperature}
          layout="half"
          tooltip={() => <L>DENSITY_MEASURED_TEMP_HELP</L>}
          min={0}
          units={['°C']}
        />
      </Section>
      <Section title={<L>SPECIFY_SOLIDS_PROPERTIES</L>}>
        <Field
          title={<L>SOLIDS_TYPE</L>}
          value={solidsType}
          change={actions.updateSolidsType}
          layout="half"
          tooltip={() => <L>SOLIDS_TYPE_HELP</L>}
        />

        <NumericField
          title={<L>CONCENTRATION_IN_FEED</L>}
          value={solidConcentrationInFeed}
          change={actions.updateSolidConcentrationInFeed}
          layout="full"
          tooltip={() => <L>CONCENTRATION_IN_FEED_HELP_SOLID_LIQUID</L>}
          min={0}
          units={['%(v/v)', 'ppm', 'mg/l']}
          allowCustomUnit={true}
          unit={solidConcentrationUnit}
          changeUnit={actions.updateSolidConcentrationUnit}
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() => Number(value) <= 0 && value.length}
                >
                  <L>VALIDATION__ZERO_OR_LESS_NOT_ALLOWED</L>
                </ReactValueWarning>
              </>
            );
          }}
        />
        <NumericField
          title={<L>DENSITY</L>}
          value={solidDensity}
          change={actions.updateSolidDensity}
          layout="half"
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() =>
                    Number.parseInt(value, 10) <=
                    Number.parseInt(primaryLiquidDensity!, 10)
                  }
                >
                  <L>VALIDATION__DENSITY_PARTICLE_LOWER_THAN_FLUID</L>
                </ReactValueWarning>
              </>
            );
          }}
          tooltip={() => <L>DENSITY_SOLIDS_HELP</L>}
          min={Number.parseInt(solidDensity!, 10)}
          units={['kg/m³']}
        />
        <Empty />
        <div className="col-md-12 cb-wrapper">
          <div className="row">
            <div className="col-md-12">
              <Checkbox
                label={<L>KNOWN_TO_BE_EROSIVE</L>}
                checked={solidKnownToBeErosive}
                onChange={actions.updateSolidKnownToBeErosive}
                tooltip={<L>KNOWN_TO_BE_EROSIVE_HELP</L>}
              />
            </div>
          </div>
        </div>
      </Section>
    </Major>
    <Major title={<L>SEPARABILITY_DATA</L>}>
      <Section title={<L>SPECIFY_SECONDARY_LIQUID_SEPARABILITY_DATA</L>}>
        <NumericField
          title={<L>DROPLET_SIZE_AS_DV50</L>}
          value={secondaryLiquidDropletSize}
          change={actions.updateSecondaryLiquidDropletSize}
          layout="half"
          tooltip={() => <L preserveWraps>DROPLET_SIZE_AS_DV50_HELP</L>}
          min={0}
          max={1000}
          units={['µm']}
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() => Number(value) <= 0 && value.length}
                >
                  <L>VALIDATION__ZERO_OR_LESS_NOT_ALLOWED</L>
                </ReactValueWarning>
                <ReactValueWarning
                  visible={() =>
                    Number.parseFloat(value) < 0.5 &&
                    Number.parseFloat(value) > 0
                  }
                >
                  <L>VALIDATION__LOW_DROPLET_SIZE</L>
                </ReactValueWarning>
              </>
            );
          }}
        />
        <NumericField
          title={<L>DELTA_DISTRIBUTION</L>}
          value={secondaryLiquidDistribution}
          change={actions.updateSecondaryLiquidDistribution}
          layout="half"
          tooltip={() => <L>DELTA_DISTRIBUTION_HELP_DROPLET</L>}
          step={0.1}
          min={0.2}
          max={1.3}
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() => Number.parseFloat(value) < 0.2}
                >
                  <L>VALIDATION__LOW_DISTRIBUTION</L>
                </ReactValueWarning>

                <ReactValueError visible={() => Number.parseFloat(value) > 1.2}>
                  <L>VALIDATION__HIGH_DISTRIBUTION</L>
                </ReactValueError>
              </>
            );
          }}
        />
        <SigmaGraph
          distribution={secondaryLiquidDistribution!}
          dv50={secondaryLiquidDropletSize!}
        />
        <div className="graph-section broad-section">
          <BroadSection title={<L>PERFORMANCE_GRAPH</L>}>
            <h2>
              <L>PERFORMANCE_GRAPH</L>
              <span
                className="light-header-tooltip"
                data-tip=""
                data-for="performanceInfo"
              >
                <Ionicon
                  className="tooltip-question-icon title"
                  icon="md-help-circle"
                />
              </span>
            </h2>
            <PerformanceGraph
              primaryDensity={primaryLiquidDensity}
              distribution={secondaryLiquidDistribution}
              dropletOrParticleSize={secondaryLiquidDropletSize}
              flowRate={primaryLiquidTotalFlowRate}
              primaryViscosity={primaryLiquidViscosity}
              concentration={secondaryLiquidConcentration}
              secondaryDensity={secondaryLiquidDensity}
              desiredConcentration={secondaryLiquidConcentrationAfterSeparation}
              concentrationUnit={liquidConcentrationUnit}
            />
            <ReactTooltip id="performanceInfo" type="dark" effect="solid">
              <div className="tooltipBox">
                <L preserveWraps>PERFORMANCE_GRAPH_INFO</L>
              </div>
            </ReactTooltip>
          </BroadSection>
        </div>
      </Section>

      <Section title={<L>SPECIFY_SOLIDS_SEPARABILITY_DATA</L>}>
        <NumericField
          title={<L>PARTICLE_SIZE_AS_DV50</L>}
          value={solidDropletSize}
          change={actions.updateSolidDropletSize}
          layout="half"
          tooltip={() => <L preserveWraps>PARTICLE_SIZE_AS_DV50_HELP</L>}
          min={0}
          max={1000}
          units={['µm']}
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() => Number(value) <= 0 && value.length}
                >
                  <L>VALIDATION__ZERO_OR_LESS_NOT_ALLOWED</L>
                </ReactValueWarning>
                <ReactValueWarning
                  visible={() =>
                    Number.parseFloat(value) > 0 &&
                    Number.parseFloat(value) <= 0.2
                  }
                >
                  <L>VALIDATION__LOW_PARTICLE_SIZE</L>
                </ReactValueWarning>
                <ReactValueError
                  visible={() =>
                    Number.parseInt(value, 10) >= 400 &&
                    Number.parseInt(value, 10) <= 1000
                  }
                >
                  <L>VALIDATION__HIGH_PARTICLE_SIZE</L>
                </ReactValueError>
              </>
            );
          }}
        />
        <NumericField
          title={<L>DELTA_DISTRIBUTION</L>}
          value={solidDistribution}
          change={actions.updateSolidDistribution}
          layout="half"
          tooltip={() => <L>DELTA_DISTRIBUTION_HELP_PARTICLE</L>}
          min={0.2}
          max={1.3}
          step={0.1}
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() => Number.parseFloat(value) <= 0.2}
                >
                  <L>VALIDATION__LOW_DISTRIBUTION</L>
                </ReactValueWarning>

                <ReactValueError visible={() => Number.parseFloat(value) > 1.2}>
                  <L>VALIDATION__HIGH_DISTRIBUTION</L>
                </ReactValueError>
              </>
            );
          }}
        />
        <SigmaGraph
          distribution={solidDistribution!}
          dv50={solidDropletSize!}
        />
        <div className="graph-section broad-section">
          <BroadSection title={<L>PERFORMANCE_GRAPH</L>}>
            <h2>
              <L>PERFORMANCE_GRAPH</L>
              <span
                className="light-header-tooltip"
                data-tip=""
                data-for="performanceInfo2"
              >
                <Ionicon
                  className="tooltip-question-icon title"
                  icon="md-help-circle"
                />
              </span>
            </h2>
            <PerformanceGraph
              primaryDensity={primaryLiquidDensity}
              distribution={solidDistribution}
              dropletOrParticleSize={solidDropletSize}
              flowRate={primaryLiquidTotalFlowRate}
              primaryViscosity={primaryLiquidViscosity}
              concentration={solidConcentrationInFeed}
              secondaryDensity={solidDensity}
              desiredConcentration={solidConcentrationAfterSeparation}
              concentrationUnit={solidConcentrationUnit}

            />
            <ReactTooltip id="performanceInfo2" type="dark" effect="solid">
              <div className="tooltipBox">
                <L preserveWraps>PERFORMANCE_GRAPH_INFO</L>
              </div>
            </ReactTooltip>
          </BroadSection>
        </div>
      </Section>

      <Section>
        <div style={{ width: '100%' }}>
          <GraphCustomization />
        </div>
      </Section>
    </Major>
    <div className="accordion container">
      <DontHaveSepData />
    </div>
  </>
);

export default LiquidLiquidSolidForm;
