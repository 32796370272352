import React from 'react';
import { connect } from "react-redux";
import Chart from '../chart';
import formula from '../../utilities/formula';
import caseApi from '../../services/caseApi';
import noperformancechart from '../../assets/images/noperformancechart.png';
import { StoreState } from "../../store";

interface GradeEfficiencyGraphProps {
  primaryDensity: any;
  primaryViscosity: any;
  flowRate: any;
  dropletOrParticleSize: any;
  secondaryDensity: any;
  XAxisLabel: string;
  customizedAe: string | undefined;
}
interface GradeEfficiencyGraphState {
  lineData: {
    y: number;
    x: number;
  }[];
  inputMemoKey: string;
}

class GradeEfficiencyGraph extends React.Component<GradeEfficiencyGraphProps, GradeEfficiencyGraphState> {
  readonly state: GradeEfficiencyGraphState = {
    lineData: [],
    inputMemoKey: ''
  };

  componentDidMount() {
    this.calculateLineData();
  }

  componentDidUpdate() {
    this.calculateLineData();
  }

  calculateLineData() {
    if (!this.isInputValid()) {
      return;
    }

    const input = this.getSanitizedInput();
    const inputMemoKey = JSON.stringify(input);
    if (this.state.inputMemoKey === inputMemoKey) {
      return;
    }

    caseApi.getAlfaSep(input.particleDensity, input.fluidDensity, input.fluidViscosity, input.flowRate, input.Ae).then(lineData => {
      this.setState({ lineData, inputMemoKey });
    }).catch(() => {
      this.setState({ lineData: [], inputMemoKey });
    });
  }
  
  getSanitizedInput = () => {
    const { flowRate } = this.props;
    const form = new formula();
    const separator = form.flowRateToSize(flowRate);
    return {
      particleDensity: parseFloat(this.props.secondaryDensity),
      fluidDensity: parseFloat(this.props.primaryDensity),
      fluidViscosity: parseFloat(this.props.primaryViscosity) * parseFloat(this.props.primaryDensity) * 0.000001,
      flowRate,
      Ae: this.props.customizedAe ? parseInt(this.props.customizedAe, 10) : separator.Ae
    };
  }

  isInputValid = () => {
    return (
      this.props.primaryDensity &&
      this.props.primaryViscosity &&
      this.props.flowRate &&
      this.props.dropletOrParticleSize &&
      this.props.secondaryDensity && parseFloat(this.props.primaryDensity) < parseFloat(this.props.secondaryDensity)
    );
  }

  render() {    
    // show graph faded image if user has not yet inputted necessary data, or api has not yet responded
    if (!this.isInputValid() || this.state.lineData.length === 0) {
      return <img className="performance-graph" alt='' style={{ width: '80%' }} src={noperformancechart} />;
    }

    const labelFormatter = (value: number) => {
      return `${this.props.XAxisLabel}: ${value.toFixed(1)} µm`;
    };
    
    const lineInfo = {
      name: 'Percentage separated (%)',
      data: this.state.lineData
    };
    
    return (
      <div className="performance-graph">
        <Chart
          XAxisLabel={`${this.props.XAxisLabel} (µm)`}
          YAxisLabel="Percentage separated (%)"
          labelFormatter={labelFormatter}
          line={lineInfo}
        />
      </div>
    );
  }
}

export default connect(
  (state: StoreState) => ({
    customizedAe: state.processData.customizedAe,
  })
)(GradeEfficiencyGraph);
