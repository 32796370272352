import {
  LabTestState,
  defaultSpinDatapoints,
  defaultSpinDatapointUnit,
  AddLabTestAction,
  ToggleLabTestAction,
  RemoveLabTestAction,
  SetLabTestAction,
  defaultGravityDatapoints,
  defaultGravityDatapointUnit,
  MAX_LABTESTS,
  SetLabTestSeparatorAction
} from './constants';
import {
  REMOVE_LABTEST,
  CLEAR_DATA,
  RESTORE_DATA,
  ADD_LABTEST,
  TOGGLE_LABTEST,
  SET_LABTEST_TYPE,
  SET_LABTEST_SEPARATOR_SIZE
} from '../actionTypes';
import createActionHandler from '../createActionHandler';
import { StoreState } from '..';

const initialState: LabTestState = {
  availableColors: ['color-1', 'color-2', 'color-3'],
  Ae: 4797,
  datapointRange: defaultSpinDatapoints,
  datapointRangeUnit: defaultSpinDatapointUnit,
  isSpinTest: true,
  reachedMaxTests: false,
  tests: [],
  testType: 'spin'
};

const actionHandlers = {
  [CLEAR_DATA]: (state: LabTestState): LabTestState => ({
    ...initialState
  }),

  [RESTORE_DATA]: (state: LabTestState, { labTest: restoreData }: StoreState): LabTestState => ({
    ...state,
    ...restoreData
  }),

  [SET_LABTEST_SEPARATOR_SIZE]: (state: LabTestState, { Ae, tests }: SetLabTestSeparatorAction): LabTestState => ({
    ...state,
    Ae,
    tests: [...tests]
  }),

  [ADD_LABTEST]: (state: LabTestState, { test }: AddLabTestAction): LabTestState => {
    const [colorId, ...availableColors] = state.availableColors;

    return {
      ...state,
      availableColors,
      tests: [
        ...state.tests,
        // the new test
        {
          ...test,
          colorId
        }
      ],
      reachedMaxTests: (state.tests.length + 1) >= MAX_LABTESTS
    };
  },

  [TOGGLE_LABTEST]: (state: LabTestState, { test }: ToggleLabTestAction): LabTestState => ({
    ...state,
    tests: state.tests.map(testIterator => testIterator !== test ? testIterator : {
      ...test,
      active: !test.active
    })
  }),

  [REMOVE_LABTEST]: (state: LabTestState, { test }: RemoveLabTestAction): LabTestState => ({
    ...state,
    availableColors: [...state.availableColors, test.colorId!],
    tests: state.tests.filter(testIterator => testIterator !== test),
    reachedMaxTests: false
  }),

  [SET_LABTEST_TYPE]: (state: LabTestState, { testType }: SetLabTestAction): LabTestState => {
    const isSpinTest = testType === 'spin';

    return {
      ...initialState,
      isSpinTest,
      testType,
      datapointRange: isSpinTest ? defaultSpinDatapoints : defaultGravityDatapoints,
      datapointRangeUnit: isSpinTest ? defaultSpinDatapointUnit : defaultGravityDatapointUnit
    };
  }
};

export const labTest = createActionHandler(initialState, actionHandlers);
