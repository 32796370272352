import { LOAD_CONFIG } from '../actionTypes';
import { ConfigState, LoadConfigAction } from './constants';

export default {
  loadConfig
};

export function loadConfig(config: ConfigState): LoadConfigAction {
  return {
    type: LOAD_CONFIG,
    config
  };
}
