import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className="errorpage">
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <h1>Sorry, but the page you requested could not be found.</h1>
            <h3>This happens when:</h3>
            <ul>
              <li>You mis-typed the address in your browser.</li>
              <li>The page has been moved or deleted.</li>
              <li>The linked you followed is incorrect or out-of-date.</li>
            </ul>
            <h3>What to do now?</h3>
            <ul>
            <li>
              You can check the spelling of the address you entered in your
              browser and try again.
            </li>
            <li>
              You can go to<Link className="link-blue" to="/">
                Explore's startpage
              </Link>
              to continue your browsing.
            </li>
            <li>
              Or you can
              <a className="link-blue" href="#" onClick={() => window.history.go(-1)}> {/* eslint-disable-line jsx-a11y/anchor-is-valid */}
                go back
              </a>to the previous page to try another link.
            </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
