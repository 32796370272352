import { createStore, applyMiddleware, combineReducers } from 'redux';
import * as reducers from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { AppState } from './app/constants';
import { ConfigState } from './config/constants';
import { CaseFilesState } from './caseFiles/constants';
import { ClientCaseState } from './clientCase/constants';
import { LabTestState } from './labTest/constants';
import { ModalState } from './modal/constants';
import { ProcessAspectState } from './processAspect/constants';
import { ProcessDataState } from './processData/constants';
import { ProcessState } from './process/constants';
import { ProgressState } from './progress/constants';
import { RecommendedSeparatorState } from './recommendedSeparator/constants';
import { LanguageState } from './language/constants';
import middlewares from './middlewares';

export const combinedReducers = combineReducers({
  ...reducers
});

// Need to automate this
export interface StoreState {
  app: AppState;
  config: ConfigState;
  caseFiles: CaseFilesState;
  clientCase: ClientCaseState;
  labTest: LabTestState;
  modal: ModalState;
  process: ProcessState;
  processAspects: ProcessAspectState;
  processData: ProcessDataState;
  progress: ProgressState;
  recommendedSeparator: RecommendedSeparatorState;
  language: LanguageState;
}

export default createStore(
  combinedReducers,
  // JSON.parse(localStorage.getItem('applicationState')!) || {},
  composeWithDevTools(
    applyMiddleware(...middlewares)
  )
);
