import store, { StoreState } from "../store";
import { get } from "../utilities/net";

const getApiUrl = () => (store.getState() as StoreState).config.apiUrl;

export function getConfig() {
  if (process.env.NODE_ENV !== "production") {
    return get("/config-local.json");
  } else {
    return get("/config.json");
  }
}

export function getTranslations(languageCode: string) {
  return get(`${getApiUrl()}/translations?languageCode=${languageCode}`);
}
