import React from 'react';
import classNames from 'classnames';
import Ionicon from 'react-ionicons';

export interface FormElementProps {
  className?: string;
  elementClassName?: string;
  label?: string | React.ReactNode;
  children?: any;
  tooltip?: any;
}

export const FormElement: React.SFC<FormElementProps> = ({
  children,
  className,
  elementClassName,
  label,
  tooltip
}) => (
  <div className={classNames('form-element', className, elementClassName)}>
    {label && (
      <label className="form-element-label">
        {label}
        {tooltip && (
          <span className="custom-tooltip" data-tip={tooltip} data-for="formInfo">
            <Ionicon
              className="tooltip-question-icon"
              icon="md-help-circle"
            />
          </span>
        )}
      </label>
    )}
    {children}
  </div>
);
