import React from 'react';
import ReactTooltip from 'react-tooltip';
import Ionicon from 'react-ionicons';
import {
  Section,
  Field,
  NumericField,
  Major,
  Empty,
  ReactValueWarning,
  ReactValueError
} from './formHelpers';
import Checkbox from '../checkbox';
import SigmaGraph from '../sigmaGraph';
import ProcessDataActions from '../../store/processData/actions';
import { ProcessDataState } from '../../store/processData/constants';
import DontHaveSepData from '../dontHaveSepData';
import PerformanceGraph from '../performanceGraph';
import GradeEfficiencyGraph from '../gradeEfficiencyGraph';
import L from '../literal';
import GraphCustomization from '../graphCustomization';

export interface SolidLiquidFormProps extends ProcessDataState {
  actions: typeof ProcessDataActions;
}

const SolidLiquidForm: React.SFC<SolidLiquidFormProps> = ({
  actions,
  primaryLiquidCorrosive,
  primaryLiquidDensity,
  primaryLiquidDensityMeasuredAtTemperature,
  primaryLiquidDistribution,
  primaryLiquidTotalFlowRate,
  primaryLiquidProductName,
  otherPerformanceAspects,
  particleSize,
  primaryLiquidProcessPressure,
  primaryLiquidProcessTemperature,
  solidConcentrationAfterSeparation,
  solidConcentrationInFeed,
  solidConcentrationUnit,
  solidDensity,
  solidKnownToBeErosive,
  solidsType,
  primaryLiquidViscosity,
  primaryLiquidViscosityMeasuredAtTemperature
}) => (
  <>
    <Major title={<L>FLUID_DATA</L>}>
      <Section title={<L>SPECIFY_PERFORMANCE_TARGET</L>}>
        <NumericField
          title={<L>SOLIDS_CONCENTRATION_AFTER_SEPARATION</L>}
          value={solidConcentrationAfterSeparation}
          change={actions.updateSolidConcentrationAfterSeparation}
          layout="full"
          tooltip={() => <L>SOLIDS_CONCENTRATION_AFTER_SEPARATION_HELP</L>}
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() => Number(value) <= 0 && value.length}
                >
                  <L>VALIDATION__ZERO_OR_LESS_NOT_ALLOWED</L>
                </ReactValueWarning>
              </>
            );
          }}
          units={['%(v/v)', 'ppm', 'mg/l']}
          allowCustomUnit={true}
          unit={solidConcentrationUnit}
          changeUnit={actions.updateSolidConcentrationUnit}
        />
        <Field
          title={<L>OTHER_PERFORMANCE_ASPECTS</L>}
          value={otherPerformanceAspects}
          change={actions.updateOtherPerformanceAspects}
          layout="full"
          tooltip={() => <L>OTHER_PERFORMANCE_ASPECTS_HELP</L>}
        />
      </Section>
      <Section title={<L>SPECIFY_LIQUID_PROPERTIES</L>}>
        <NumericField
          title={<L>FLOW_RATE</L>}
          value={primaryLiquidTotalFlowRate}
          change={actions.updatePrimaryLiquidTotalFlowRate}
          layout="half"
          tooltip={() => <L>FLOW_RATE_HELP_PARTICLE</L>}
          units={['m³/h']}
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() => Number(value) <= 0 && value.length}
                >
                  <L>VALIDATION__ZERO_OR_LESS_NOT_ALLOWED</L>
                </ReactValueWarning>
                <ReactValueWarning
                  visible={() => Number.parseInt(value, 10) >= 500}
                >
                  <L>VALIDATION__HIGH_FLOW_RATE</L>
                </ReactValueWarning>
              </>
            );
          }}
        />
        <Field
          title={<L>LIQUID_PRODUCT_NAME</L>}
          value={primaryLiquidProductName}
          change={actions.updatePrimaryLiquidProductName}
          layout="half"
          tooltip={() => <L>LIQUID_PRODUCT_NAME_HELP_SOLID_LIQUID</L>}
        />
        <NumericField
          title={<L>PROCESS_TEMPERATURE</L>}
          value={primaryLiquidProcessTemperature}
          change={actions.updatePrimaryLiquidProcessTemperature}
          layout="half"
          tooltip={() => <L>PROCESS_TEMPERATURE_HELP</L>}
          units={['°C']}
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() => Number(value) <= 0 && value.length}
                >
                  <L>VALIDATION__LOW_TEMP</L>
                </ReactValueWarning>
                <ReactValueWarning
                  visible={() =>
                    Number.parseInt(value, 10) >= 100 &&
                    Number.parseInt(value, 10) < 200
                  }
                >
                  <L>VALIDATION__HIGH_TEMP</L>
                </ReactValueWarning>
                <ReactValueError visible={() => Number.parseInt(value, 10) > 200}>
                  <L>VALIDATION__EXTREME_TEMP</L>
                </ReactValueError>
              </>
            );
          }}
        />
        <NumericField
          title={<L>PROCESS_PRESSURE</L>}
          value={primaryLiquidProcessPressure}
          change={actions.updatePrimaryLiquidProcessPressure}
          layout="half"
          tooltip={() => <L>PROCESS_PRESSURE_HELP</L>}
          min={0}
          units={['kPa']}
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() =>
                    Number.parseInt(value, 10) >= 50 &&
                    Number.parseInt(value, 10) <= 400
                  }
                >
                  <L>VALIDATION__HIGH_PRESSURE</L>
                </ReactValueWarning>
                <ReactValueError visible={() => Number.parseInt(value, 10) > 400}>
                  <L>VALIDATION__EXTREME_PRESSURE</L>
                </ReactValueError>
              </>
            );
          }}
        />
        <NumericField
          title={<L>DENSITY</L>}
          value={primaryLiquidDensity}
          change={actions.updatePrimaryLiquidDensity}
          layout="half"
          tooltip={() => <L>DENSITY_HELP</L>}
          units={['kg/m³']}
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() => Number.parseInt(value, 10) >= 1100}
                >
                  <L>VALIDATION__HIGH_DENSITY</L>
                </ReactValueWarning>
              </>
            );
          }}
        />
        <NumericField
          title={<L>DENSITY_MEASURED_TEMP</L>}
          value={primaryLiquidDensityMeasuredAtTemperature}
          change={actions.updatePrimaryLiquidDensityMeasuredAtTemperature}
          layout="half"
          tooltip={() => <L>DENSITY_MEASURED_TEMP_HELP</L>}
          min={0}
          units={['°C']}
        />
        <NumericField
          title={<L>VISCOSITY</L>}
          value={primaryLiquidViscosity}
          change={actions.updatePrimaryLiquidViscosity}
          layout="half"
          tooltip={() => <L>VISCOSITY_HELP</L>}
          units={['cSt']}
          step={0.1}
          validation={(value: string) => {
            return (
              <>
                <ReactValueWarning
                  visible={() => Number(value) <= 0 && value.length}
                >
                  <L>VALIDATION__ZERO_OR_LESS_NOT_ALLOWED</L>
                </ReactValueWarning>
                <ReactValueWarning
                  visible={() => Number.parseInt(value, 10) >= 100}
                >
                  <L>VALIDATION__HIGH_VISCOSITY</L>
                </ReactValueWarning>
              </>
            );
          }}
        />
        <NumericField
          title={<L>VISCOSITY_MEASURED_TEMP</L>}
          value={primaryLiquidViscosityMeasuredAtTemperature}
          change={actions.updatePrimaryLiquidViscosityMeasuredAtTemperature}
          layout="half"
          tooltip={() => <L>VISCOSITY_MEASURED_TEMP_HELP</L>}
          min={0}
          units={['°C']}
        />
        <div className="col-md-12 cb-wrapper">
          <div className="row">
            <div className="col-md-12">
              <Checkbox
                label={<L>CORROSIVE</L>}
                checked={primaryLiquidCorrosive}
                onChange={actions.updatePrimaryLiquidCorrosive}
                tooltip={<L>CORROSIVE_HELP</L>}
              />
            </div>
          </div>
        </div>
      </Section>
      <Section title={<L>SPECIFY_SOLIDS_PROPERTIES</L>}>
        <Field
          title={<L>SOLIDS_TYPE</L>}
          value={solidsType}
          change={actions.updateSolidsType}
          layout="half"
          tooltip={() => <L>SOLIDS_TYPE_HELP</L>}
        />

        <NumericField
          title={<L>CONCENTRATION_IN_FEED</L>}
          value={solidConcentrationInFeed}
          change={actions.updateSolidConcentrationInFeed}
          layout="full"
          tooltip={() => <L>CONCENTRATION_IN_FEED_HELP_SOLID_LIQUID</L>}
          min={0}
          units={['%(v/v)', 'ppm', 'mg/l']}
          allowCustomUnit={true}
          unit={solidConcentrationUnit}
          changeUnit={actions.updateSolidConcentrationUnit}
          validation={(value: string) => {
            return (
              <>
                <ReactValueError
                  visible={() => Number(value) <= 0 && value.length}
                >
                  <L>VALIDATION__ZERO_OR_LESS_NOT_ALLOWED</L>
                </ReactValueError>
              </>
            );
          }}
        />
        <NumericField
          title={<L>DENSITY</L>}
          value={solidDensity}
          change={actions.updateSolidDensity}
          layout="half"
          validation={(value: number) => (
            <ReactValueWarning
              visible={() => !!value && value <= Number.parseFloat(primaryLiquidDensity!)}
            >
              <L>VALIDATION__DENSITY_PARTICLE_LOWER_THAN_FLUID</L>
            </ReactValueWarning>
          )}
          tooltip={() => <L>DENSITY_SOLIDS_HELP</L>}
          min={primaryLiquidDensity!}
          units={['kg/m³']}
        />
        <Empty />
        <div className="col-md-12 cb-wrapper">
          <div className="row">
            <div className="col-md-12">
              <Checkbox
                label={<L>KNOWN_TO_BE_EROSIVE</L>}
                checked={solidKnownToBeErosive}
                onChange={actions.updateSolidKnownToBeErosive}
                tooltip={<L>KNOWN_TO_BE_EROSIVE_HELP</L>}
              />
            </div>
          </div>
        </div>
      </Section>
    </Major>
    <Major title={<L>SEPARABILITY_DATA</L>}>
      <NumericField
        title={<L>PARTICLE_SIZE_AS_DV50</L>}
        value={particleSize}
        change={actions.updateParticleSize}
        layout="half"
        tooltip={() => <L preserveWraps>PARTICLE_SIZE_AS_DV50_HELP</L>}
        min={0}
        max={1000}
        units={['µm']}
        validation={(value: string) => {
          return (
            <>
              <ReactValueWarning
                visible={() => Number(value) <= 0 && value.length}
              >
                <L>VALIDATION__ZERO_OR_LESS_NOT_ALLOWED</L>
              </ReactValueWarning>
              <ReactValueWarning
                visible={() =>
                  Number.parseFloat(value) <= 0.2 &&
                  Number.parseFloat(value) > 0
                }
              >
                <L>VALIDATION__LOW_PARTICLE_SIZE</L>
              </ReactValueWarning>
              <ReactValueError visible={() => Number.parseInt(value, 10) >= 400}>
                <L>VALIDATION__HIGH_PARTICLE_SIZE</L>
              </ReactValueError>
            </>
          );
        }}
      />

      <NumericField
        title={<L>DELTA_DISTRIBUTION</L>}
        value={primaryLiquidDistribution}
        change={actions.updatePrimaryLiquidDistribution}
        layout="half"
        tooltip={() => <L>DELTA_DISTRIBUTION_HELP_PARTICLE</L>}
        min={0.2}
        max={1.3}
        step={0.1}
        validation={(value: string) => {
          return (
            <>
              <ReactValueWarning visible={() => Number.parseFloat(value) < 0.2}>
                <L>VALIDATION__LOW_DISTRIBUTION</L>
              </ReactValueWarning>

              <ReactValueError visible={() => Number.parseFloat(value) > 1.2}>
                <L>VALIDATION__HIGH_DISTRIBUTION</L>
              </ReactValueError>
            </>
          );
        }}
      />
      <SigmaGraph
        distribution={primaryLiquidDistribution!}
        dv50={particleSize!}
      />
    </Major>
    <div className="accordion container">
      <DontHaveSepData />
    </div>
    <div className="graph-section">
      <Major title={<L>PERFORMANCE_GRAPH</L>}>
        <h2>
          <L>PERFORMANCE_GRAPH</L>
          <span
            className="light-header-tooltip"
            data-tip=""
            data-for="performanceInfo"
          >
            <Ionicon
              className="tooltip-question-icon title"
              icon="md-help-circle"
            />
          </span>
        </h2>
        <PerformanceGraph
          primaryDensity={primaryLiquidDensity}
          distribution={primaryLiquidDistribution}
          dropletOrParticleSize={particleSize}
          flowRate={primaryLiquidTotalFlowRate}
          primaryViscosity={primaryLiquidViscosity}
          concentration={solidConcentrationInFeed}
          secondaryDensity={solidDensity}
          desiredConcentration={solidConcentrationAfterSeparation}
          concentrationUnit={solidConcentrationUnit}

        />
        <ReactTooltip id="performanceInfo" type="dark" effect="solid">
          <div className="tooltipBox">
            <L preserveWraps>PERFORMANCE_GRAPH_INFO</L>
          </div>
        </ReactTooltip>
      </Major>
      <Major title={<L>GRADE_EFFICIENCY_GRAPH_SOLID_LIQUID_TITLE</L>}>
        <h2>
          <L>GRADE_EFFICIENCY_GRAPH_SOLID_LIQUID_TITLE</L>
        </h2>
        <GradeEfficiencyGraph
          primaryDensity={primaryLiquidDensity}
          dropletOrParticleSize={particleSize}
          flowRate={primaryLiquidTotalFlowRate}
          primaryViscosity={primaryLiquidViscosity}
          secondaryDensity={solidDensity}
          XAxisLabel="Particle size"
        />

        <div style={{ marginTop: 20, width: '100%' }}>
          <GraphCustomization />
        </div>
      </Major>
    </div>
  </>
);

export default SolidLiquidForm;
