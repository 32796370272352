import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClientCaseActions from '../../store/clientCase/actions';
import { StoreState } from '../../store';
import L from '../literal';

const ThankYouForm: React.SFC = () => (
  <div className="modal-form data-thankyour-form">
    <span className="full-width form-description">
      <L>MODAL_THANK_YOU__INFO</L>
    </span>
  </div>
);

export default connect(
  ({ clientCase }: StoreState) => ({
    caseId: clientCase.caseId
  }),
  dispatch => ({
    actions: bindActionCreators(ClientCaseActions, dispatch)
  })
)(ThankYouForm);
