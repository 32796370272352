import { UPDATE_PROCESSASPECT_FIELD } from '../actionTypes';
import {
  ProcessAspectAction,
  PROCESSASPECT_FIELDS
} from './constants';

export default {
  updateDescription,
  updateSelectedExStandards,
  updateInstallationAspect,
  updateIsHazardousArea,
  updateSelectedControlLocations
};

function updateField(property: PROCESSASPECT_FIELDS, value: any) {
  return {
    type: UPDATE_PROCESSASPECT_FIELD,
    property,
    value
  };
}

export function updateDescription(description: string): ProcessAspectAction {
  return updateField(PROCESSASPECT_FIELDS.PROCESS_DESCRIPTION, description);
}

export function updateInstallationAspect(description: string): ProcessAspectAction {
  return updateField(PROCESSASPECT_FIELDS.INSTALLATION_ASPECT, description);
}

export function updateIsHazardousArea(isHazardousArea: boolean): ProcessAspectAction {
  return updateField(PROCESSASPECT_FIELDS.IS_HAZARDOUS_AREA, isHazardousArea);
}

export function updateSelectedExStandards(standards: Array<string>): ProcessAspectAction {
  return updateField(PROCESSASPECT_FIELDS.SELECTED_EXSTANDARDS, standards);
}

export function updateSelectedControlLocations(locations: Array<string>): ProcessAspectAction {
  return updateField(PROCESSASPECT_FIELDS.SELECTED_CONTROL_LOCATIONS, locations);
}
