import React from 'react';
import classNames from 'classnames';

interface SummarySection {
  className?: string;
  children: any;
}

const SummarySection: React.SFC<SummarySection> = ({
  className,
  children,
  ...rest
}) => (
  <div className={classNames('summary-section', className)} {...rest}>
    <div className="summary-container">
      {children}
    </div>
  </div>
);

export default SummarySection;
