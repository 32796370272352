import { ThunkAction } from 'redux-thunk';
import { SELECT_PROGRESS_STEP, SHOW_LOADER, HIDE_LOADER } from '../actionTypes';
import { ProgressAction } from './constants';
import { clearData } from '../clientCase/actions';
import { StoreState } from '..';
import history from '../../routerHistory';
import caseApi from '../../services/caseApi';
import { MODAL_TYPES } from '../modal/constants';
import { showModal } from '../modal/actions';
import { registerInteraction } from '../../utilities/analytics';

export default {
  clearAndNavigate,
  navigateToLastStep,
  startProcessFromBeginning,
  selectStep,
  previousStep,
  nextStep,
  finalizeProcess
};

function navigate(uri: string, state: any) {
  history.push(uri, state);
}

export function clearAndNavigate(navigateTo: string): ThunkAction<any, StoreState, any, any> {
  return (dispatch, getState) => {
    const store = getState();
    history.replace(history.location.pathname, {
      activeStep: store.progress.activeStep,
      activeProcessId: store.process.activeProcessId
    });

    dispatch(clearData());

    history.push(navigateTo);
  };
}

export function startProcessFromBeginning(): ProgressAction {
  history.replace('/process-selection', { activeStep: 0 });
  return { type: SELECT_PROGRESS_STEP, activeStep: 0 };
}

export function navigateToLastStep(): ThunkAction<any, StoreState, any, any> {
  return (dispatch, getState) => {
    const { progress } = getState();

    dispatch(selectStep(progress.steps[progress.steps.length - 1].id));
  };
}

export function finalizeProcess(): ThunkAction<any, StoreState, any, any> {
  return (dispatch, getState) => {
    const { clientCase } = getState();
    const { caseId } = clientCase;

    if (!caseId) {
      return;
    }

    dispatch({ type: SHOW_LOADER });

    return caseApi.finalizeProcess(caseId)
      .then(() => {
        dispatch({ type: HIDE_LOADER });
        dispatch(showModal(MODAL_TYPES.THANK_YOU));
        // here we can register an event for send-data
        registerInteraction('Send-data','Selector');
      })
      .catch(error => {
        dispatch({ type: HIDE_LOADER });
        // Handle error

        // rethrow so others may react upon it
        throw error;
      });
  };

}

export function selectStep(stepId: number): ThunkAction<any, StoreState, any, any> {
  return (dispatch, getState) => {
    const state = getState();
    const { process, progress } = state;
    const { activeProcess } = process;
    const activeStep = progress.steps.find(({ id }) => id === stepId);

    if (activeProcess && activeStep) {
      // Special case for first step
      if (progress.activeStep === 0) {
        history.replace(history.location.pathname, {
          activeStep: 0,
          activeProcessId: activeProcess.id
        });
      }

      dispatch({ type: SELECT_PROGRESS_STEP, activeStep: activeStep.id });
      navigate(activeStep.getUri(state), { activeStep: activeStep.id, activeProcessId: activeProcess.id });
    }
  };
}

export function previousStep(): ThunkAction<any, StoreState, any, any> {
  return (dispatch, getState) => {
    const { progress } = getState();
    const { activeStep } = progress;
    const nextStepId = activeStep - 1;

    dispatch(selectStep(nextStepId));
  };
}

export function nextStep(): ThunkAction<any, StoreState, any, any> {
  return (dispatch, getState) => {
    const { progress } = getState();
    const { activeStep } = progress;
    const nextStepId = activeStep + 1;

    dispatch(selectStep(nextStepId));
  };
}
