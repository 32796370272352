import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PrivacyPolicy from '../documentViewer/privacyPolicy';
import ModalActions from '../../store/modal/actions';

interface PrivacyPolicyModalProps {
  modalActions: typeof ModalActions;
}

const PrivacyPolicyModal: React.SFC<PrivacyPolicyModalProps> = ({ modalActions }) => (
  <div className="modal-form legal-modal wrap-modal-buttons">
    <PrivacyPolicy />
  </div>
);

export default connect(null, dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch)
}))(PrivacyPolicyModal);
