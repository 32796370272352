import React from 'react';
import classNames from 'classnames';
import CollapseButton from '../collapseButton';

interface SectionProps {
  className?: string;
  contentClassName?: string;
  children?: any;
  isCollapsible?: boolean;
  isCollapsed?: boolean;
  title: string | React.ReactNode;
  id?: string;
}

const Section: React.SFC<SectionProps> = ({
  className,
  contentClassName,
  children,
  isCollapsible,
  isCollapsed,
  title,
  id,
}) => (
  <section className={classNames('section', className)}>
    <header>{title}</header>
    {isCollapsible && (
      <CollapseButton
        id={(title && typeof title === 'string') ? title : Date.now().toString()}
        className="section-collapse"
        isCollapsed={isCollapsed}
      />
    )}
    <div className={classNames('section-content', contentClassName)}>
      {children}
    </div>
  </section>
);

Section.defaultProps = {
  isCollapsible: true,
  isCollapsed: false
};

export default Section;
