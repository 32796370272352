import React from 'react';

export interface DocumentViewerProps {
  children?: any;
}

export const DocumentViewer: React.SFC<DocumentViewerProps> = ({
  children
}) => (
  <div className="document-view">{children}</div>
);

export default DocumentViewer;
