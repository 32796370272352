import { erf } from './math/erf';

const SeparatorSizes = {
  Small: {
    Ae: 123 * 39, // 4797 KQ = 123
    Scale: 4,
    Multiplier: 5
  },
  Medium: {
    Ae: 1581 * 39, // 61659
    Scale: 25,
    Multiplier: 2

  },
  Large: {
    Ae: 3837 * 39, // 149643,
    Scale: 130,
    Multiplier: 1
  }
};

class Formula {
  flowRateToSize(flowRate: number) {
    if (flowRate > 0 && flowRate <= 4) {
      return SeparatorSizes.Small;
    } else if (flowRate > 4 && flowRate <= 25) {
      return SeparatorSizes.Medium;
    } else if (flowRate > 25) {
      return SeparatorSizes.Large;
    }

    return {
      Ae: 0,
      Scale: 0,
      Multiplier: 1
    };
  }

  crelativeAnalytic4(data: {
    Q: number,
    Ae: number,
    my: number,
    drho: number,
    Dv50: number,
    sigma: number
  }): any {
    const g = 9.81;
    const sv50 = Math.pow((data.Dv50 * 0.000001), 2) * data.drho * g / (18 * data.my);
    const mu = Math.log(sv50);

    const S0 = 2 * (data.Q / data.Ae);

    const sigmaS = 2 * data.sigma;

    // experiment
    const a1 = (Math.log(S0) - mu) / (sigmaS * Math.sqrt(2));
    const a = erf(a1);

    const bInner = mu + Math.pow(sigmaS, 2) / 2;
    const b = Math.exp(bInner);
    const c1 = (Math.log(S0) - mu - Math.pow(sigmaS, 2)) / (sigmaS * Math.sqrt(2));
    const c = erf(c1);
    // exp
    const result = 0.5 * (1 + a) - 1 / (2 * S0) * b * (1 + c);

    // 834279
    return result;
  }

  crelativeAnalyticWithPC(data: {
    particleConcentration: number,
    Q: number,
    fluidDensity: number,
    fluidViscosity: number,
    Ae: number,
    Dv50: number,
    sigma: number,
    particleDensity: number

  }): number {
    var input = {
      Q: data.Q,
      Ae: data.Ae,
      my: data.fluidViscosity,
      drho: Math.abs(data.particleDensity - data.fluidDensity),
      Dv50: data.Dv50,
      sigma: data.sigma
    };

    if (data.Q === 0) {
      return 0;
    }

    return data.particleConcentration * this.crelativeAnalytic4(input);
  }
}

export default Formula;
