import React from 'react';
import classNames from 'classnames';
import { ProcessType } from '../../store/process/constants';
import separation1 from '../../assets/images/solids.png';
import separation2 from '../../assets/images/liquids.png';
import separation3 from '../../assets/images/solid_and_liquid.png';
import L from '../literal';

const separationImageMap = {
  separation1,
  separation2,
  separation3
};

interface SeparationTaskProps {
  className?: string;
  id: ProcessType;
  imageReference: string;
  isSelected?: boolean;
  translationKey: string;
  onSelect?: (processType: any) => any;
  summaryTitle?: string | React.ReactNode;
}

const SeparationTask: React.SFC<SeparationTaskProps> = ({
  id,
  className,
  imageReference,
  isSelected,
  translationKey,
  onSelect,
  summaryTitle
}) => (
  <div onClick={() => onSelect!(id)} className={classNames('separation-task', className, { selected: isSelected })}>
    <div className="circle">
      <img className="separation-task-hero" src={separationImageMap[imageReference]} alt="" />
    </div>
    {summaryTitle && <span className="separation-task-prefix">{summaryTitle}</span>}
    <span className="separation-task-label"><L>{translationKey}</L></span>
  </div>
);

SeparationTask.defaultProps = {
  onSelect: () => null
};

export default SeparationTask;
