import { LOAD_TRANSLATIONS, SET_LANGUAGE } from "../actionTypes";
import {
  LanguageState,
  SetLanguageAction,
  LoadTranslationsAction,
} from "./constants";
import createActionHandler from "../createActionHandler";

const initialState: LanguageState = {
  languages: [
    { code: "zh-Hans-CN", name: "中文" },
    { code: "en-GB", name: "English" },
    { code: "ja-JP", name: "日本語" },
  ],
  languageCode: localStorage.getItem("language") || "en-GB",
};

const actionHandlers = {
  [SET_LANGUAGE]: (
    state: LanguageState,
    { languageCode }: SetLanguageAction
  ): LanguageState => ({ ...state, languageCode }),
  [LOAD_TRANSLATIONS]: (
    state: LanguageState,
    { translations }: LoadTranslationsAction
  ): LanguageState => ({ ...state, translations }),
};

export const language = createActionHandler(initialState, actionHandlers);
