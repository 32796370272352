import React from "react";
import LanguagePicker from "./languagePicker";

const TopBar = () => {
  return (
    <div className="topbar">
      <div className="topbar-margin topbar-left" />
      <div className="topbar-middle">
        <div className="topbar-filler">
          <div className="topbar-arc" />
        </div>
        <div className="topbar-content">
          <LanguagePicker />
        </div>
      </div>
      <div className="topbar-margin topbar-right" />
    </div>
  );
};

export default TopBar;
