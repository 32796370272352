interface FetchSettings {
  method?: string;
  mode?: RequestMode;
  payload?: any;
}

export function getDefaultFetchSettings({
  method = 'GET',
  payload = null
}: FetchSettings = {}): RequestInit {
  return {
    method,
    headers: {
      'content-type': 'application/json'
    },
    body: payload
  };
}

export function handleHttpErrorCodes(response: Response): Promise<Response> {
  if (response.status >= 400) {
    return Promise.reject({
      message: response.text,
      response
    });
  }

  return Promise.resolve(response);
}

export function get(url: string) {
  return fetch(url, getDefaultFetchSettings())
    .then(handleHttpErrorCodes)
    .then(response => response.json());
}

export function post(url: string, payload: any): Promise<any> {
  return fetch(url, getDefaultFetchSettings({ method: 'POST', payload }))
    .then(handleHttpErrorCodes)
    .then(response => response.json());
}

export function postFile(url: string, payload: FormData): Promise<any> {
  const fetchOptions = {
    method: 'POST',
    body: payload
  };

  return fetch(url, fetchOptions)
    .then(handleHttpErrorCodes)
    .then(response => response.json());
}

export function put(url: string, payload: any): Promise<any> {
  return fetch(url, getDefaultFetchSettings({ method: 'PUT', payload }))
    .then(handleHttpErrorCodes)
    .then(response => response.json());
}

export function restDelete(url: string, payload: any): Promise<any> {
  return fetch(url, getDefaultFetchSettings({ method: 'DELETE', payload }))
    .then(handleHttpErrorCodes)
    .then(response => response.json());
}
