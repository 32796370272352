import React from 'react';

const ErrorPage = () => {
  return (
    <div className="errorpage">
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <h1>Sorry, the service is currently unavailable.</h1>
            <h3>This is probably an issue on our end.</h3>
            <h3>What to do now?</h3>
            <ul>
            <li>
              Try again a little later.
            </li>
            <li>
              You can go to<a href="/" className="link-blue">
                Explore's startpage
              </a>
              to continue your browsing.
            </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
