import React from 'react';
import { Prompt } from 'react-router';
import { connect } from 'react-redux';
import { StoreState } from '../../store';

export class UnsavedProgressWarning extends React.PureComponent<any> {
  onBeforeUnload = (event: BeforeUnloadEvent) => {
    if (this.props.contentIsDirty) {
      event.preventDefault();
      event.returnValue = 'Make sure you save your progress before you leave the form so you don’t lose all your work so far.';
      return event.returnValue;
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onBeforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  }

  render() {
    return <Prompt when={true} message={location => location.pathname} />;
  }
}

export default connect(
  ({ app }: StoreState) => ({
    contentIsDirty: app.contentIsDirty
  })
)(UnsavedProgressWarning);
