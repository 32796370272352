import { useSelector } from "react-redux";
import { StoreState } from "../store";
import { replacePlaceholders } from "../utilities/translations";

export default (key: string, replacements?: {}): string => {
  let value = useSelector(
    (state: StoreState) => state.language.translations![key] || ""
  );

  if (replacements) {
    value = replacePlaceholders(value, replacements);
  }

  return value || `[${key}]`;
};
