import React from 'react';
import { FormElement, FormElementProps } from '../formElement';
import Checkbox from '../checkbox';

interface CheckboxListProps extends FormElementProps {
  options: ReadonlyArray<{ label: string, value: string }>;
  values: Array<string>;
  onChange: (values: Array<string>) => any;
}

export default class CheckboxList extends React.Component<CheckboxListProps> {
  static defaultProps = {
    onChange: () => null
  };

  onCheckboxChange = (value: string, checkedState: boolean) => {
    const { onChange, values } = this.props;
    const newValues = checkedState ? values.concat(value) : values.filter(v => v !== value);

    onChange(newValues);
  }

  render() {
    const { options, values, onChange, ...restProps } = this.props;
    return (
      <FormElement {...restProps} elementClassName="checkbox-list">
        <div className="checkbox-items">
          {options.map(({ label, value }) =>
            <Checkbox
              key={value}
              label={label}
              checked={values.indexOf(value) >= 0}
              onChange={(checkedState: boolean) => this.onCheckboxChange(value, checkedState)}
            />)}
        </div>
      </FormElement>
    );
  }
}
