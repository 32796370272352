import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClientCaseActions from '../../store/clientCase/actions';
import ModalActions from '../../store/modal/actions';
import { Link } from 'react-router-dom';
import L from '../literal';

interface ClearDataFormProps {
  clearData: () => any;
  closeModal: () => any;
}

const ClearDataForm: React.SFC<ClearDataFormProps> = ({
  clearData,
  closeModal
}) => (
  <div className="modal-form clear-data-form wrap-modal-buttons">
    <span className="form-description">
      <L preserveWraps>MODAL_CLEAR_DATA__INFO</L>
      <br />
      <br />
      <Link to="/process-selection" target="_blank" rel="noopener noreferrer">
        <L>MODAL_CLEAR_DATA__NEW_TAB</L>
      </Link>
    </span>
    <div className="modal-actions">
      <button
        className="btn-light modal-action-button smallButton"
        onClick={clearData}
      >
        <L>MODAL_CLEAR_DATA__YES</L>
      </button>

      <button className="modal-action-button smallButton clear-button" onClick={closeModal}>
        <L>MODAL_CLEAR_DATA__NO</L>
      </button>
    </div>
  </div>
);

export default connect(null, dispatch =>
  bindActionCreators(
    {
      clearData: ClientCaseActions.clearData,
      closeModal: ModalActions.closeModal
    },
    dispatch
  )
)(ClearDataForm);
