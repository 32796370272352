import React from 'react';

interface SliderProps {
  disabled?: boolean;
  max?: number;
  min?: number;
  onChange: (value: number) => any;
  value?: number;

}
const Slider: React.SFC<SliderProps> = ({
  disabled,
  max,
  min,
  onChange,
  value
}) => (
  <div className="slider" data-value={value}>
    <input
      type="range"
      min={min}
      max={max}
      value={value}
      disabled={disabled}
      onChange={e => onChange(parseInt(e.target.value, 10))}
      className="native-slider"
    />
  </div>
);

Slider.defaultProps = {
  onChange: () => null
};

export default Slider;
