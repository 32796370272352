import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Type, Effect } from 'react-tooltip';
import { withRouter, RouteProps } from 'react-router-dom';

interface TooltipInfo {
  id: string;
  type: Type;
  effect: Effect;
  place?: 'top';
}

const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

class ToolTipManager extends React.Component<RouteProps> {
  static tooltypTypes: Array<TooltipInfo> = [
    // Add more type of tooltips when needed
    {
      id: 'formInfo',
      type: 'dark',
      effect: 'solid',
      place: 'top'
    },
    {
      id: 'light-header',
      type: 'dark',
      effect: 'solid'
    }
  ];

  tooltips: object;

  constructor(props: any) {
    super(props);

    this.tooltips = {};
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  shouldComponentUpdate(nextProps: RouteProps) {
    return nextProps.location !== this.props.location;
  }

  onAfterHideTooltip = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.classList.remove('fix-tooltip-position');
    }
  }

  onAfterShowTooltip = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.classList.add('fix-tooltip-position');
    }
  }

  render() {
    return (
      <>
        {ToolTipManager.tooltypTypes.map(tooltip => (
          <ReactTooltip
            event={isMobileDevice ? 'click' : ''}
            eventOff={isMobileDevice ? 'click' : ''}
            {...tooltip}
            key={tooltip.id}
            ref={(ref: any) => (this.tooltips[tooltip.id] = ref)}
            afterHide={() => this.onAfterHideTooltip(tooltip.id)}
            afterShow={() => this.onAfterShowTooltip(tooltip.id)}
          />
        ))}
      </>
    );
  }
}

export default withRouter(ToolTipManager as any);
