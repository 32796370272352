import { ThunkAction } from 'redux-thunk';
import { StoreState } from '..';
import {
  ADD_LABTEST,
  REMOVE_LABTEST,
  SET_LABTEST_TYPE,
  TOGGLE_LABTEST,
  SET_LABTEST_SEPARATOR_SIZE
} from '../actionTypes';

import {
  AddLabTestAction,
  LabTest,
  RemoveLabTestAction,
  ToggleLabTestAction,
  SetLabTestSeparatorAction,
  DataPoint
} from './constants';
import { nonlinearRegression } from '../../utilities/regressionAnalysis';

export default {
  addTest,
  removeTest,
  selectSeparatorSize,
  toggleTest,
  toggleTestType
};

const centrifugeSpeed = 2000;
const tubeType = 0.4486;
const L100Tube = 100;
const LxxTube = 70;
const xxTube = 50;
const analysedTopPhase = 0.5;

const calculateSpin = (Ae: number) => ({ time, centrateQuality }: DataPoint): DataPoint => {
  const Q =
      3600 *
      Ae *
      9.80665 *
      tubeType *
      0.5 /
    ((2 * Math.PI * centrifugeSpeed / 60) ** 2 * time * 60);

  return {
    time,
    centrateQuality,
    Q
  };
};

const calculateGravity = (Ae: number) => ({ time, centrateQuality }: DataPoint): DataPoint => {
  const Q =
      0.5 *
      Ae *
      ((L100Tube - LxxTube) * analysedTopPhase / (100 - xxTube) / 1000 / (time * 3600)) *
    3600 * 100;

  return {
    time,
    centrateQuality,
    Q
  };
};

export function addTest(test: LabTest): ThunkAction<any, StoreState, any, any> {
  return (dispatch, getState) => {
    const { labTest } = getState();

    const dataPointsWithQ = test.dataPoints
      .reverse()
      .map(labTest.isSpinTest ? calculateSpin(labTest.Ae) : calculateGravity(labTest.Ae));

    const trendDataPoints = nonlinearRegression(dataPointsWithQ);

    dispatch<AddLabTestAction>({
      type: ADD_LABTEST,
      test: {
        ...test,
        dataPoints: dataPointsWithQ,
        trendDataPoints,
        active: true,
        id: Date.now()
      }
    });
  };
}

export function removeTest(test: LabTest): RemoveLabTestAction {
  return {
    type: REMOVE_LABTEST,
    test
  };
}

export function toggleTest(test: LabTest): ToggleLabTestAction {
  return {
    type: TOGGLE_LABTEST,
    test
  };
}

export function selectSeparatorSize(Ae: number): ThunkAction<any, StoreState, any, any> {
  return (dispatch, getState) => {
    const { labTest } = getState();

    // This could be optimized by doing the calculations when fetching from the store instead
    // with the reselect library
    const tests = labTest.tests.map(test => {
      const dataPointsWithQ = test.dataPoints.map(labTest.isSpinTest ? calculateSpin(Ae) : calculateGravity(Ae));
      const trendDataPoints = nonlinearRegression(dataPointsWithQ);

      return {
        ...test,
        dataPoints: dataPointsWithQ,
        trendDataPoints
      };
    });

    dispatch<SetLabTestSeparatorAction>({
      type: SET_LABTEST_SEPARATOR_SIZE,
      Ae,
      tests
    });
  };
}

export function toggleTestType(): ThunkAction<any, StoreState, any, any> {
  return (dispatch, getState) => {
    const { labTest } = getState();
    const { testType } = labTest;

    dispatch({
      type: SET_LABTEST_TYPE,
      testType: testType === 'spin' ? 'gravity' : 'spin'
    });
  };
}
