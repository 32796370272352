import { ProcessDataState } from '../store/processData/constants';
import { ProcessAspectState } from '../store/processAspect/constants';
import { ProcessInfo } from '../store/process/constants';
import { Attachment } from '../store/caseFiles/constants';

let lastClick = Date.now();

export const downloadFile = (file: Attachment) => {
  const isMobileResolution = window.innerWidth < 768;

  const now = Date.now();
  const duration = now - lastClick;
  lastClick = now;

  if (!isMobileResolution || (isMobileResolution && duration < 500)) {
    window.open(file.url);
  }
};

export const getPrimaryLiquidProperties = (processData: ProcessDataState) => [
  {
    translationKey: 'FLOW_RATE',
    value: processData.primaryLiquidTotalFlowRate,
    unit: 'm³/h'
  },
  {
    translationKey: 'LIQUID_PRODUCT_NAME',
    value: processData.primaryLiquidProductName,
    className: 'long-text'
  },
  {
    translationKey: 'PROCESS_TEMPERATURE',
    value: processData.primaryLiquidProcessTemperature,
    unit: '°C'
  },
  {
    translationKey: 'PROCESS_PRESSURE',
    value: processData.primaryLiquidProcessPressure,
    unit: 'kPa'
  },
  {
    translationKey: 'DENSITY',
    value: processData.primaryLiquidDensity,
    unit: 'kg/m³'
  },
  {
    translationKey: 'DENSITY_MEASURED_TEMP',
    value: processData.primaryLiquidDensityMeasuredAtTemperature,
    unit: '°C'
  },
  {
    translationKey: 'VISCOSITY',
    value: processData.primaryLiquidViscosity,
    unit: 'cSt'
  },
  {
    translationKey: 'VISCOSITY_MEASURED_TEMP',
    value: processData.primaryLiquidViscosityMeasuredAtTemperature,
    unit: '°C'
  },
  {
    translationKey: 'CORROSIVE',
    value: processData.primaryLiquidCorrosive && 'Yes'
  }
];

export const getSecondaryLiquidProperties = (processData: ProcessDataState) => [
  {
    translationKey: 'LIQUID_CONCENTRATION',
    value: processData.secondaryLiquidConcentration,
    unit: processData.liquidConcentrationUnit
  },
  {
    translationKey: 'LIQUID_PRODUCT_NAME',
    value: processData.secondaryLiquidProductName,
    className: 'long-text'
  },
  {
    translationKey: 'DENSITY',
    value: processData.secondaryLiquidDensity,
    unit: 'kg/m³'
  },
  {
    translationKey: 'DENSITY_MEASURED_TEMP',
    value: processData.secondaryLiquidDensityMeasuredAtTemperature,
    unit: '°C'
  }
];

export const getSolidProperties = (processData: ProcessDataState) => [
  {
    translationKey: 'SOLIDS_TYPE',
    value: processData.solidsType,
    className: 'long-text'
  },
  {
    translationKey: 'CONCENTRATION',
    value: processData.solidConcentrationInFeed,
    unit: processData.solidConcentrationUnit
  },
  {
    translationKey: 'DENSITY',
    value: processData.solidDensity,
    unit: 'kg/m³'
  },
  {
    translationKey: 'KNOWN_TO_BE_EROSIVE',
    value: processData.solidKnownToBeErosive && 'Yes'
  }
];

export const getProcessAspectDescription = (processAspects: ProcessAspectState) => [
  {
    translationKey: 'BRIEF_PROCESS_DESCRIPTION',
    value: processAspects.processDescription
  },
  {
    translationKey: 'INSTALLATION_ASPECTS_ENVIRONMENTAL_CONDITIONS',
    value: processAspects.installationEnvironmentConditions
  }
];

export const getHazardousProcessAspectProperties = (processAspects: ProcessAspectState) => [
  {
    translationKey: 'HAZARDOUS_AREA',
    value: processAspects.isHazardousArea ? 'Yes' : 'No'
  },
  {
    translationKey: 'SPECIFY_EX_STANDARD_APPLIED',
    value: processAspects.selectedExStandards.join(', ')
  },
  {
    translationKey: 'LIKELY_CONTROLS_LOCATIONS',
    value: processAspects.selectedControlLocations.join(', ')
  }
];

export const getSeparabilityData = (activeProcess: ProcessInfo, processData: ProcessDataState) => {
  const {
    particleSize,
    primaryLiquidDistribution,
    secondaryLiquidDropletSize,
    secondaryLiquidDistribution,
    primaryLiquidDropletSize
  } = processData;
  const { separateSolids, separateLiquids } = activeProcess!;

  const isLiquidLiquidSolid = separateLiquids && separateSolids;
  const isLiquidLiquid = separateLiquids && !separateSolids;

  // Extract the appropriate values depending on selected separator process
  let dv50Value = particleSize; // default for solid/liquid
  let sigmaValue = primaryLiquidDistribution; // default for solid/liquid

  if (isLiquidLiquidSolid) {
    dv50Value = secondaryLiquidDropletSize;
    sigmaValue = secondaryLiquidDistribution;
  } else if (isLiquidLiquid) {
    dv50Value = primaryLiquidDropletSize;
    sigmaValue = primaryLiquidDistribution;
  }

  return [
    {
      translationKey: activeProcess.separateLiquids ? 'DROPLET_SIZE_AS_DV50' : 'PARTICLE_SIZE_AS_DV50',
      value: dv50Value,
      unit: 'μm'
    },
    {
      translationKey: 'DISTRIBUTION_SIGMA',
      value: sigmaValue
    }
  ];
};

export const getExtendedSeparabilityData = (processData: ProcessDataState) => {
  const { solidDropletSize, solidDistribution } = processData;

  return [
    {
      translationKey: 'PARTICLE_SIZE_AS_DV50',
      value: solidDropletSize,
      unit: 'μm'
    },
    {
      translationKey: 'DISTRIBUTION_SIGMA',
      value: solidDistribution
    }
  ];
};

export const getPerformanceTarget = (
  activeProcess: ProcessInfo,
  processData: ProcessDataState,
  options: {
    includeOtherPerformanceAspects?: boolean;
  } = {}
) => {
  const {
    solidConcentrationAfterSeparation,
    solidConcentrationUnit,
    secondaryLiquidConcentrationAfterSeparation,
    liquidConcentrationUnit,
    otherPerformanceAspects
  } = processData;
  const { separateSolids, separateLiquids } = activeProcess!;

  const isLiquidLiquidSolid = separateLiquids && separateSolids;
  const isLiquidLiquid = separateLiquids && !separateSolids;

  const solidsAfterSeparationOption = {
    translationKey: 'SOLIDS_AFTER_SEPARATION',
    value: solidConcentrationAfterSeparation,
    unit: solidConcentrationUnit
  };

  const secondaryLiquidConcentrationAfterSeparationOption = {
    translationKey: 'SECONDARY_LIQUID_CONCENTRATION_AFTER_SEPARATION',
    value: secondaryLiquidConcentrationAfterSeparation,
    unit: liquidConcentrationUnit
  };

  const otherPerformanceAspectsOption = {
    translationKey: 'OTHER_PERFORMANCE_ASPECTS',
    value: otherPerformanceAspects,
    unit: ''
  };

  let result;

  if (isLiquidLiquidSolid) {
    result = [
      solidsAfterSeparationOption,
      secondaryLiquidConcentrationAfterSeparationOption
    ];
  } else if (isLiquidLiquid) {
    result = [secondaryLiquidConcentrationAfterSeparationOption];
  } else {
    result = [solidsAfterSeparationOption];
  }

  if (options.includeOtherPerformanceAspects) {
    result.push(otherPerformanceAspectsOption);
  }

  return result;
};
