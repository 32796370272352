import React from 'react';
import L from '../../components/literal';

const SubHero = () => (
  <div className="subhero">
    <h1 className="subhero-header">
      <L>YOUR_DATA_OVERVIEW</L>
    </h1>
  </div>
);

export default SubHero;
