import React from 'react';
import classNames from 'classnames';

interface CollapseButtonProps {
  className?: string;
  id: string;
  isCollapsed?: boolean;
}

const CollapseButton: React.SFC<CollapseButtonProps> = ({
  className,
  id,
  isCollapsed
}) => (
  <>
    <input
      id={id}
      type="checkbox"
      defaultChecked={isCollapsed}
      className={classNames('collapse-button', className)}
    />
    <label className="collapse-button-icon" htmlFor={id} />
  </>
);

CollapseButton.defaultProps = {
  isCollapsed: false
};

export default CollapseButton;
