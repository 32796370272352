import React from 'react';
import CheckBox from '../checkbox';
import Section from '../section';
import PrivacyPolicy from '../documentViewer/privacyPolicy';
import ClientCaseActions from '../../store/clientCase/actions';
import { ClientCaseState } from '../../store/clientCase/constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StoreState } from '../../store';
import L from '../literal';

interface PrivacyProps extends ClientCaseState {
  actions: typeof ClientCaseActions;
}

const PrivacyBlock: React.SFC<PrivacyProps> = ({
  actions,
  acceptedPrivacyPolicy
}) => {
  return (
    <>
      <CheckBox
        label={<L>MODAL_SAVE_DATA__PRIVACY_POLICY_CONSENT</L>}
        checked={acceptedPrivacyPolicy}
        onChange={actions.updateAcceptedPrivacyPolicy}
        className="full-width privacy-box"
      />
      <Section
        title=""
        className="privacy"
        id="privacypolicy"
        isCollapsed={true}
      >
        <PrivacyPolicy />
      </Section>
    </>
  );
};

export default connect(
    ({ clientCase }: StoreState) => ({
      ...clientCase
    }),
    dispatch => ({
      actions: bindActionCreators(ClientCaseActions, dispatch)
    })
  )(PrivacyBlock);
