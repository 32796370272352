export function closest<T>(num: number, arr: T[], selector: (numm: T) => number): T {
    const arr2 = arr.map(selector);
    var curro = arr[0];
    var diff = Math.abs(num - arr2[0]);
    for (var val = 0; val < arr2.length; val++) {
        var newdiff = Math.abs(num - arr2[val]);
        if (newdiff < diff) {
            diff = newdiff;
            curro = arr[val];
        }
    }
    return curro;
}