import React from 'react';
import classNames from 'classnames';
import L from '../literal';

interface PropertyTableProps {
  className?: string;
  layoutDirection?: 'horizontal-two-columns' | 'vertical';
  propertyLayout?: 'flat' | 'stacked' | 'stacked-indented';
  properties: Array<{
    translationKey: string;
    value?: string | number | boolean;
    unit?: string | null;
    className?: string;
  }>;
}

const PropertyList: React.SFC<PropertyTableProps> = ({
  className,
  layoutDirection,
  propertyLayout,
  properties
}) => (
  <ul className={classNames('property-list', className, layoutDirection, propertyLayout)}>
    {properties.map(({ translationKey, value, unit, className: itemClassName }, i) => (
      <li className={classNames('property-list-item', itemClassName)} key={i}>
        <span className="property-list-label"><L>{translationKey}</L></span>
        <span className="property-list-value">
          {value}
          {value && unit && ` ${unit}`}
        </span>
      </li>
    ))}
  </ul>
);

PropertyList.defaultProps = {
  propertyLayout: 'flat',
  layoutDirection: 'vertical'
};

export default PropertyList;
