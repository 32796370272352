import { SET_LANGUAGE, LOAD_TRANSLATIONS } from "../actionTypes";
import { SetLanguageAction, LoadTranslationsAction } from "./constants";

export default {
  setLanguage,
  loadTranslations,
};

export function setLanguage(languageCode: string): SetLanguageAction {
  return {
    type: SET_LANGUAGE,
    languageCode,
  };
}

export function loadTranslations(translations: {}): LoadTranslationsAction {
  return {
    type: LOAD_TRANSLATIONS,
    translations,
  };
}
