import { Action } from 'redux';

export enum PROCESSASPECT_FIELDS {
  PROCESS_DESCRIPTION = 'processDescription',
  INSTALLATION_ASPECT = 'installationEnvironmentConditions',
  IS_HAZARDOUS_AREA = 'isHazardousArea',
  SELECTED_EXSTANDARDS = 'selectedExStandards',
  SELECTED_CONTROL_LOCATIONS = 'selectedControlLocations'
}

export interface ProcessAspectState {
  contentIsDirty?: boolean;
  processDescription: string;
  installationEnvironmentConditions: string;
  isHazardousArea: boolean;
  selectedExStandards: Array<string>;
  selectedControlLocations: Array<string>;
  exStandardOptions: ReadonlyArray<{ label: string; value: string }>;
  controlLocationOptions: ReadonlyArray<{ label: string; value: string }>;
}

export interface ProcessAspectAction extends Action {
  property: PROCESSASPECT_FIELDS;
  value: string | boolean | Array<string>;
}
