import React from 'react';
import DocumentViewer from './documentView';

const TermsConditions = () => (
  <DocumentViewer>
    <h2>User terms and compliance requirements for Alfa Laval</h2>
    <h4>1. Introduction</h4>
    <p>
      Alfa Laval Corporate AB, 556007-7785, PO Box 73, SE-221 00 Lund, Sweden,
      operates multiple websites. Some of the websites enable customers and Alfa
      Laval’s business partners to connect, such as Alfa Laval’s product guide
      and InTouch. As a customer or an Alfa Laval business partner, your use of
      such websites is subject to the following terms and conditions (the
      <span className="p-bold">“Terms”</span>). As a customer, your use of the website for Alfa Laval’s
      webshop is also subject to the Terms.
    </p>
    <p>
      All are welcome to visit Alfa Laval’s websites. However, by accessing Alfa
      Laval’s webshop or one of the websites that enable customers and Alfa
      Laval’s business partners to connect, you agree to be bound by the Terms.
      If you do not agree to be bound by the Terms, please exit the website.
    </p>

    <h3>Terms applicable for all users</h3>
    <p />

    <h4>
      2. Disclaimer regarding information and material published by Alfa Laval
    </h4>
    <p>
      While Alfa Laval endeavors to ensure that the information on the websites
      published by Alfa Laval or any of its subsidiaries is correct, Alfa Laval
      does not make any warranties or undertakings of any kind in relation to
      the information and material, including but not limited to the accuracy
      and completeness of the information and the material. Alfa Laval may make
      changes to the information and material provided on the websites at any
      time and without notice. The information and material on the websites may
      be out of date, and Alfa Laval makes no commitment to update such
      information and material.
    </p>
    <p>
      Accordingly, to the maximum extent permitted by law, Alfa Laval provides
      you with access to its websites on the basis that Alfa Laval excludes all
      representations and warranties, conditions and other terms (including,
      without limitation, the conditions implied by law of satisfactory quality,
      fitness for purpose and the use of reasonable care and skill) in relation
      to the websites.
    </p>

    <h4>3. Availability</h4>
    <p>
      While Alfa Laval endeavors to ensure that the websites are normally
      available 24 hours a day, Alfa Laval shall not be liable if, for any
      reason, any website is unavailable at any time for any period. Access to
      the websites may be suspended temporarily and without notice in case of
      system failure, maintenance or repair or for other reasons.
    </p>
    <p>
      The websites are provided “as available” without any express or implied
      warranty or representation whatsoever that they will be uninterrupted or
      timely. Alfa Laval makes no warranties, express or implied, regarding the
      functionality and accessibility of the websites.
    </p>

    <h4>4. Intellectual property rights</h4>
    <p>
      You are permitted to print or download extracts from the websites provided
      that the following provisions are complied with:
    </p>
    <ul>
      <li>
        no documents or related graphics on the websites are modified in any
        way;
      </li>
      <li>
        no graphics on the websites are used separately from accompanying text;
        and
      </li>
      <li>Alfa Laval’s copyright notice appears in all copies.</li>
    </ul>
    <p>
      The copyright and other intellectual property rights in and to the
      websites and all information and material provided on them (including
      without limitation photographs and graphical images) are owned by Alfa
      Laval or its licensors. For the purpose of this legal notice, any use of
      extracts from the websites other than in accordance with this Section 4 is
      prohibited.
    </p>
    <p>
      Except as explicitly provided for in the Terms, no part of the websites
      may be reproduced or stored in any other website or included in any public
      or private electronic retrieval system or service or otherwise without
      Alfa Laval’s prior written permission and nothing in these Terms shall
      confer any ownership right or rights of use thereto to you.
    </p>

    <h4>5. Changes to the Terms</h4>
    <p>
      Alfa Laval may from time to time and in its discretion make changes to the
      Terms. The date of last modification is stated at the end of each Term
      document. It is your responsibility to check the Term document from time
      to time for updates.
    </p>
    <p>
      When Alfa Laval makes revisions of a Term document that it considers
      material, Alfa Laval will ask signed in users to read and agree to the
      changed terms.
    </p>
    <p>
      You will have no obligation to continue using Alfa Laval’s websites
      following any changes to the Terms, but if you continue to use the website
      for Alfa Laval’s webshop and/or any of Alfa Laval’s websites that enable
      customers and business partners to connect after any changes it will
      constitute your acceptance of the changes.
    </p>

    <h4>6. Limitation of liability</h4>
    <p>
      Alfa Laval’s websites are provided “as is” without any express or implied
      warranty or representation whatsoever that they will be secure, error-free
      or that third party content provided will be free of viruses or other
      harmful components. Alfa Laval disclaims all liability for the websites
      (to the extent possible under applicable mandatory law) and makes no
      warranties, express or implied, regarding the websites.
    </p>
    <p>
      In no event will Alfa Laval, any of its subsidiaries, their officers,
      directors, employees or shareholders, be liable for any amount or any kind
      of loss or damage that you or any third party may incur (including without
      limitation, any direct, indirect, punitive or consequential loss or
      damage, or any loss of income, profits, and whether in tort, contract or
      otherwise) in connection with your use of the websites in any way, or in
      connection with the inability to use or the results of the use of the
      websites, if you are a customer including any information received from
      Alfa Laval business partners, any websites linked to Alfa Laval’s websites
      or the material on such websites, including but not limited to loss or
      damage due to viruses that may infect your computer equipment, software,
      data or other property on account of your access to, use of, or browsing
      the websites or your downloading of any material from the websites or any
      websites linked to the websites.
    </p>
    <p>
      You agree that your sole and exclusive remedy for any problems, losses,
      costs or dissatisfaction caused by the use of the website for Alfa Laval’s
      webshop or any of Alfa Laval’s websites that enable customers and business
      partners to connect is to de-register from the websites. For further
      information about registration of a profile and de-registration of such
      profile, please see Section 9 and Section 10.
    </p>

    <h4>7. Your responsibilities</h4>
    <p>
      You are responsible for taking preventive measures and actions to protect
      computer systems from viruses or other harmful content when using the
      websites. You shall immediately notify Alfa Laval if there is a
      discrepancy or incorrect data detected in the information available on a
      website or if you identify any virus or corruption of a website.
    </p>
    <p>
      If you represent a company or another legal person, you agree to defend,
      indemnify and hold Alfa Laval, its suppliers, licensors, employees and
      agents harmless from and against all claims and expenses, including
      attorney or legal fees arising directly or indirectly from your use of one
      of the websites in violation of these Terms, or in violation of any other
      applicable terms and conditions or intellectual property infringements.
    </p>

    <h4>8. Applicable law and dispute resolution</h4>
    <p>
      The Terms are subject to the laws of Sweden, without regard to choice or
      conflicts of law principles, and you and Alfa Laval agree to the exclusive
      jurisdiction of the courts of Sweden to resolve any dispute, claim or
      controversy that arises in connection with the Terms.
    </p>

    <h3>Terms applicable for users with a profile</h3>
    <p />

    <h4>9. Profile and profile information</h4>
    <p>
      By registering your user credentials and clicking “I confirm”, you create
      a profile for Alfa Laval’s websites.
    </p>
    <p>
      By registering your user credentials and consenting to these Terms, you
      acknowledge that you accept and agree to comply with the Terms.
    </p>
    <p>
      If you register user credentials and create a profile on behalf of a
      company, by clicking “I confirm” you represent that you are authorised to
      register user credentials and create a profile on behalf of the company.
    </p>
    <p>
      You are responsible for keeping your personal credentials and log in
      information up to date and secure from unauthorized access.
    </p>
    <p>
      You must add your name and e-mail address to your profile. In addition,
      you decide what information you will add to your profile. You may at any
      time change or remove information in your profile. You may also at any
      time de-register your profile (as described in Section 10 below). Alfa
      Laval reserves the right to suspend or terminate your profile if
      activities occur which, in Alfa Laval’s sole discretion, constitutes or
      may constitute a violation of the Terms or of any applicable laws, rules
      or regulations or if the profile is being used or may be used by an
      unauthorized person.
    </p>
    <p>
      The processing of your personal data in connection with your profile and
      use of the websites is further described in our
      <a href="/privacypolicy">privacy policy</a>.
    </p>

    <h4>10. De-registration</h4>
    <p>
      You may at any time de-register your profile and cease using Alfa Laval’s
      websites. If you decide to de-register your profile you will no longer be
      granted access to the profile. Upon such de-registration, and if requested
      by you, all information in your profile will be sent to you by email and
      deleted shortly thereafter.
    </p>

    <h4>11. Customer’s information sharing</h4>
    <p>
      As a customer you can choose to share certain information on your profile
      with an Alfa Laval business partner. An Alfa Laval business partner is a
      legal entity that has an agreement with Alfa Laval AB or any of Alfa Laval
      AB’s subsidiaries for distribution of Alfa Laval’s products to end
      customers. The Alfa Laval business partners will only have access to such
      information that you have explicitly agreed to share. On a website where
      both customers and business partners have access and can register a
      profile, you may at any time withdraw your approval of the Alfa Laval
      business partner’s access to your profile information by selecting
      de-share for a specific Alfa Laval business partner.
    </p>

    <h4>12. Business Partner processing of customer’s profile information</h4>
    <p>
      If you as business partner are granted access to a customer’s profile
      information and in any way process such information you will be regarded
      as a data controller for such processing. You are only allowed to process
      the information for the purpose of (i) contacting the customers regarding
      quotes or questions submitted to you by customers, and (ii) offering
      maintenance and other services in relation to the customers’ products. We
      kindly ask you to erase all information about a customer stored in any
      electronic file or database if and when the customer withdraws its
      approval to share its profile information with you.
    </p>
    <p>
      Being a data controller implies that you have to process the customer’s
      personal information in accordance with the data protection legislation
      applicable in the jurisdiction where you are located. By accepting the
      Terms you warrant that you will process customers’ personal data in
      accordance with the applicable data protection legislation.
    </p>

    <h3>Terms applicable for buying customers</h3>
    <p />

    <h4>13. Export control</h4>
    <p>
      Supply or export of products and services as envisaged by the websites or
      the terms and conditions for a purchase order (<span className="p-bold">“Purchase Agreement”</span>)
      may require prior authorization or licences by competent authorities or
      otherwise be restricted or prohibited due to export laws, trade or export
      restrictions, trade sanctions, embargoes, prohibitions or other regulation
      imposed by the EU, UN or U.S. or other foreign governments (collectively,
      <span className="p-bold">“Export Control Law”</span>). Alfa Laval therefore reserves the right to
      unilaterally cancel any Purchase Agreement or a purchase order submitted
      through its websites without incurring any liability for damage or loss
      arising out of or relating to such cancellation, in the event any required
      authorization or licence is not granted or subsequently revoked or if the
      supply, export or re-export of the products or services otherwise cannot
      be carried out due to Export Control Law.
    </p>
    <p>
      Alfa Laval shall always be entitled to unilaterally suspend and/or
      terminate any Purchase Agreement or a purchase order submitted by the
      customer through Alfa Laval’s websites if and to the extent performance of
      a Purchase Agreement and/or the purchase order is impeded or made
      unreasonably onerous, as determined by Alfa Laval at its sole discretion,
      by any Export Control Law, whether foreseen or unforeseen at the time of
      formation of the Purchase Agreement or acceptance of the purchase order,
      without incurring any liability for damage or loss arising out of or
      relating to such suspension or termination.
    </p>
    <p>
      The customer undertakes not to directly or indirectly through affiliate,
      third party or otherwise, use, export, re-export, transfer or make
      available any products or any software, data or technical or commercial
      information provided by Alfa Laval in violation of any Export Control Law
      and shall refrain from any dealings, transactions or other activity
      involving persons or entities targeted by Export Control Law or that is
      owned or controlled by such by persons targeted by Export Control Law.
      Furthermore, the customer shall not facilitate any attempt by a third
      party to circumvent or otherwise assist such third party in any dealings,
      transactions or activity that, by wilfulness or neglect, might be in
      violation of Export Control Law.
    </p>
    <p>
      For the purpose of this Section 13, “customer” shall mean you or the
      company you represent in which name a purchase order is made, as
      applicable.
    </p>

    <h4>14. Compliance with anti-corruption laws</h4>
    <p>
      A buying customer shall comply with applicable laws and regulations
      relating to anti-corruption, including, without limitation, (i) the UK
      Bribery Act 2010; and the U.S. Foreign Corrupt Practices Act of 1977,
      irrespective of the place of performance, and (ii) laws and regulations
      implementing the Organization for Economic Cooperation and Development’s
      Convention on Combating Bribery of Foreign Public Officials in
      International Business Transactions and the U.N. Convention Against
      Corruption, and (iii) all applicable laws and regulations in the
      customer’s country or in any country where performance of a Purchase
      Agreement will occur.
    </p>
    <p>
      In carrying out its responsibilities under any Purchase Agreement, the
      customer further represents that:
    </p>
    <ul>
      <li>
        (a) The customer will not pay, offer, promise to pay, or authorize the
        payment directly or indirectly of anything of value to (i) any person or
        firm employed by or acting for or on behalf of any customer, whether
        private or governmental, or (ii) any government official or employee or
        any political party or candidate for political office for the purpose of
        influencing any act or decision or inducing or rewarding any action by
        the customer in any commercial transaction or in any governmental matter
        or securing any improper advantage to assist the customer in obtaining
        or retaining business or directing business to any person.
      </li>
      <li>
        (b) The customer will not make, either directly or indirectly, any
        improper payments, including but not limited to facilitation payments,
        gratuities or kickbacks.
      </li>
      <li>
        (c) The customer has established and will maintain an effective business
        ethics and compliance program and procedures to prevent corruption and
        ensure compliance with applicable laws or regulations.
      </li>
      <li>
        (d) The customer will promptly disclose to Alfa Laval together with all
        pertinent facts any violation, or alleged violation, of any applicable
        law or regulation in connection with the performance of this contract.
      </li>
    </ul>

    <p>
      For the purpose of this Section 14, “customer” shall mean you or the
      company you represent in which name a purchase order is made, as
      applicable.
    </p>

    <p>October 23, 2018</p>
  </DocumentViewer>
);

export default TermsConditions;
