import { Action } from 'redux';

export const defaultGravityDatapoints = [12, 24, 40, 60];
export const defaultGravityDatapointUnit = 'hours';
export const defaultSpinDatapoints = [2, 4, 8, 16];
export const defaultSpinDatapointUnit = 'min';
export const MAX_LABTESTS = 3;

export interface LabTest {
  colorId?: string;
  active?: boolean;
  dataPoints: DataPoint[];
  trendDataPoints?: DataPoint[];
  dateOfTesting: string;
  id?: number;
  name: string;
  unit: string;
}

export interface DataPoint {
  time: number;
  centrateQuality: number;
  Q?: number;
}

type LabTestType = 'spin' | 'gravity';

export interface LabTestState {
  availableColors: Array<string>;
  Ae: number;
  datapointRange: Array<number>;
  datapointRangeUnit: 'min' | 'hours';
  isSpinTest: boolean;
  reachedMaxTests: boolean;
  tests: LabTest[];
  testType: LabTestType;
}

export interface AddLabTestAction extends Action {
  test: LabTest;
}

export interface RemoveLabTestAction extends Action {
  test: LabTest;
}

export interface ToggleLabTestAction extends Action {
  test: LabTest;
}

export interface SetLabTestAction extends Action {
  testType: LabTestType;
}

export interface SetLabTestSeparatorAction extends Action {
  Ae: number;
  tests: LabTest[];
}
