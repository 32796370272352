import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CheckBox from "../checkbox";
import { NumericField } from "../forms/field";
import L from "../literal";
import actions from "../../store/processData/actions";
import { StoreState } from "../../store";

const password = "clever";

interface GraphCustomizationProps {
  // onRecalculate: any;
  customizedAe: string | undefined;
  updateCustomizedAe: (value: string | undefined) => any;
}

interface GraphCustomizationState {
  unlocked: boolean;
  enabled: boolean;
  Ae: string;
}

class GraphCustomization extends React.Component<
  GraphCustomizationProps,
  GraphCustomizationState
> {
  constructor(props: GraphCustomizationProps) {
    super(props);
    this.state = {
      unlocked: Boolean(props.customizedAe),
      enabled: Boolean(props.customizedAe),
      Ae: props.customizedAe || "",
    };
  }

  /* prettier-ignore */
  handleEnabledChange = (enabled: boolean) => {
    if (!this.state.unlocked && prompt('Password') !== password) {
      return;
    }

    this.setState({ unlocked: true, enabled });
    if (!enabled) {
      this.props.updateCustomizedAe(undefined);
    }
  }

  handleRecalculateClick = () => {
    this.props.updateCustomizedAe(this.state.Ae);
  };

  handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault(); // Disable enter (from posting form)
      this.props.updateCustomizedAe(this.state.Ae);
    }
  };

  render() {
    return (
      <div>
        <CheckBox
          label={<L>CUSTOMIZE</L>}
          checked={this.state.enabled}
          onChange={this.handleEnabledChange}
        />
        <div style={{ display: this.state.enabled ? "block" : "none" }}>
          <NumericField
            title="Ae"
            value={this.state.Ae}
            change={(value) => this.setState({ Ae: value })}
            layout="half"
            min={0}
            units={["m²"]}
            onKeyDown={this.handleKeyDown}
          />

          <button
            type="button"
            onClick={this.handleRecalculateClick}
            style={{ margin: "20px 30px 0" }}
          >
            <L>RECALCULATE</L>
          </button>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: StoreState) => ({
    customizedAe: state.processData.customizedAe,
  }),
  (dispatch) => ({
    updateCustomizedAe: bindActionCreators(
      actions.updateCustomizedAe,
      dispatch
    ),
  })
)(GraphCustomization);
