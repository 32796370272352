import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { ProcessState } from '../../store/process/constants';
import { ProcessDataState } from '../../store/processData/constants';
import { ProcessAspectState } from '../../store/processAspect/constants';
import PerformanceTargetSummary from './performanceTargetSummary';
import SeparationPropertiesSummary from './separationPropertiesSummary';
import SeparabilitySummary from './separabilitySummary';
import RecommendedSeparator from '../recommendedSeparator';
import IconText from '../iconText';
import { HazardousMaterial } from '../../assets/icons';
import L from '../literal';

interface SummaryProps {
  process: ProcessState;
  processAspects: ProcessAspectState;
  processData: ProcessDataState;
}

export const CompactSummary: React.SFC<SummaryProps> = ({
  process,
  processAspects,
  processData
}) => (
  <aside className="compact-summary">
    <header className="big-summary-header"><L>PROGRESS_STEP__SUMMARY</L></header>

    <h3 className="summary-header"><L>PROGRESS_STEP__SEPARATION_TASK</L></h3>
    <span className="compact-summary-description">
      <L>{process.activeProcess!.translationKey}</L>
    </span>

    <h3 className="summary-header"><L>PROGRESS_STEP__PROCESS_DATA</L></h3>
    <div className="compact-summary-content">
      {processData.primaryLiquidTotalFlowRate && <RecommendedSeparator />}

      {processData.hasEnteredData && (
        <div className="compact-summary-collapsible">
          <PerformanceTargetSummary
            showHeader={false}
            activeProcess={process.activeProcess!}
            processData={processData}
          />

          {processData.otherPerformanceAspects && (
            <IconText className="added-performance-aspects" text={<L>OTHER_PERFORMANCE_ASPECTS_ADDED</L>} icon="md-checkmark" />
          )}

          <SeparationPropertiesSummary
            activeProcess={process.activeProcess!}
            processData={processData}
          />
          <SeparabilitySummary
            activeProcess={process.activeProcess!}
            processData={processData}
          />
        </div>
      )}
    </div>

    <h3 className="summary-header"><L>PROGRESS_STEP__PROCESS_ASPECTS</L></h3>
    <div className="compact-summary-content">
      {processAspects.processDescription && <IconText text={<L>BRIEF_PROCESS_DESCRIPTION_ADDED</L>} icon="md-checkmark" />}
      {processAspects.installationEnvironmentConditions && <IconText text={<L>INSTALLATION_ASPECTS_ADDED</L>} icon="md-checkmark" />}
      {processAspects.isHazardousArea && <IconText text={<L>HAZARDOUS_AREA_MARKED</L>} customIcon={HazardousMaterial} />}
    </div>
  </aside>
);

export default connect(
  ({ process, processAspects, processData }: StoreState) => ({
    process,
    processAspects,
    processData
  })
)(CompactSummary);
