import React from 'react';

const PageLoader = () => (
    <div className="spinner">
        <div />
        <div />
        <div />
        <div />
        <div />
    </div>
);

export default PageLoader;