import { ADD_ATTACHMENT, REMOVE_ATTACHMENT, UPDATE_ATTACHMENT } from '../actionTypes';
import { RemoveCaseFileAction, AddCaseFileAction, UpdateCaseFileAction } from './constants';
import { ThunkAction } from 'redux-thunk';
import { StoreState } from '..';
import caseApi from '../../services/caseApi';
import { registerInteraction } from '../../utilities/analytics';

export default {
  addFiles,
  removeFile
};

export function addFiles(files: File[]): ThunkAction<any, StoreState, any, any> {
  return (dispatch, getState) => {
    const { clientCase, caseFiles } = getState();
    const { caseId } = clientCase;
    const { maxAttachments, attachments } = caseFiles;
    const maxAllowedFiles = maxAttachments - attachments.length;

    const allowedFiles = files
      .filter(file => file.size < 10 * 1024 * 1024)
      .slice(0, maxAllowedFiles);

    if (!caseId && allowedFiles.length > 0) {
      return;
    }

    const parallellUploads = allowedFiles.map((file, offset) => {
      // Temporary id for the attachment
      const id = (Date.now() + offset).toString();

      dispatch<AddCaseFileAction>({
        type: ADD_ATTACHMENT,
        attachment: {
          id,
          name: file.name,
          url: '',
          isLoading: true
        }
      });

      return caseApi
        .uploadCaseFile(caseId, file)
        .then(attachment => {
          dispatch<UpdateCaseFileAction>({
            type: UPDATE_ATTACHMENT,
            id,
            updatedAttachment: { ...attachment, isLoading: false }
          });
          // here we can register an event for 'Upload'
          registerInteraction('Upload','Selector');
        })
        .catch(() => {
          dispatch<RemoveCaseFileAction>({ type: REMOVE_ATTACHMENT, id });
        });
    });

    return Promise.all(parallellUploads);
  };
}

export function removeFile(id: string): ThunkAction<any, StoreState, any, any> {
  return (dispatch, getState) => {
    const { clientCase, caseFiles } = getState();
    const { caseId } = clientCase;

    const attachmentToDelete = caseFiles.attachments.find(attachment => attachment.id === id);

    if (!attachmentToDelete) {
      return;
    }

    dispatch<UpdateCaseFileAction>({
      type: UPDATE_ATTACHMENT,
      id,
      updatedAttachment: { ...attachmentToDelete, isLoading: true }
    });

    return caseApi.deleteCaseFile(caseId, id)
      .then(() => dispatch<RemoveCaseFileAction>({ type: REMOVE_ATTACHMENT, id }));
  };
}
