import { Action } from 'redux';

export const MODAL_OPEN = 'MODAL_OPEN';

export enum MODAL_TYPES {
  SAVE_DATA = 'SAVE_DATA',
  DATA_SAVED = 'DATA_SAVED',
  CLEAR_DATA = 'CLEAR_DATA',
  LEAVE_PAGE = 'LEAVE_PAGE',
  THANK_YOU = 'THANK_YOU',
  LEGAL = 'LEGAL',
  PRIVACY = 'PRIVACY'
}

export interface ModalState {
  modalType: string;
  modalArguments?: any;
}

export interface ModalAction extends Action {
  modalType: string;
  modalArguments?: any;
}
