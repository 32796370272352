import { UPDATE_PROCESSASPECT_FIELD, CLEAR_DATA, RESTORE_DATA } from '../actionTypes';
import { ProcessAspectAction, ProcessAspectState } from './constants';
import { StoreState } from '..';
import createActionHandler from '../createActionHandler';

const initialState: ProcessAspectState = {
  processDescription: '',
  installationEnvironmentConditions: '',
  isHazardousArea: false,
  selectedExStandards: [],
  selectedControlLocations: [],
  exStandardOptions: Object.freeze([
    { label: 'ATEX', value: 'ATEX' },
    { label: 'IECEX', value: 'IECEX' },
    { label: 'NEC505', value: 'NEC505' },
    { label: 'Other', value: 'Other' }
  ]),
  controlLocationOptions: Object.freeze([
    { label: 'Safe area', value: 'Safe area' },
    { label: 'Hazardous area', value: 'Hazardous area' }
  ])
};

const actionHandlers = {
  [CLEAR_DATA]: (state: ProcessAspectState): ProcessAspectState => ({
    ...initialState
  }),

  [RESTORE_DATA]: (state: ProcessAspectState, { processAspects: restoreData }: StoreState): ProcessAspectState => ({
    ...state,
    ...restoreData
  }),

  [UPDATE_PROCESSASPECT_FIELD]: (state: ProcessAspectState, { property, value }: ProcessAspectAction): ProcessAspectState => ({
    ...state,
    [property]: value
  })
};

export const processAspects = createActionHandler(initialState, actionHandlers);
