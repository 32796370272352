import { Action } from 'redux';

export enum PROCESSDATA_FIELDS {
  OTHER_PERFORMANCE_ASPECTS = 'otherPerformanceAspects',
  PARTICLE_SIZE = 'particleSize',
  PRIMARY_LIQUID_CORROSIVE = 'primaryLiquidCorrosive',
  PRIMARY_LIQUID_DENSITY = 'primaryLiquidDensity',
  PRIMARY_LIQUID_DENSITY_MEASURED_AT_TEMPERATURE = 'primaryLiquidDensityMeasuredAtTemperature',
  PRIMARY_LIQUID_DISTRIBUTION = 'primaryLiquidDistribution',
  PRIMARY_LIQUID_DROPLET_SIZE = 'primaryLiquidDropletSize',
  PRIMARY_LIQUID_PROCESS_PRESSURE = 'primaryLiquidProcessPressure',
  PRIMARY_LIQUID_PROCESS_TEMPERATURE = 'primaryLiquidProcessTemperature',
  PRIMARY_LIQUID_PRODUCT_NAME = 'primaryLiquidProductName',
  PRIMARY_LIQUID_TOTAL_FLOWRATE = 'primaryLiquidTotalFlowRate',
  PRIMARY_LIQUID_VISCOSITY = 'primaryLiquidViscosity',
  PRIMARY_LIQUID_VISCOSITY_MEASURED_AT_TEMPERATURE = 'primaryLiquidViscosityMeasuredAtTemperature',
  SECONDARY_LIQUID_CONCENTRATION = 'secondaryLiquidConcentration',
  SECONDARY_LIQUID_CONCENTRATION_AFTER_SEPARATION = 'secondaryLiquidConcentrationAfterSeparation',
  LIQUID_CONCENTRATION_UNIT = 'liquidConcentrationUnit',
  SECONDARY_LIQUID_DENSITY = 'secondaryLiquidDensity',
  SECONDARY_LIQUID_DENSITY_MEASURED_AT_TEMPERATURE = 'secondaryLiquidDensityMeasuredAtTemperature',
  SECONDARY_LIQUID_DISTRIBUTION = 'secondaryLiquidDistribution',
  SECONDARY_LIQUID_DROPLET_SIZE = 'secondaryLiquidDropletSize',
  SECONDARY_LIQUID_PRODUCT_NAME = 'secondaryLiquidProductName',
  SOLID_CONCENTRATION_AFTER_SEPARATION = 'solidConcentrationAfterSeparation',
  SOLID_CONCENTRATION_INFEED = 'solidConcentrationInFeed',
  SOLID_CONCENTRATION_UNIT = 'solidConcentrationUnit',
  SOLID_DENSITY = 'solidDensity',
  SOLID_DISTRIBUTION = 'solidDistribution',
  SOLID_DROPLET_SIZE = 'solidDropletSize',
  SOLID_KNOWN_TO_BE_EROSIVE = 'solidKnownToBeErosive',
  SOLIDS_TYPE = 'solidsType',
  CUSTOMIZED_AE = 'customizedAe',
}

export interface UpdateProcessDataFieldAction extends Action {
  property: PROCESSDATA_FIELDS;
  value: string | boolean | number;
}

export interface ProcessDataState {
  hasEnteredData: boolean;
  [PROCESSDATA_FIELDS.OTHER_PERFORMANCE_ASPECTS]: string | undefined;
  [PROCESSDATA_FIELDS.PARTICLE_SIZE]: string | undefined;
  [PROCESSDATA_FIELDS.PRIMARY_LIQUID_CORROSIVE]: boolean;
  [PROCESSDATA_FIELDS.PRIMARY_LIQUID_DENSITY]: string | undefined;
  [PROCESSDATA_FIELDS.PRIMARY_LIQUID_DENSITY_MEASURED_AT_TEMPERATURE]: string | undefined;
  [PROCESSDATA_FIELDS.PRIMARY_LIQUID_DISTRIBUTION]: string | undefined;
  [PROCESSDATA_FIELDS.PRIMARY_LIQUID_DROPLET_SIZE]: string | undefined;
  [PROCESSDATA_FIELDS.PRIMARY_LIQUID_PROCESS_PRESSURE]: string | undefined;
  [PROCESSDATA_FIELDS.PRIMARY_LIQUID_PROCESS_TEMPERATURE]: string | undefined;
  [PROCESSDATA_FIELDS.PRIMARY_LIQUID_PRODUCT_NAME]: string | undefined;
  [PROCESSDATA_FIELDS.PRIMARY_LIQUID_TOTAL_FLOWRATE]: string | undefined;
  [PROCESSDATA_FIELDS.PRIMARY_LIQUID_VISCOSITY]: string | undefined;
  [PROCESSDATA_FIELDS.PRIMARY_LIQUID_VISCOSITY_MEASURED_AT_TEMPERATURE]: string | undefined;
  [PROCESSDATA_FIELDS.SECONDARY_LIQUID_CONCENTRATION]: string | undefined;
  [PROCESSDATA_FIELDS.SECONDARY_LIQUID_CONCENTRATION_AFTER_SEPARATION]: string | undefined;
  [PROCESSDATA_FIELDS.LIQUID_CONCENTRATION_UNIT]: string | undefined;
  [PROCESSDATA_FIELDS.SECONDARY_LIQUID_DENSITY]: string | undefined;
  [PROCESSDATA_FIELDS.SECONDARY_LIQUID_DENSITY_MEASURED_AT_TEMPERATURE]: string | undefined;
  [PROCESSDATA_FIELDS.SECONDARY_LIQUID_DISTRIBUTION]: string | undefined;
  [PROCESSDATA_FIELDS.SECONDARY_LIQUID_DROPLET_SIZE]: string | undefined;
  [PROCESSDATA_FIELDS.SECONDARY_LIQUID_PRODUCT_NAME]: string | undefined;
  [PROCESSDATA_FIELDS.SOLID_CONCENTRATION_AFTER_SEPARATION]: string | undefined;
  [PROCESSDATA_FIELDS.SOLID_CONCENTRATION_UNIT]: string | undefined;
  [PROCESSDATA_FIELDS.SOLID_CONCENTRATION_INFEED]: string | undefined;
  [PROCESSDATA_FIELDS.SOLID_DENSITY]: string | undefined;
  [PROCESSDATA_FIELDS.SOLID_DISTRIBUTION]: string | undefined;
  [PROCESSDATA_FIELDS.SOLID_DROPLET_SIZE]: string | undefined;
  [PROCESSDATA_FIELDS.SOLID_KNOWN_TO_BE_EROSIVE]: boolean;
  [PROCESSDATA_FIELDS.SOLIDS_TYPE]: string | undefined;
  [PROCESSDATA_FIELDS.CUSTOMIZED_AE]: string | undefined;
}
