import React from 'react';
import ReactTooltip from 'react-tooltip';
import { FormElement, FormElementProps } from '../formElement';
import Ionicon from 'react-ionicons';
import { guid } from '../../utilities/guid';

interface CheckBoxProps extends FormElementProps {
  onChange?: (checkedState: boolean) => any;
  label?: any;
  checked?: boolean;
}

const CheckBox: React.SFC<CheckBoxProps> = ({
  onChange,
  checked,
  label,
  tooltip,
  ...restProps
}) => {
  const myId = guid();
  return (
    <FormElement {...restProps} elementClassName="checkbox">
      <label className="checkbox-wrapper">
        <input
          type="checkbox"
          className="native-checkbox"
          checked={checked}
          onChange={e => onChange!(e.target.checked)}
        />
        <span className="checkbox-proxy shaded-box" />
        {label && <span className="checkbox-label">{label}</span>}
      </label>
      {tooltip && (
        <>
          <span
            className="custom-tooltip tooltip-icon"
            data-tip={tooltip}
            data-for={myId}
          >
            <Ionicon className="tooltip-question-icon" icon="md-help-circle" />
          </span>
          <ReactTooltip id={myId} type="dark" effect="solid">
            <div className="tooltipBox">{tooltip}</div>
          </ReactTooltip>
        </>
      )}
    </FormElement>
  );
};

CheckBox.defaultProps = {
  onChange: () => null
};

export default CheckBox;
