const trackingId = process.env.REACT_APP_GA_TRACKING_ID;

export function registerPageView() {
  if (trackingId && trackingId !== 'NONE' && gtag) {
    gtag('config', trackingId);
  }
}

export function registerInteraction(eventCategory: string, eventLabel: string) {
  if (trackingId && trackingId !== 'NONE' && gtag) {
    gtag('event', 'Click', {
      send_to: trackingId,
      event_category: eventCategory,
      event_label: eventLabel
    });
  }
}
