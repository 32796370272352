import React from 'react';
import {
  Route,
  RouteComponentProps,
  Switch,
  Redirect,
  RouteProps
} from 'react-router-dom';
import SolidLiquid from './solidLiquid';
import LiquidLiquidSolid from './liquidLiquidSolid';
import LiquidLiquid from './liquidLiquid';
import ProcessAspects from './processAspects';
import CompactSummary from '../components/summary/compactSummary';
import ProgressSteps from '../components/progressSteps';
import ProgressStepper from '../components/progressStepper';
import SaveAndClear from '../components/saveAndClear';

const ProcessLayout: React.SFC<RouteComponentProps<RouteProps & any>> = ({
  match
}) => (
  <>
    <div className="container">
      <ProgressSteps />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-8 col-md-12 form-wrapper">
          <Switch>
            <Route
              path={`${match.url}/:task/process-aspects`}
              component={ProcessAspects}
            />
            <Route path={`${match.url}/solid-liquid`} component={SolidLiquid} />
            <Route
              path={`${match.url}/liquid-liquid`}
              component={LiquidLiquid}
            />
            <Route
              path={`${match.url}/liquid-liquid-solid`}
              component={LiquidLiquidSolid}
            />
            <Redirect to="/process-selection" />
          </Switch>
        </div>
        <div className="col-lg-4 col-md-12 summary-wrapper">
          <CompactSummary />
          <SaveAndClear />
        </div>
      </div>
    </div>

    <ProgressStepper />
  </>
);

export default ProcessLayout;
