import React from 'react';
import Ionicon from 'react-ionicons';

interface ModalProps {
  children?: React.ReactChild | React.ReactChild[];
  title: string | React.ReactNode;
  actions: {
    closeModal: () => any;
  };
}

class Modal extends React.PureComponent<ModalProps> {
  modal: HTMLDivElement | null;
  modalBackdrop: HTMLDivElement | null;

  constructor(props) {
    super(props);
    this.modal = null;
    this.modalBackdrop = null;
  }

  closeModal = () => {
    this.props.actions.closeModal();
  }

  onCloseModal = (e: Event) => {
    if (!this.modal!.contains(e.target! as Element)) {
      this.closeModal();
    }
  }

  componentDidMount() {
    document.body.classList.add('prevent-scroll');
    this.modalBackdrop!.addEventListener('click', this.onCloseModal, true);
  }

  componentWillUnmount() {
    document.body.classList.remove('prevent-scroll');
    this.modalBackdrop!.removeEventListener('click', this.onCloseModal);
  }

  render() {
    const { children, title } = this.props;
    return (
      <div className="modal-backdrop" ref={ref => (this.modalBackdrop = ref)}>
        <div className="modal" ref={ref => (this.modal = ref)}>
          <Ionicon className="modal-close" icon="md-close" onClick={this.closeModal} />
          <span className="modal-title">{title}</span>
          {children}
        </div>
      </div>
    );
  }
}

export default Modal;
