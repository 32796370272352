import { SELECT_SEPARATION_TASK, CLEAR_DATA, RESTORE_DATA, CLEAR_SEPARATION_TASK } from '../actionTypes';
import { ProcessState, SelectSeparationTaskProcessAction } from './constants';
import { StoreState } from '..';
import createActionHandler from '../createActionHandler';

const initialState: ProcessState = {
  activeProcess: undefined,
  activeProcessId: '',
  processes: [
    {
      id: 'solid-liquid',
      uri: 'solid-liquid',
      translationKey: 'PROCESS__SOLIDS_FROM_LIQUID',
      imageReference: 'separation1',
      separateLiquids: false,
      separateSolids: true
    },
    {
      id: 'liquid-liquid',
      uri: 'liquid-liquid',
      translationKey: 'PROCESS__LIQUID_FROM_LIQUID',
      imageReference: 'separation2',
      separateLiquids: true,
      separateSolids: false
    },
    {
      id: 'liquid-liquid-solid',
      uri: 'liquid-liquid-solid',
      translationKey: 'PROCESS__LIQUIDS_AND_SOLIDS',
      imageReference: 'separation3',
      separateLiquids: true,
      separateSolids: true
    }
  ]
};

const actionHandlers = {
  [CLEAR_DATA]: (state: ProcessState): ProcessState => ({
    ...initialState
  }),

  [RESTORE_DATA]: (state: ProcessState, { process: restoreData }: StoreState): ProcessState => ({
    ...state,
    ...restoreData,
    activeProcess: state.processes.find(({ id }) => id === restoreData.activeProcessId)
  }),

  [CLEAR_SEPARATION_TASK]: (state: ProcessState): ProcessState => ({
    ...state,
    ...initialState
  }),

  [SELECT_SEPARATION_TASK]: (state: ProcessState, { activeProcessId, activeProcess }: SelectSeparationTaskProcessAction): ProcessState => ({
    ...state,
    activeProcessId,
    activeProcess
  })
};

export const process = createActionHandler(initialState, actionHandlers);
