import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import history from './routerHistory';
import ConfigLoader from './components/configLoader';
import EnsureProcessHasStarted from './components/ensureProcessHasStarted';
import NotFoundPage from './components/notFoundPage';
import ErrorPage from './components/errorPage';
import Footer from './components/footer';
import LoadClientCase from './components/loadClientCase';
import Loader from './components/loader';
import ModalManager from './components/modalManager';
import Nav from './components/nav';
import ScrollToTop from './components/scrollToTop';
import ToolTipManager from './components/toolTipManager';
import UnsavedProgressWarning from './components/unsavedProgressWarning';
import { StoreState } from './store';
import LiveChat from './components/liveChat';
import AsyncComponent from './components/asyncComponent/';
import ProcessLayout from './pages/processLayout';
import Summary from './pages/summary';
import CookieConsent from './components/cookieConsent/';
import GtmLoader from './components/gtmLoader';

const AsyncGetSeparabilityDataPage =  AsyncComponent(() =>  import(/* webpackPrefetch: true */ './pages/getSeparabilityData'));
const AsyncLandingPage =  AsyncComponent(() =>  import(/* webpackPrefetch: true */ './pages/landing'));
const AsyncLabTestPage =  AsyncComponent(() =>  import(/* webpackPrefetch: true */ './pages/labTest'));
const AsyncPilotTestPage =  AsyncComponent(() =>  import(/* webpackPrefetch: true */ './pages/pilotTest'));
const AsyncProcessSelectionPage =  AsyncComponent(() =>  import(/* webpackPrefetch: true */ './pages/processSelection'));
interface AppProps {
  isLoading: boolean;
}
const App: React.SFC<AppProps> = ({ isLoading }) => (
  <ConfigLoader fallback={<Loader />} error={<ErrorPage />}>
    {isLoading && <Loader />}
    <Router history={history}>
      <>
        <CookieConsent />
        <Nav />
        <UnsavedProgressWarning />
        <ScrollToTop />
        <Switch>
          {/* Loading case using a personal link */}
          <Route exact={true} path="/explore/:caseId" component={LoadClientCase} />

          {/* Process routes */}
          <Route exact={true} path="/separation-process/:task/summary" component={EnsureProcessHasStarted(Summary)} />
          <Route path="/separation-process" component={EnsureProcessHasStarted(ProcessLayout)} />
          <Route path="/process-selection" component={AsyncProcessSelectionPage} />
          
          {/* Content pages */}
          <Route exact={true} path="/lab-test" component={AsyncLabTestPage} />
          <Route exact={true} path="/get-separability-data" component={AsyncGetSeparabilityDataPage} />
          <Route exact={true} path="/pilot-test" component={AsyncPilotTestPage} />
          <Route exact={true} path="/" component={AsyncLandingPage} />

          {/* Non-existing urls */}
          <Route path="*" component={NotFoundPage} />
        </Switch>
        <ToolTipManager />
        <ModalManager />
      </>
    </Router>
    <LiveChat  />
    <Footer />
    <GtmLoader />
  </ConfigLoader>
);

export default connect(
  ({ app }: StoreState) => ({
    isLoading: app.isLoading
  })
)(App);
