import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClientCaseActions from '../../store/clientCase/actions';
import ModalActions from '../../store/modal/actions';
import { MODAL_TYPES } from '../../store/modal/constants';
import L from '../literal';

interface SaveAndClearButtonsProps {
  onSave: () => any;
  onClear: () => any;
}

export const SaveAndClear: React.SFC<SaveAndClearButtonsProps> = ({
  onSave,
  onClear
}) => {
  return (
    <div className="save-and-clear-wrapper container">
      <div className="save-and-clear col-md-12 md-offset-0 col-lg-5 lg-offset-7">
      <div className="push-center">
        <button className="btn-save" onClick={onSave}>
          <L>SAVE</L>
        </button>
        <button className="btn-clear" onClick={onClear}>
          <L>CLEAR</L>
        </button>
        </div>
      </div>
    </div>
  );
};

export default connect(null, dispatch =>
  bindActionCreators(
    {
      onSave: () => ClientCaseActions.saveOrShowModal(),
      onClear: () => ModalActions.showModal(MODAL_TYPES.CLEAR_DATA)
    },
    dispatch
  )
)(SaveAndClear);
