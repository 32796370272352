import {
  ADD_LABTEST,
  CLEAR_DATA,
  FETCH_SAVE_CLIENTCASE_SUCCESS,
  HIDE_LOADER,
  REMOVE_LABTEST,
  RESTORE_DATA,
  SET_LABTEST_TYPE,
  SHOW_LOADER,
  TOGGLE_LABTEST,
  UPDATE_PROCESSASPECT_FIELD,
  UPDATE_PROCESSDATA_FIELD
} from '../actionTypes';
import { AppState } from './constants';
import createActionHandler from '../createActionHandler';

const initialState: AppState = {
  contentIsDirty: false,
  isLoading: false
};

const actionHandlers = {
  [CLEAR_DATA]: (state: AppState): AppState => ({
    ...state,
    ...initialState
  }),

  [RESTORE_DATA]: (state: AppState): AppState => ({
    ...state,
    contentIsDirty: false,
    isLoading: false
  }),

  [FETCH_SAVE_CLIENTCASE_SUCCESS]: (state: AppState): AppState => ({
    ...state,
    contentIsDirty: false
  }),

  [UPDATE_PROCESSASPECT_FIELD]: (state: AppState): AppState => ({
    ...state,
    contentIsDirty: true
  }),

  [UPDATE_PROCESSDATA_FIELD]: (state: AppState): AppState => ({
    ...state,
    contentIsDirty: true
  }),

  [SHOW_LOADER]: (state: AppState): AppState => ({
    ...state,
    isLoading: true
  }),

  [HIDE_LOADER]: (state: AppState): AppState => ({
    ...state,
    isLoading: false
  }),

  [TOGGLE_LABTEST]: (state: AppState): AppState => ({
    ...state,
    contentIsDirty: true
  }),

  [SET_LABTEST_TYPE]: (state: AppState): AppState => ({
    ...state,
    contentIsDirty: true
  }),

  [ADD_LABTEST]: (state: AppState): AppState => ({
    ...state,
    contentIsDirty: true
  }),

  [REMOVE_LABTEST]: (state: AppState): AppState => ({
    ...state,
    contentIsDirty: true
  })
};

export const app = createActionHandler(initialState, actionHandlers);
