import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GlobeIcon } from "../../assets/icons";
import { StoreState } from "../../store";
import LanguageActions from "../../store/language/actions";
import { Language } from "../../store/language/constants";
import { getTranslations } from "../../services/configApi";

interface LanguagePickerProps {
  languages: Language[];
  languageCode: string;
  setLanguage: (languageCode) => any;
  loadTranslations: (translations) => any;
}

const LanguagePicker: React.FunctionComponent<LanguagePickerProps> = ({
  languages,
  languageCode,
  setLanguage,
  loadTranslations,
}) => {
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null);

  const listener = useRef((e) => {
    const containerEl = containerRef.current! as HTMLElement;
    const menuClicked =
      e.target === containerEl || containerEl.contains(e.target);
    if (!menuClicked) {
      closeMenu();
    }
  });

  const openMenu = () => {
    if (!open) {
      window.document.addEventListener("mouseup", listener.current);
      setOpen(true);
    } else {
      closeMenu();
    }
  };

  const closeMenu = () => {
    window.document.removeEventListener("mouseup", listener.current);
    setOpen(false);
  };

  const selectLanguage = async (language) => {
    localStorage.setItem("language", language.code);
    setLanguage(language.code);
    closeMenu();
    loadTranslations(await getTranslations(language.code));
  };

  return (
    <div ref={containerRef} style={{ position: "relative" }}>
      <button className="topbar-button" onClick={openMenu}>
        <span className="topbar-button-icon">
          <GlobeIcon />
        </span>
        <span>{languages.find((x) => x.code === languageCode)!.name}</span>
      </button>
      <div className={`language-picker-menu ${open ? "open" : ""}`}>
        <ul>
          {languages.map((language) => (
            <li
              key={language.code}
              className={
                languageCode === language.code ? "language-picker-selected" : ""
              }
            >
              <button
                className="language-picker-link"
                onClick={() => selectLanguage(language)}
              >
                {language.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default connect(
  ({ language }: StoreState) => ({
    languages: language.languages,
    languageCode: language.languageCode,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setLanguage: (languageCode) =>
          LanguageActions.setLanguage(languageCode),
        loadTranslations: (translations) =>
          LanguageActions.loadTranslations(translations),
      },
      dispatch
    )
)(LanguagePicker);
