/* tslint:disable */
import React from 'react';
import { Creatable } from 'react-select';
import ReactTooltip from 'react-tooltip';
import Ionicon from 'react-ionicons';
import { guid } from '../../utilities/guid';
import L from '../literal';
import useLiteral from '../../hooks/useLiteral';

const isMobile = (window.innerWidth <= 768);
const isDesktop = (window.innerWidth > 768);

// HACK for Edge browsers until they support localized decimals for
// number inputs
const userAgent = window.navigator.userAgent.toLowerCase();
const replaceCommaWithDotForEdgeBrowser =  (userAgent.includes('edge/') || userAgent.includes('trident/7')) && {
  onKeyDown: (e: any) => {
    if (e.key === ',') {
      e.preventDefault();
      e.target.value += '.';
    }
  }
};

const convertUnitToSelectItem = (unit: string) => {
  const result: { value: string; label: string } = {
    value: unit,
    label: unit
  };
  return result;
};

const convertUnitsForSelect = (units: string[], currentValue: string, userSpecificLabel: string) => {
  const options = units.map(convertUnitToSelectItem);

  if (!options.find(option => option.value === currentValue)) {
    options.push({
      value: currentValue,
      label: [userSpecificLabel, currentValue && `'${currentValue}'`].join(' ')
    });
  }

  return options;
};

const getInnerLayout = (units: string[]) => {
  const hasUnits = units && units.length > 1;
  if (hasUnits) {
    return 'col-8';
  } else {
    return 'col-12';
  }
};
const getLabelLayout = (units: string[]) => {
  const hasUnits = units && units.length > 1;
  if (hasUnits) {
    return 'col-12';
  } else {
    return 'col-12';
  }
};
const getLabelRowLayout = (units: string[], layout: string) => {
  const hasUnits = !units || units.length <= 1;
  if (hasUnits && layout === 'full') {
    return 'row justify-content-between';
  } else {
    return 'row';
  }
};
const getInnerLayoutReverse = (units: string[]) => {
  const hasUnits = units && units.length > 1;
  if (hasUnits) {
    return 'col-4';
  } else {
    return 'col-12';
  }
};
const getLayout = (layout: string) => {
    if (layout === 'full') {
        return 'col-12';
    }
    if (layout === 'half' && isDesktop) {
        return 'col-6';
    } else if (layout === 'half' && isMobile) {
        return 'col-12';
    }
    return '';
};

const Input: React.FunctionComponent<any> = props => {
  const userSpecificLabel = useLiteral('USER_SPECIFIC')

  const {
    allowCustomUnit,
    disabled,
    id,
    name,
    label,
    type = 'text',
    value,
    min,
    max,
    placeholder,
    step,
    onKeyDown
  } = props;
  const hasSingleUnit =
    props.newUnits && props.newUnits.length === 1;
  const unit = hasSingleUnit ? props.newUnits[0] : null;
  const hasManyUnits = props.newUnits && props.newUnits.length > 1;
  return (
    <>
      <div className="container">
        <div
          className={`${getLabelRowLayout(
            props.newUnits,
            props.layout
          )}`}
        >
          <div className={`${getLabelLayout(props.newUnits)} input`}>
            {label && (
              <label htmlFor={name}>
                <span>{label} </span>
                <ReactTooltip
                  id={id}
                  type="dark"
                  effect="solid"
                >
                  <div className="tooltipBox">
                    {props.tooltip && props.tooltip()}
                  </div>
                </ReactTooltip>
              </label>
            )}
          </div>

          {props.tooltip && (
            <div className="align-content-center">
              <span data-tip className="tooltip-wrapper" data-for={id}>
                <Ionicon
                  className="tooltip-question-icon"
                  icon="md-help-circle"
                />
              </span>
            </div>
          )}
        </div>
        <div className="row align-bottom">
          <div className={getInnerLayout(props.newUnits)}>
            <div id={id} className="input">
              <input
                className="form-control"
                value={value}
                disabled={disabled}
                type={type}
                min={min}
                max={max}
                step={step}
                placeholder={placeholder}
                onKeyDown={onKeyDown}
                onChange={v => props.change(v.target.value)}
              />
              {hasSingleUnit && <span className="unit">{unit}</span>}
            </div>
          </div>
          {hasManyUnits ? (
            <div className={getInnerLayoutReverse(props.newUnits)}>
              <div className="UndoMargin dropdown">
                <Creatable
                  searchable={!!allowCustomUnit}
                  clearable={false}
                  name="form-field-name"
                  placeholder={<L>UNIT</L>}
                  promptTextCreator={label => <L replacements={{ label }}>USE_CUSTOM_UNIT</L>}
                  options={convertUnitsForSelect(props.newUnits, props.unit, userSpecificLabel)}
                  value={props.unit}
                  onChange={(v: any) =>
                    props.changeUnit &&
                    props.changeUnit(v && v.value)
                  }
                  wrapperStyle={{ width: '100%' }}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {props.validation && (
          <div className="row">
            <div className="col-12">
              {props.validation(props.value)}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export class Field extends React.Component<any> {
  myId: string = guid();
  render() {
    return (
      <div className={getLayout(this.props.layout)}>
        <Input
          id={this.myId}
          name="s"
          type="text"
          change={(v: any) => this.props.change && this.props.change(v)}
          changeUnit={(v: any) =>
            this.props.changeUnit && this.props.changeUnit(v)
          }
          unit={this.props.unit}
          label={this.props.title}
          layout={this.props.layout}
          tooltip={this.props.tooltip}
          newUnits={this.props.units}
          validation={this.props.validation}
          placeholder={this.props.placeholder}
          value={this.props.value ? this.props.value : ''}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}
export class NumericField extends React.Component<any> {
  myId: string = guid();
  render() {
    return (
      <div className={getLayout(this.props.layout)}>
        <Input
          id={this.myId}
          name="s"
          type="number"
          {...replaceCommaWithDotForEdgeBrowser}
          change={(v: any) => this.props.change && this.props.change(v)}
          changeUnit={(v: any) =>
            this.props.changeUnit && this.props.changeUnit(v)
          }
          allowCustomUnit={this.props.allowCustomUnit}
          unit={this.props.unit}
          label={this.props.title}
          layout={this.props.layout}
          tooltip={this.props.tooltip}
          step={this.props.step}
          newUnits={this.props.units}
          validation={this.props.validation}
          disabled={this.props.disabled}
          value={this.props.value ? this.props.value : ''}
          onKeyDown={this.props.onKeyDown}
        />
      </div>
    );
  }
}
