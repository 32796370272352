import { UPDATE_PROCESSDATA_FIELD, CLEAR_DATA, RESTORE_DATA } from '../actionTypes';
import {
  ProcessDataState,
  UpdateProcessDataFieldAction
} from './constants';
import { StoreState } from '..';
import createActionHandler from '../createActionHandler';

const initialState: ProcessDataState = {
  hasEnteredData: false,
  otherPerformanceAspects: '',
  particleSize: '',
  primaryLiquidCorrosive: false,
  primaryLiquidDensity: '',
  primaryLiquidDensityMeasuredAtTemperature: '',
  primaryLiquidDistribution: '',
  primaryLiquidDropletSize: '',
  primaryLiquidProcessPressure: '',
  primaryLiquidProductName: '',
  primaryLiquidTotalFlowRate: '',
  primaryLiquidViscosity: '',
  primaryLiquidViscosityMeasuredAtTemperature: '',
  primaryLiquidProcessTemperature: '',
  secondaryLiquidConcentration: '',
  secondaryLiquidConcentrationAfterSeparation: '',
  liquidConcentrationUnit: '',
  secondaryLiquidDensity: '',
  secondaryLiquidDensityMeasuredAtTemperature: '',
  secondaryLiquidDistribution: '',
  secondaryLiquidDropletSize: '',
  secondaryLiquidProductName: '',
  solidConcentrationAfterSeparation: '',
  solidConcentrationUnit: '',
  solidConcentrationInFeed: '',
  solidDensity: '',
  solidDistribution: '',
  solidDropletSize: '',
  solidKnownToBeErosive: false,
  solidsType: '',
  customizedAe: undefined
};

const actionHandlers = {
  [CLEAR_DATA]: (state: ProcessDataState): ProcessDataState => ({
    ...initialState
  }),

  [RESTORE_DATA]: (state: ProcessDataState, { processData: restoreData }: StoreState): ProcessDataState => ({
    ...state,
    ...restoreData
  }),

  [UPDATE_PROCESSDATA_FIELD]: (state: ProcessDataState, { property, value }: UpdateProcessDataFieldAction): ProcessDataState => ({
    ...state,
    hasEnteredData: true,
    [property]: value
  })
};

export const processData = createActionHandler(initialState, actionHandlers);
