import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { MODAL_TYPES } from '../../store/modal/constants';
import Modal from '../modal';
import ModalActions from '../../store/modal/actions';
import ClearDataForm from '../forms/clearDataForm';
import DataSavedForm from '../forms/dataSavedForm';
import LegalTermsModal from '../forms/legalTermsModal';
import PrivacyPolicyModal from '../forms/privacyPolicyModal';
import SaveDataForm from '../forms/saveDataForm';
import ThankYouForm from '../forms/thankYouForm';
import UnsavedDataForm from '../forms/unsavedProgressForm';
import L from '../literal';

interface ModalManagerProps {
  modalType: string;
  modalArguments: string;
  modalActions: {
    showModal: (modalType: MODAL_TYPES) => any;
    closeModal: () => any;
  };
}

export const ModalManager: React.SFC<ModalManagerProps> = ({
  modalType,
  modalArguments,
  modalActions
}) => {
  switch (modalType) {
    case MODAL_TYPES.CLEAR_DATA:
      return (
        <Modal title={<L>MODAL_CLEAR_DATA__TITLE</L>} actions={modalActions}>
          <ClearDataForm />
        </Modal>
      );

    case MODAL_TYPES.SAVE_DATA:
      return (
        <Modal title={<L>MODAL_SAVE_DATA__TITLE</L>} actions={modalActions}>
          <SaveDataForm />
        </Modal>
      );

    case MODAL_TYPES.DATA_SAVED:
      return (
        <Modal title={<L>MODAL_DATA_SAVED__TITLE</L>} actions={modalActions}>
          <DataSavedForm />
        </Modal>
      );

    case MODAL_TYPES.LEGAL:
      return (
        <Modal title={<L>MODAL_LEGAL__TITLE</L>} actions={modalActions}>
          <LegalTermsModal />
        </Modal>
      );

    case MODAL_TYPES.PRIVACY:
      return (
        <Modal title={<L>MODAL_PRIVACY__TITLE</L>} actions={modalActions}>
          <PrivacyPolicyModal />
        </Modal>
      );

    case MODAL_TYPES.LEAVE_PAGE:
      return (
        <Modal title={<L>MODAL_LEAVE_PAGE__TITLE</L>} actions={modalActions}>
          <UnsavedDataForm redirectTo={modalArguments} />
        </Modal>
      );

    case MODAL_TYPES.THANK_YOU:
      return (
        <Modal title={<L>MODAL_THANK_YOU__TITLE</L>} actions={modalActions}>
          <ThankYouForm />
        </Modal>
      );

    default:
      return null;
  }
};

export default connect(
  ({  modal }: StoreState) => ({
    ...modal
  }),
  dispatch => ({
    modalActions: bindActionCreators(ModalActions, dispatch)
  })
)(ModalManager);
