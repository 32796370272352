import { UPDATE_PROCESSDATA_FIELD, CLEAR_DATA, RESTORE_DATA } from '../actionTypes';
import { RecommendedSeparatorState } from './constants';
import { UpdateProcessDataFieldAction, PROCESSDATA_FIELDS } from '../processData/constants';
import { StoreState } from '..';
import createActionHandler from '../createActionHandler';

const initialState: RecommendedSeparatorState = {
  separatorSize: 'unknown',
  description: 'Small sized separator',
  descriptionTranslationKey: 'SMALL_SIZED_SEPARATOR',
  separators: [
    {
      name: 'Small separator',
      nameTranslationKey: 'SMALL_SEPARATOR',
      description: 'Small sized separator',
      descriptionTranslationKey: 'SMALL_SEPARATOR_DESCRIPTION',
      separatorSize: 'small',
      requirement: flowRate => flowRate > 0 && flowRate <= 4,
      Ae: 4797
    },
    {
      name: 'Medium separator',
      nameTranslationKey: 'MEDIUM_SEPARATOR',
      description: 'Medium sized separator',
      descriptionTranslationKey: 'MEDIUM_SEPARATOR_DESCRIPTION',
      separatorSize: 'medium',
      requirement: flowRate => flowRate > 4 && flowRate <= 25,
      Ae: 61659
    },
    {
      name: 'Large separator',
      nameTranslationKey: 'LARGE_SEPARATOR',
      description: 'Large sized separator',
      descriptionTranslationKey: 'LARGE_SEPARATOR_DESCRIPTION',
      separatorSize: 'large',
      requirement: flowRate => flowRate > 25,
      Ae: 149643
    }
  ]
};

const getSeparatorInfo = (flowRate: any, separators: Array<any>) => {
  const separator = separators.find(s => s.requirement(flowRate));
  const description = (separator && separator.description) || separators[0].description;
  const descriptionTranslationKey = (separator && separator.descriptionTranslationKey) || separators[0].descriptionTranslationKey;
  const { separatorSize = 'unknown' } = separator || {};

  return {
    separatorSize,
    description,
    descriptionTranslationKey
  };
};

const actionHandlers = {
  [CLEAR_DATA]: (state: RecommendedSeparatorState): RecommendedSeparatorState => ({
    ...initialState
  }),

  [RESTORE_DATA]: (state: RecommendedSeparatorState, { processData: restoreData }: StoreState): RecommendedSeparatorState => ({
    ...state,
    ...getSeparatorInfo(restoreData.primaryLiquidTotalFlowRate!, state.separators)
  }),

  [UPDATE_PROCESSDATA_FIELD]: (state: RecommendedSeparatorState, { property, value: flowRate }: UpdateProcessDataFieldAction): RecommendedSeparatorState => {
    // Listen to flow rate updates only so we can update the recommended separator
    if (property !== PROCESSDATA_FIELDS.PRIMARY_LIQUID_TOTAL_FLOWRATE) {
      return state;
    }

    return {
      ...state,
      ...getSeparatorInfo(flowRate, state.separators)
    };
  }
};

export const recommendedSeparator = createActionHandler(initialState, actionHandlers);
