import React from 'react';
import { ProcessDataState } from '../../store/processData/constants';
import { ProcessInfo } from '../../store/process/constants';
import PerformanceGraph from '../../components/performanceGraph';
import L from '../../components/literal';

interface SummaryPerformanceGraphProps {
  processData: ProcessDataState;
  activeProcess: ProcessInfo;
}

const SummaryPerformanceGraph: React.SFC<SummaryPerformanceGraphProps> = ({
  processData,
  activeProcess
}) => {
  const isSolidLiquid = activeProcess.id === 'solid-liquid';
  const isLiquidLiquidSolid = activeProcess.id === 'liquid-liquid-solid';

  const sharedProps = {
    primaryDensity: processData.primaryLiquidDensity,
    primaryViscosity: processData.primaryLiquidViscosity,
    flowRate: processData.primaryLiquidTotalFlowRate
  };
  const mainGraphProps = {
    dropletOrParticleSize: isLiquidLiquidSolid
      ? processData.secondaryLiquidDropletSize
      : isSolidLiquid
        ? processData.particleSize
        : processData.primaryLiquidDropletSize,
    distribution: isLiquidLiquidSolid ? processData.secondaryLiquidDistribution : processData.primaryLiquidDistribution,
    concentration: isSolidLiquid ? processData.solidConcentrationInFeed : processData.secondaryLiquidConcentration,
    secondaryDensity: isSolidLiquid ? processData.solidDensity : processData.secondaryLiquidDensity,
    desiredConcentration: isSolidLiquid ? processData.solidConcentrationAfterSeparation : processData.secondaryLiquidConcentrationAfterSeparation,
    concentrationUnit: isSolidLiquid ? processData.solidConcentrationUnit : processData.liquidConcentrationUnit
  };

  const secondaryGraphProps = {
    dropletOrParticleSize: processData.solidDropletSize,
    distribution: processData.solidDistribution,
    concentration: processData.solidConcentrationInFeed,
    secondaryDensity: processData.solidDensity,
    desiredConcentration: processData.solidConcentrationAfterSeparation,
    concentrationUnit: processData.solidConcentrationUnit
 };

  return (
    <div className="summary-performance-graph">
      {isLiquidLiquidSolid && (
        <>
          <input name="performance-graph" type="radio" id="toggle-liquid-separability-graph" defaultChecked={true} />
          <input name="performance-graph" type="radio" id="toggle-solids-separability-graph" />
          <div className="performance-graph-buttons">
            <span className="showing-label">
              <L>SHOWING_GRAPH_FOR</L>
            </span>
            <label className="btn smaller-btn toggle-liquid-label" htmlFor="toggle-liquid-separability-graph">
              <L>SECONDARY_LIQUID_SEPARABILITY_DATA</L>
            </label>
            <label className="btn smaller-btn toggle-solids-label" htmlFor="toggle-solids-separability-graph">
              <L>SOLIDS_SEPARABILITY_DATA</L>
            </label>
          </div>
        </>
      )}

      <PerformanceGraph className="liquid-separability-graph" {...sharedProps} {...mainGraphProps} />

      {isLiquidLiquidSolid && <PerformanceGraph className="solids-separability-graph graph" {...sharedProps} {...secondaryGraphProps} />}
    </div>
  );
};

export default SummaryPerformanceGraph;
