import { SELECT_PROGRESS_STEP, CLEAR_DATA, RESTORE_DATA, FINISH_PROCESS } from '../actionTypes';
import { ProgressState, ProgressAction } from './constants';
import { StoreState } from '..';
import createActionHandler from '../createActionHandler';

const initialState: ProgressState = {
  processHasStarted: false,
  activeStep: 0,
  steps: [
    {
      id: 0,
      getUri: () => '/process-selection',
      translationKey: 'PROGRESS_STEP__SEPARATION_TASK',
      activated: false
    },
    {
      id: 1,
      getUri: (state: StoreState) => `/separation-process/${state.process.activeProcess!.uri}/fluid-data`,
      translationKey: 'PROGRESS_STEP__PROCESS_DATA',
      activated: false
    },
    {
      id: 2,
      getUri: (state: StoreState) => `/separation-process/${state.process.activeProcess!.uri}/process-aspects`,
      translationKey: 'PROGRESS_STEP__PROCESS_ASPECTS',
      activated: false
    },
    {
      id: 3,
      getUri: (state: StoreState) => `/separation-process/${state.process.activeProcess!.uri}/summary`,
      translationKey: 'PROGRESS_STEP__SUMMARY',
      activated: false,
      hidden: true
    }
  ]
};

const actionHandlers = {
  [CLEAR_DATA]: (state: ProgressState): ProgressState => ({
    ...initialState
  }),

  [RESTORE_DATA]: (state: ProgressState, { progress: restoreData }: StoreState): ProgressState => {
    return {
      ...state,
      activeStep: restoreData.activeStep || 0,
      processHasStarted: !!restoreData.processHasStarted
    };
  },

  [SELECT_PROGRESS_STEP]: (state: ProgressState, { activeStep }: ProgressAction): ProgressState => ({
    ...state,
    activeStep,
    processHasStarted: activeStep > 0
  }),

  [FINISH_PROCESS]: (state: ProgressState, action: ProgressAction) => ({
    ...state
  })
};

export const progress = createActionHandler(initialState, actionHandlers);
