import React from 'react';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import SeparatorComponent from '../separator';
import { SeparatorSize, Separator } from '../../store/recommendedSeparator/constants';
import Slider from '../slider';
import L from '../literal';

interface ChooseSeparatorProps {
  Ae: number;
  separators: Array<Separator>;
  onSelect: (Ae: number) => void;
}

export class ChooseSeparator extends React.Component<ChooseSeparatorProps> {
  selectSeparator = (separatorSize: SeparatorSize) => {
    const foundSeparator = this.props.separators.find((a) => a.separatorSize === separatorSize);
    this.props.onSelect(foundSeparator!.Ae);
  }

  sliderSelectSeparator = (value: number) => {
    if (value === 3) {
      this.selectSeparator('large');
    }
    if (value === 2) {
      this.selectSeparator('medium');
    }
    if (value === 1) {
      this.selectSeparator('small');
    }
  }

  render() {
    const { Ae, separators } = this.props;
    const selectedSliderValue = separators.findIndex(separator => separator.Ae === Ae) + 1;

    return (
      <div className="choose-separator">
        <div>
          <span className="choose-separator-title"><L>SELECT_SEPARATOR_SIZE</L></span>
          <Slider
            min={1}
            max={3}
            value={selectedSliderValue}
            onChange={this.sliderSelectSeparator}
          />
        </div>
        <div className="separators">
          {separators.map(separator => (
            <SeparatorComponent
              key={separator.separatorSize}
              onClick={() => this.selectSeparator(separator.separatorSize)}
              {...separator}
              isActive={separator.Ae === Ae}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default connect(
  ({ labTest, recommendedSeparator }: StoreState) => ({
    Ae: labTest.Ae,
    separators: recommendedSeparator.separators
  })
)(ChooseSeparator);
