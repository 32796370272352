import { useEffect } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../store";

const url = "https://www.googletagmanager.com/gtag/js?id=";

const GtmLoader = ({ gaTrackingId }) => {
  useEffect(() => {
    if (!gaTrackingId) {
      return;
    }

    const loaded = Boolean(document.querySelector(`script[src^="${url}"]`));
    if (loaded) {
      return;
    }

    const tag = document.createElement("script");
    tag.setAttribute("async", "");
    tag.setAttribute("src", url + gaTrackingId);
    document.getElementsByTagName("head")[0].appendChild(tag);
  }, [gaTrackingId]);

  return null;
};

export default connect((state: StoreState) => ({
  gaTrackingId: state.config.gaTrackingId,
}))(GtmLoader);
