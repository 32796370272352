import React from 'react';
import {
  getPrimaryLiquidProperties,
  getSecondaryLiquidProperties,
  getSolidProperties
} from '../../utilities/summary';
import PropertyList from '../propertyList';
import { ProcessDataState } from '../../store/processData/constants';
import { ProcessInfo } from '../../store/process/constants';
import { LiquidsIcon, SolidsIcon } from '../../assets/icons';
import L from '../literal';

interface PropertyListProps {
  layoutDirection?: 'horizontal-two-columns' | 'vertical';
}

interface SeparationPropertiesSummaryProps {
  activeProcess: ProcessInfo;
  processData: ProcessDataState;
  propertyListProps?: PropertyListProps;
}

const SeparationPropertiesSummary: React.SFC<SeparationPropertiesSummaryProps> = ({
  activeProcess,
  processData,
  propertyListProps
}) => (
  <>
    <h3 className="summary-header">
      {activeProcess.separateLiquids
        ? <L>PRIMARY_LIQUID_PROPERTIES</L>
        : <L>LIQUID_PROPERTIES</L>}
      <LiquidsIcon />
    </h3>
    <PropertyList
      {...propertyListProps}
      properties={getPrimaryLiquidProperties(processData)}
    />

    {activeProcess.separateLiquids && (
      <>
        <h3 className="summary-header">
          <L>SECONDARY_LIQUID_PROPERTIES</L>
          <LiquidsIcon />
        </h3>
        <PropertyList
          {...propertyListProps}
          properties={getSecondaryLiquidProperties(processData)}
        />
      </>
    )}

    {activeProcess.separateSolids && (
      <>
        <h3 className="summary-header">
          <L>SOLID_PROPERTIES</L>
          <SolidsIcon />
        </h3>
        <PropertyList
          {...propertyListProps}
          properties={getSolidProperties(processData)}
        />
      </>
    )}
  </>
);

export default SeparationPropertiesSummary;
