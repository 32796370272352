import { ThunkAction } from 'redux-thunk';
import { StoreState } from '..';
import {
  CLEAR_DATA,
  UPDATE_CLIENTCASE_FIELD,
  FETCH_SAVE_CLIENTCASE_SUCCESS,
  FETCH_SAVE_CLIENTCASE_ERROR,
  RESTORE_DATA,
  SHOW_LOADER,
  HIDE_LOADER
} from '../actionTypes';
import {
  CLIENTCASE_FIELDS,
  ClientCaseAction,
  SaveClientCaseAction
} from './constants';
import { showModal, closeModal } from '../modal/actions';
import { MODAL_TYPES } from '../modal/constants';
import { navigateToLastStep } from '../progress/actions';
import caseApi from '../../services/caseApi';
import { registerInteraction } from '../../utilities/analytics';

export default {
  clearData,
  loadCase,
  saveCase,
  saveOrShowModal,
  updateAcceptedTerms,
  updateAcceptedPrivacyPolicy,
  updateFirstName,
  updateLastName,
  updateCompany,
  updateEmailAddress,
  updatePhoneNumber
};

function updateField(property: CLIENTCASE_FIELDS, value: any) {
  return {
    type: UPDATE_CLIENTCASE_FIELD,
    property,
    value
  };
}

export function clearData(): ClientCaseAction {
  return {
    type: CLEAR_DATA
  };
}

export function saveOrShowModal(): ThunkAction<any, StoreState, any, any> {
  return (dispatch, getState) => {
    const { clientCase: { caseId } } = getState();

    if (caseId) {
      dispatch(saveCase());
    } else {
      dispatch(showModal(MODAL_TYPES.SAVE_DATA));
    }
  };
}

export function loadCase(caseId: string): ThunkAction<any, StoreState, any, any> {
  return (dispatch, getState) => {
    dispatch({ type: SHOW_LOADER });

    return caseApi
      .getCase(caseId)
      .then(
        ({ CaseType, CustomerInformation, Data, Aspect, Attachments }: any) => {
          const restoreData = {
            caseFiles: {
              attachments: Attachments.map(({ Id, Url, FileName }: any) => ({
                id: Id,
                url: Url,
                name: FileName
              }))
            },
            clientCase: {
              caseId
            },
            process: {
              activeProcessId: CaseType
            },
            progress: {
              activeStep: 0,
              processHasStarted: true
            },
            processAspects: Aspect,
            processData: Data
          };

          dispatch({ type: HIDE_LOADER });
          dispatch({ type: RESTORE_DATA, ...restoreData });
          dispatch(navigateToLastStep());
        }
      )
      .catch(error => {
        dispatch({ type: HIDE_LOADER });
        throw error;
      });
  };
}

export function saveCase(): ThunkAction<any, StoreState, any, any> {
  return (dispatch, getState) => {
    const { clientCase, labTest, process, processAspects, processData: processDataToSave } = getState();
    const { caseId } = clientCase;

    // Filter out the data that we don't want to save
    const {
      exStandardOptions,
      controlLocationOptions,
      ...processAspectsToSave
    } = processAspects;

    const {
      availableColors,
      reachedMaxTests,
      ...labTestToSave
    } = labTest;

    dispatch({ type: SHOW_LOADER });

    const payload = {
      CaseType: process.activeProcessId,
      ContactInformation: {
        FirstName: clientCase.firstName,
        LastName: clientCase.lastName,
        Email: clientCase.email,
        PhoneNumber: clientCase.phoneNumber,
        CompanyName: clientCase.companyName,
        AcceptedPrivacyPolicy: clientCase.acceptedPrivacyPolicy,
        AcceptedTerms: clientCase.acceptedTerms
      },
      Data: processDataToSave,
      Aspect: processAspectsToSave,
      LabTest: labTestToSave
    };

    (caseId ? caseApi.saveCase(caseId, payload) : caseApi.createCase(payload))
      .then(({ CaseId: updatedCaseId = caseId }) => {
        dispatch<SaveClientCaseAction>({
          type: FETCH_SAVE_CLIENTCASE_SUCCESS,
          caseId: updatedCaseId
        });
        dispatch(showModal(MODAL_TYPES.DATA_SAVED));
        // here we can register an event for 'Registration'
        registerInteraction('Registration','Selector');
      })
      .catch(() => {
        dispatch({ type: FETCH_SAVE_CLIENTCASE_ERROR });
        dispatch(closeModal());
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };
}

export function updateAcceptedPrivacyPolicy(acceptedPrivacyPolicy: boolean): ClientCaseAction {
  return updateField(CLIENTCASE_FIELDS.ACCEPTED_PRIVACY_POLICY, acceptedPrivacyPolicy);
}

export function updateAcceptedTerms(acceptedTerms: boolean): ClientCaseAction {
  return updateField(CLIENTCASE_FIELDS.ACCEPTED_TERMS, acceptedTerms);
}

export function updateFirstName(firstName: string): ClientCaseAction {
  return updateField(CLIENTCASE_FIELDS.FIRST_NAME, firstName);
}

export function updateLastName(lastName: string): ClientCaseAction {
  return updateField(CLIENTCASE_FIELDS.LAST_NAME, lastName);
}

export function updateCompany(company: string): ClientCaseAction {
  return updateField(CLIENTCASE_FIELDS.COMPANY, company);
}

export function updateEmailAddress(email: string): ClientCaseAction {
  return updateField(CLIENTCASE_FIELDS.EMAIL_ADDRESS, email);
}

export function updatePhoneNumber(phone: string): ClientCaseAction {
  return updateField(CLIENTCASE_FIELDS.PHONE, phone);
}
