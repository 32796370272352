import { DataPoint } from '../store/labTest/constants';
const { Matrix } = require('./ml-matrix'); // Important to use require here!

function jacobian(coefficients: any, input: DataPoint[]) {
  const J = input.reduce(
    (result: Array<Array<number>>, datapoint) => {
      result[0].push(datapoint.Q! ** coefficients.get(1, 0));
      result[1].push(
        coefficients.get(0, 0) * datapoint.Q! ** coefficients.get(1, 0) * Math.log(datapoint.Q!)
      );
      return result;
    },
    [[], []]
  );

  return new Matrix(J).transpose();
}

/**
 * Calculates the dot products for matrices with non matching row/columns
 */
function dot (matrixA: any, matrixB: any) {
  // Find the minimum dimensions for the result matrix that will contain the dot products
  const columns = Math.min(matrixA.columns, matrixB.columns);
  const rows = Math.min(matrixA.rows, matrixB.rows);

  const emptyDataset = new Array(rows).fill(new Array(columns).fill(0));

  const dotMatrix = emptyDataset.map(
    (row: Array<Array<number>>, rowOffset) =>
      row.map((column, columnOffset) => {
        const rowMatrix = Matrix.rowVector(matrixA.getRow(rowOffset));
        const columnMatrix = Matrix.columnVector(matrixB.getColumn(columnOffset));

        return rowMatrix.dot(columnMatrix);
      })
  );

  return new Matrix(dotMatrix);
}

export function nonlinearRegression(datapoints: DataPoint[], iterations: Number = 10) {
  const meanValues = datapoints.reduce(
    ({ x, y }: any, datapoint) => ({
      x: x + datapoint.Q!,
      y: y + datapoint.centrateQuality
    }),
    {
      x: 0,
      y: 0
    });

  const initialK1 = meanValues.y / meanValues.x;
  const initialK2 = 1;
  let coefficients = new Matrix([[initialK1], [initialK2]]); // Initial k1 & k2 values

  for (var iteration = 0; iteration < iterations; iteration++) {
    const residuals = datapoints.map(
      datapoint => datapoint.centrateQuality - coefficients.get(0, 0) * Math.pow(datapoint.Q!, coefficients.get(1, 0)) // eslint-disable-line no-loop-func
    );

    // const square = residuals.reduce((res, r) => res + r ** 2, 0);

    const jacobianMatrix = jacobian(coefficients, datapoints);
    const transposedResiduals = new Matrix([residuals]).transpose();

    coefficients = coefficients.add(
      dot(jacobianMatrix.pseudoInverse(), transposedResiduals)
    );
  }

  const k1 = coefficients.get(0, 0);
  const k2 = coefficients.get(1, 0);

  return [
    { time: 0, Q: 0, centrateQuality: 0 }, // Add a starting point at  0,0
    ...datapoints.map(({ time, Q }) => ({
      time,
      Q,
      centrateQuality: k1 * Math.pow(Q!, k2)
    }))
  ];
}
