import React from 'react';
import classNames from 'classnames';
import { FormElement, FormElementProps } from '../formElement';

interface TextBoxProps extends FormElementProps {
  onChange?: (value: string) => any;
  id?: string;
  value?: string;
  placeholder?: string;
  isInvalid?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

const TextBox: React.SFC<TextBoxProps> = ({
  id,
  onChange,
  value,
  placeholder,
  isInvalid,
  disabled,
  readOnly,
  ...restProps
}) => (
  <FormElement {...restProps} elementClassName={classNames('textfield', { invalid: isInvalid })}>
    <input
      id={id}
      value={value}
      placeholder={placeholder}
      className="native-textbox shaded-box"
      disabled={disabled}
      readOnly={readOnly}
      onChange={e => onChange!(e.target.value)}
    />
  </FormElement>
);

TextBox.defaultProps = {
  onChange: () => null
};

export default TextBox;
