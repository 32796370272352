import React from 'react';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Ionicon from 'react-ionicons';
import ProgressActions from '../../store/progress/actions';
import { StoreState } from '../../store';
import L from '../literal';

interface ProgressStepperProps {
  actions: {
    nextStep: () => any;
    previousStep: () => any;
    finalizeProcess: () => any;
  };
  allowFinalizing?: boolean;
  isOnFinalStep: boolean;
  isPreSummaryStep: boolean;
  isSeparationTaskSelected: boolean;
  showBackButton: boolean;
  showNextButton: boolean;
}

export const ProgressStepper: React.SFC<ProgressStepperProps> = ({
  actions,
  allowFinalizing,
  isOnFinalStep,
  isPreSummaryStep,
  isSeparationTaskSelected,
  showBackButton,
  showNextButton
}) => (
  <div className="progress-stepper">
    <div className="container">
      <button
        className={classNames('smallButton back', { invisible: !showBackButton })}
        onClick={actions.previousStep}
      >
        <Ionicon icon="md-arrow-back" />
        <L>BACK</L>
      </button>

      {!isOnFinalStep && (
        <button
          disabled={!isSeparationTaskSelected}
          className={classNames('smallButton next', {
            invisible: !showNextButton,
            'pre-step': isPreSummaryStep
          })}
          onClick={actions.nextStep}

        >
          {isPreSummaryStep ? <L>VIEW_YOUR_SUMMARY</L> : <L>NEXT</L>}
          <Ionicon icon="md-arrow-forward" />
        </button>
      )}

      {isOnFinalStep && (
        <button
          className="btn-blue smallButton finalize"
          disabled={!allowFinalizing}
          onClick={actions.finalizeProcess}
        >
          <L>SEND_DATA_TO_ALFA_LAVAL</L>
          <Ionicon icon="md-arrow-forward" />
        </button>
      )}
    </div>
  </div>
);

export default connect(
  ({
    process,
    processData,
    progress
  }: StoreState) => ({
    isOnFinalStep: progress.activeStep === progress.steps.length - 1,
    isPreSummaryStep: progress.activeStep === progress.steps.length - 2,
    isSeparationTaskSelected: !!process.activeProcessId,
    showBackButton: progress.activeStep > 0,
    showNextButton: progress.activeStep < progress.steps.length - 1,
  }),
  dispatch => ({
    actions: bindActionCreators(ProgressActions, dispatch)
  })
)(ProgressStepper);
