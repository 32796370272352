import React from 'react';
import classNames from 'classnames';
import { LabTest, MAX_LABTESTS } from '../../store/labTest/constants';
import DataList from '../../components/dataList';
import TimeUnit from '../../components/timeUnit';

interface AnalysisTableProps {
  allowDelete?: boolean;
  className?: string;
  datapointRange: Array<number>;
  datapointRangeUnit: string;
  onRemoveTest: (test: LabTest) => any;
  tests: Array<LabTest>;
}

const AnalysisTable: React.SFC<AnalysisTableProps> = ({
  allowDelete,
  className,
  datapointRange,
  datapointRangeUnit,
  onRemoveTest,
  tests
}) => {
  const fillerData = new Array(datapointRange.length).fill({});
  const showfiller = tests.length < MAX_LABTESTS;
  return (
    <div className={classNames('analysis-table', className)} data-length={tests.length}>
      {tests.map(test => (
        <DataList
          key={test.id}
          className={`test-data ${test.colorId}`}
          showDelete={allowDelete && !test.active}
          onRemove={() => onRemoveTest(test)}
          title={test.name}
          subTitle={test.dateOfTesting}
          itemPrefix={index => <TimeUnit value={datapointRange[index]} unit={datapointRangeUnit} />}
          unit={test.unit}
          dataKey="centrateQuality"
          dataset={test.dataPoints.slice().reverse()}
        />
      ))}
      {showfiller && <DataList className="data-list-filler" dataset={fillerData} dataKey="centrateQuality" />}
    </div>
  );
};

AnalysisTable.defaultProps = {
  allowDelete: true
};

export default AnalysisTable;
