import React from 'react';
import classNames from 'classnames';
import Ionicon from 'react-ionicons';
import Dropzone from 'react-dropzone';
import { Attachment } from '../../store/caseFiles/constants';
import { downloadFile } from '../../utilities/summary';
import L from '../literal';
import useLiteral from '../../hooks/useLiteral';

interface FileUploadProps {
  actions: {
    addFiles: (files: File[]) => any,
    removeFile: (id: string) => any
  };
  disallowFileUploads: boolean;
  files: Attachment[];
}

const FileUpload: React.SFC<FileUploadProps> = ({
  actions,
  files,
  disallowFileUploads
}) => {
  const uploadsLabel = useLiteral('FILE_UPLOAD_UPLOADS_LABEL')
  const uploadsLabelMobile = useLiteral('FILE_UPLOAD_UPLOADS_LABEL_MOBILE')

  return (
    <div className="file-upload">
      <div className="file-upload-drop-section">
        <Dropzone
          className="file-upload-drop"
          multiple={true}
          disabled={disallowFileUploads}
          onDrop={actions.addFiles}
        >
          <button className="smallButton upload-button" disabled={disallowFileUploads}>
            <L>FILE_UPLOAD_BUTTON</L>
          </button>
          <L>FILE_UPLOAD_DROP</L>
          <br />
          .xlsx, .png, .jpg, .pdf, .pptx
        </Dropzone>
      </div>

      <div className="file-upload-files-section">
        {!!files.length && (
          <ul className="file-upload-files" data-label={uploadsLabel} data-mobile-label={uploadsLabelMobile}>
            {files.map(file => (
              <li key={file.id} className={classNames('file-upload-file', { loading: file.isLoading })}>
                <span
                  className="file-upload-filename"
                  onClick={() => downloadFile(file)}
                >
                  {file.name}
                </span>
                <Ionicon
                  color="currentColor"
                  icon={file.isLoading ? 'md-sync' : 'md-close'}
                  onClick={() => !file.isLoading && actions.removeFile(file.id)}
                />
              </li>
            ))}
          </ul>
        )}
        {!files.length && (
          <div className="file-upload-no-files">
            <Ionicon
              className="no-uploaded-files-icon"
              color="currentColor"
              icon="ios-information-circle-outline"
            />
            <L>FILE_UPLOAD_NO_UPLOADS</L>
          </div>
        )}
      </div>
    </div>
  );
}

export default FileUpload;
