import React from 'react';
import { Link } from 'react-router-dom';
import Ionicon from 'react-ionicons';
import L from '../literal';

export class DontHaveSepData extends React.Component<{}, { showmore: any }> {
  constructor(props: any) {
    super(props);
    this.state = { showmore: false };
  }
  showMore = () => {
    this.setState({ showmore: true });
  }
  showLess = () => {
    this.setState({ showmore: false });
  }
  render() {
    return (
      <>
        <div className="darker-section accordion__item">
          <div className="col-md-12">
            <h3>
              <L>NO_SEP_DATA__TITLE</L>
            </h3>
            {!this.state.showmore && (
              <>
              <div className="start-text">
                <p>
                  <L>NO_SEP_DATA__INFO_PREVIEW</L>
                </p>
                </div>
                <div className="show-more-block">
                  <button
                    onClick={this.showMore}
                    className={
                      this.state.showmore
                        ? 'smaller-btn current show-more'
                        : 'smaller-btn show-more'
                    }
                  >
                    <L>SHOW_MORE</L>
                  </button>
                </div>
              </>
            )}
            {this.state.showmore && (
              <>
                <p>
                  <L preserveWraps>NO_SEP_DATA__INFO</L>
                </p>
                <div className="show-more-block">
                  <button
                    onClick={this.showLess}
                    className={
                      this.state.showmore
                        ? 'smaller-btn current show-more'
                        : 'smaller-btn show-more'
                    }
                  >
                    <L>SHOW_LESS</L>
                  </button>
                </div>
              </>
            )}
            <Link className="link-blue" to="/get-separability-data">
              <Ionicon icon="md-arrow-back" />
              <L>NO_SEP_DATA__GET_SEPARABILITY_DATA</L>
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default DontHaveSepData;
