import { SELECT_SEPARATION_TASK, CLEAR_SEPARATION_TASK } from '../actionTypes';
import { ThunkAction } from 'redux-thunk';
import { StoreState } from '..';
import { SelectSeparationTaskProcessAction, ProcessType } from './constants';
import { Action } from 'redux';

export default {
  clearSeparationProcess,
  selectSeparationProcess
};

export function clearSeparationProcess(): Action {
  return {
    type: CLEAR_SEPARATION_TASK
  };
}

export function selectSeparationProcess(activeProcessId: ProcessType): ThunkAction<any, StoreState, any, any> {
  return (dispatch, getState) => {
    const { process } = getState();
    const activeProcess = process.processes.find(({ id }) => id === activeProcessId);

    if (activeProcess) {
      dispatch<SelectSeparationTaskProcessAction>({
        type: SELECT_SEPARATION_TASK,
        activeProcessId,
        activeProcess
      });
    } else {
      dispatch(clearSeparationProcess());
    }
  };
}
