import store, { StoreState } from '../store';
import { post, get, put, restDelete, postFile } from '../utilities/net';

const getApiUrl = () => (store.getState() as StoreState).config.apiUrl;
// const getApiUrl = () => 'http://localhost:49247'

export default {
  getCase,
  getAlfaSep,
  createCase,
  saveCase,
  uploadCaseFile,
  deleteCaseFile,
  finalizeProcess
};

export function getCase(caseId: string) {
  return get(`${getApiUrl()}/case/${caseId}`);
}

export function getAlfaSep(particleDensity: number, fluidDensity: number, fluidViscosity: number, flowRate: number, Ae: number) {
  return get(`${getApiUrl()}/alfa/getAlfas?particleDensity=${particleDensity}&fluidDensity=${fluidDensity}&fluidViscosity=${fluidViscosity}&flowRate=${flowRate}&Ae=${Ae}`);
}

export function createCase(payload: any) {
  return post(`${getApiUrl()}/case`, JSON.stringify(payload));
}

export function finalizeProcess(caseId: string) {
  return post(`${getApiUrl()}/case/${caseId}/sendEmail`, null);
}

export function saveCase(caseId: string, payload: any) {
  return put(`${getApiUrl()}/case/${caseId}`, JSON.stringify(payload));
}

export function uploadCaseFile(caseId: string, file: File) {
  const formData = new FormData();
  formData.append('upfiles', file);

  return postFile(`${getApiUrl()}/case/${caseId}/upload`, formData)
    .then(({ Attachment: { Url, FileName, Id } }) => ({
      id: Id,
      url: Url,
      name: FileName
    }));
}

export function deleteCaseFile(caseId: string, fileId: string) {
  return restDelete(`${getApiUrl()}/case/${caseId}/deleteAttachment`, JSON.stringify({
    FileId: fileId
  }));
}
