import { createBrowserHistory } from 'history';
import store, { StoreState } from './store';
import { MODAL_OPEN } from './store/actionTypes';
import { ModalAction, MODAL_TYPES } from './store/modal/constants';

const processRoutes = [
  '/separation-process',
  '/process-selection',
  '/lab-test'
];

const containsRoute = (routes: string[], path: string): boolean => !!routes.find(route => path.includes(route));

const routerHistory = createBrowserHistory({
  getUserConfirmation: (targetPathName, allowRedirect) => {
    const isCurrentlyInsideProcessRoute = containsRoute(processRoutes, routerHistory.location.pathname);
    const isNavigatingAwayFromProcessRoute = !containsRoute(processRoutes, targetPathName);

    if (
      isCurrentlyInsideProcessRoute
      && isNavigatingAwayFromProcessRoute
      && (store.getState() as StoreState).app.contentIsDirty) {
      // Content is dirty and we're trying to navigate away
      store.dispatch<ModalAction>({
        type: MODAL_OPEN,
        modalType: MODAL_TYPES.LEAVE_PAGE,
        modalArguments: targetPathName
      });

      allowRedirect(false);
    } else {
      allowRedirect(true);
    }
  }
});

export default routerHistory;
