export const replacePlaceholders = (
  translation: string,
  replacements: {}
): string | React.ReactNode[] => {
  const strings = translation.split(/{[^}]+}/);
  const placeholders = translation.match(/{[^}]+}/g);

  const result = [strings[0]];
  let onlyStrings = true;
  for (let i = 1; i < strings.length; i++) {
    const placeholderKey = placeholders![i - 1].replace(/[{}]/g, "");
    result.push(replacements[placeholderKey]);
    result.push(strings[i]);

    if (typeof replacements[placeholderKey] !== "string") {
      onlyStrings = false;
    }
  }

  return onlyStrings ? result.join("") : result;
};
