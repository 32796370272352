import { UPDATE_PROCESSDATA_FIELD } from '../actionTypes';
import { PROCESSDATA_FIELDS, UpdateProcessDataFieldAction } from './constants';

export default {
  updateOtherPerformanceAspects,
  updateParticleSize,
  updatePrimaryLiquidCorrosive,
  updatePrimaryLiquidDensity,
  updatePrimaryLiquidDensityMeasuredAtTemperature,
  updatePrimaryLiquidDistribution,
  updatePrimaryLiquidDropletSize,
  updatePrimaryLiquidProcessPressure,
  updatePrimaryLiquidProcessTemperature,
  updatePrimaryLiquidProductName,
  updatePrimaryLiquidTotalFlowRate,
  updatePrimaryLiquidViscosity,
  updatePrimaryLiquidViscosityMeasuredAtTemperature,
  updateSecondaryLiquidConcentration,
  updateSecondaryLiquidConcentrationAfterSeparation,
  updateLiquidConcentrationUnit,
  updateSecondaryLiquidDensity,
  updateSecondaryLiquidDensityMeasuredAtTemperature,
  updateSecondaryLiquidDistribution,
  updateSecondaryLiquidDropletSize,
  updateSecondaryLiquidProductName,
  updateSolidConcentrationAfterSeparation,
  updateSolidConcentrationUnit,
  updateSolidConcentrationInFeed,
  updateSolidDensity,
  updateSolidDistribution,
  updateSolidDropletSize,
  updateSolidKnownToBeErosive,
  updateSolidsType,
  updateCustomizedAe
};

function updateField(property: PROCESSDATA_FIELDS, value: any) {
  return {
    type: UPDATE_PROCESSDATA_FIELD,
    property,
    value
  };
}

export function updatePrimaryLiquidDistribution (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.PRIMARY_LIQUID_DISTRIBUTION, value);
}

export function updatePrimaryLiquidDropletSize (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.PRIMARY_LIQUID_DROPLET_SIZE, value);
}

export function updateOtherPerformanceAspects (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.OTHER_PERFORMANCE_ASPECTS, value);
}

export function updateParticleSize (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.PARTICLE_SIZE, value);
}

export function updatePrimaryLiquidCorrosive (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.PRIMARY_LIQUID_CORROSIVE, value);
}

export function updatePrimaryLiquidDensity (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.PRIMARY_LIQUID_DENSITY, value);
}

export function updatePrimaryLiquidDensityMeasuredAtTemperature (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.PRIMARY_LIQUID_DENSITY_MEASURED_AT_TEMPERATURE, value);
}

export function updatePrimaryLiquidProcessPressure (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.PRIMARY_LIQUID_PROCESS_PRESSURE, value);
}

export function updatePrimaryLiquidProcessTemperature (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.PRIMARY_LIQUID_PROCESS_TEMPERATURE, value);
}

export function updatePrimaryLiquidProductName (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.PRIMARY_LIQUID_PRODUCT_NAME, value);
}

export function updatePrimaryLiquidTotalFlowRate (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.PRIMARY_LIQUID_TOTAL_FLOWRATE, value);
}

export function updatePrimaryLiquidViscosity (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.PRIMARY_LIQUID_VISCOSITY, value);
}

export function updatePrimaryLiquidViscosityMeasuredAtTemperature (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.PRIMARY_LIQUID_VISCOSITY_MEASURED_AT_TEMPERATURE, value);
}

export function updateSecondaryLiquidConcentration (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.SECONDARY_LIQUID_CONCENTRATION, value);
}

export function updateSecondaryLiquidConcentrationAfterSeparation (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.SECONDARY_LIQUID_CONCENTRATION_AFTER_SEPARATION, value);
}

export function updateLiquidConcentrationUnit (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.LIQUID_CONCENTRATION_UNIT, value);
}

export function updateSecondaryLiquidDensity (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.SECONDARY_LIQUID_DENSITY, value);
}

export function updateSecondaryLiquidDensityMeasuredAtTemperature (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.SECONDARY_LIQUID_DENSITY_MEASURED_AT_TEMPERATURE, value);
}

export function updateSecondaryLiquidDistribution (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.SECONDARY_LIQUID_DISTRIBUTION, value);
}

export function updateSecondaryLiquidDropletSize (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.SECONDARY_LIQUID_DROPLET_SIZE, value);
}

export function updateSecondaryLiquidProductName (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.SECONDARY_LIQUID_PRODUCT_NAME, value);
}

export function updateSolidConcentrationAfterSeparation (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.SOLID_CONCENTRATION_AFTER_SEPARATION, value);
}

export function updateSolidConcentrationUnit (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.SOLID_CONCENTRATION_UNIT, value);
}

export function updateSolidConcentrationInFeed (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.SOLID_CONCENTRATION_INFEED, value);
}

export function updateSolidDensity (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.SOLID_DENSITY, value);
}

export function updateSolidDistribution (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.SOLID_DISTRIBUTION, value);
}

export function updateSolidDropletSize (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.SOLID_DROPLET_SIZE, value);
}

export function updateSolidKnownToBeErosive (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.SOLID_KNOWN_TO_BE_EROSIVE, value);
}

export function updateSolidsType (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.SOLIDS_TYPE, value);
}

export function updateCustomizedAe (value: any): UpdateProcessDataFieldAction {
  return updateField(PROCESSDATA_FIELDS.CUSTOMIZED_AE, value);
}
