import React from 'react';
import RcCheckbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import ReactTooltip from 'react-tooltip';
import Ionicon from 'react-ionicons';
import { guid } from '../../utilities/guid';

const getLayout = (layout: string) => {
  return 'col-md-12';
};
export class Checkbox extends React.Component<any> {
  myId: string = guid();
  handleChange = (v: any) => {
    if (this.props.change !== undefined) {
      this.props.change(!this.props.value);
    }
  }
  render() {
    return (
      <>
        <div className={getLayout(this.props.layout)}>
          <div className="container">
            <ReactTooltip
              id={this.myId}
              type="dark"
              effect="solid"
            >
              <div className="tooltipBox">
                {this.props.tooltip && this.props.tooltip()}
              </div>
            </ReactTooltip>
            <div className="cb-wrapper">
                <label className="checkBox">
                  <RcCheckbox
                    id="st"
                    name="st"
                    checked={this.props.value}
                    onChange={this.handleChange}
                  />
                </label>
                <label onClick={this.handleChange} className="checkBoxLabel">
                  <span>{this.props.title}</span>
                </label>
              {this.props.tooltip && (
                <div className="cb">
                  <span
                    className="tooltip-wrapper"
                    data-tip={true}
                    data-for={this.myId}
                  >
                    <Ionicon
                      className="tooltip-question-icon"
                      icon="md-help-circle"
                    />
                  </span>
                </div>
              )}
            </div>
            </div>
        </div>
      </>
    );
  }
}
