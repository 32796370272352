import React from 'react';
import classNames from 'classnames';
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';
import Ionicon from 'react-ionicons';
import { LabTest } from '../../store/labTest/constants';
import L from '../../components/literal';

const dotOptions = { r: 6 };
const chartMargins = { top: 20, right: 20, left: 10, bottom: 20 };

interface LabTestGraphProps {
  Ae: number;
  className?: string;
  isSpinTest: boolean;
  tests: LabTest[];
}

const LabTestGraph: React.SFC<LabTestGraphProps> = ({ className, tests }) => (
  <div className={classNames('labtest-graph', className)}>
    <ResponsiveContainer>
      <LineChart margin={chartMargins}>
        <XAxis dataKey="Q" type="number">
          <Label value="Q (m³/h)" position="bottom" />
        </XAxis>
        <YAxis>
          <Label
            className="y-axis"
            content={(attributes: any) =>
              <text textAnchor="middle" transform={`translate(15,${attributes.viewBox.height / 2 + chartMargins.top}) rotate(-90)`}>
                <L replacements={{ unit: tests[0].unit }}>LAB_TEST__TEST_ANALYSIS__GRAPH_Y_AXIS_LABEL</L>
              </text>}
          />
        </YAxis>
        <CartesianGrid strokeDasharray="3 3" />

        {/* Lab test - Notice the old fragment syntax since recharts doesn't support React.Fragment */}
        {tests.map(({ id, colorId, dataPoints, trendDataPoints }) => [
          <Line
            key={`trend${id}`}
            dot={null}
            className={classNames('trend', colorId)}
            data={trendDataPoints}
            dataKey="centrateQuality"
            type="monotoneX"
            strokeDasharray="3 3"
          />,
          <Line
            key={id}
            isAnimationActive={false}
            className={colorId}
            dot={dotOptions}
            data={dataPoints}
            dataKey="centrateQuality"
            type="monotoneX"
          />
        ])}
      </LineChart>
    </ResponsiveContainer>
    {!tests.length && (
      <div className="empty-graph-info">
        <Ionicon
          color="currentColor"
          icon="ios-information-circle-outline"
        />
        <span>
          <L>LAB_TEST__TEST_ANALYSIS__GRAPH_PLACEHOLDER</L>
        </span>
      </div>
    )}
  </div>
);

export default LabTestGraph;
