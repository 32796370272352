import { LOAD_CONFIG } from '../actionTypes';
import { ConfigState, LoadConfigAction } from './constants';
import createActionHandler from '../createActionHandler';

const initialState: ConfigState = {
};

const actionHandlers = {
  [LOAD_CONFIG]: (state: ConfigState, { config }: LoadConfigAction): ConfigState => ({
    ...state,
    ...config
  }),
};

export const config = createActionHandler(initialState, actionHandlers);
