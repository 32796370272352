import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LabTestActions from '../../store/labTest/actions';
import CaseFileActions from '../../store/caseFiles/actions';
import ModalActions from '../../store/modal/actions';
import pageComponent, { SummaryProps } from './page';
import { StoreState } from '../../store';

export default connect(
  ({ clientCase, labTest, process, processData, processAspects, recommendedSeparator, caseFiles }: StoreState) => ({
    activeProcess: process.activeProcess,
    allowFinalizing: !!clientCase.caseId,
    caseFiles,
    clientCase,
    disallowFileUploads: !clientCase.caseId || caseFiles.attachments.length >= caseFiles.maxAttachments,
    hasLabTests: labTest.tests.length > 0,
    labTest,
    processAspects,
    processData,
    recommendedSeparator
  }) as SummaryProps,
  dispatch => ({
    labTestActions: bindActionCreators(LabTestActions, dispatch),
    modalActions: bindActionCreators(ModalActions, dispatch),
    caseFileActions: bindActionCreators(CaseFileActions, dispatch)
  })
)(pageComponent);
