import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { StoreState } from "../../store";
import ConfigActions from "../../store/config/actions";
import LanguageActions from "../../store/language/actions";
import { getConfig, getTranslations } from "../../services/configApi";

interface ConfigLoaderProps {
  fallback: any;
  error: any;
  children: any;
  languageCode: string;
  loaded: boolean;
  loadConfig: (config) => any;
  loadTranslations: (translations) => any;
}

const ConfigLoader: React.SFC<ConfigLoaderProps> = ({
  fallback,
  error,
  children,
  languageCode,
  loaded,
  loadConfig,
  loadTranslations,
}) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        // Must be run sequentially, due to API URL being loaded in getConfig
        loadConfig(await getConfig());
        loadTranslations(await getTranslations(languageCode));
      } catch {
        setHasError(true);
      }
    })();
  }, [languageCode]); // eslint-disable-line react-hooks/exhaustive-deps

  if (hasError) {
    return error;
  }

  return loaded ? children : fallback;
};

export default connect(
  ({ language }: StoreState) => ({
    languageCode: language.languageCode,
    loaded: !!language.translations,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        loadConfig: (config) => ConfigActions.loadConfig(config),
        loadTranslations: (translations) =>
          LanguageActions.loadTranslations(translations),
      },
      dispatch
    )
)(ConfigLoader);
