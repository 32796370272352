import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClientCaseActions from '../../store/clientCase/actions';

interface LoadClientCaseProps extends RouteComponentProps<any> {
  actions: typeof ClientCaseActions;
}

class LoadClientCase extends React.PureComponent<LoadClientCaseProps> {
  componentDidMount() {
    const { actions, match: { params } } = this.props;
    const { caseId } = params;

    actions.loadCase(caseId);
  }

  render() {
    return null;
  }
}

export default connect(
  null,
  dispatch => ({
    actions: bindActionCreators(ClientCaseActions, dispatch)
  })
)(LoadClientCase);
