import React from 'react';
import TextArea from '../../components/textArea';
import Checkbox from '../../components/checkbox';
import CheckboxList from '../../components/checkboxList';
import Section from '../../components/section';
import ProcessAspectActions from '../../store/processAspect/actions';
import { ProcessAspectState } from '../../store/processAspect/constants';
import L from '../../components/literal';
import useLiteral from '../../hooks/useLiteral';

interface ProcessAspectsProps extends ProcessAspectState {
  actions: typeof ProcessAspectActions;
}

const ProcessAspects: React.SFC<ProcessAspectsProps> = ({
  actions,
  installationEnvironmentConditions,
  processDescription,
  isHazardousArea,
  selectedExStandards,
  selectedControlLocations,
  exStandardOptions,
  controlLocationOptions
}) => {
  const briefProcessDescriptionTooltip = useLiteral('BRIEF_PROCESS_DESCRIPTION_HELP')
  const installationAspectsTooltip = useLiteral('INSTALLATION_ASPECTS_HELP')

  return (
    <div className="container">
      {/* Remove this wrapper when the other forms have been fixed */}
      <Section
        title={<L>SPECIFY_PROCESS_ASPECTS</L>}
        className="main-section"
        isCollapsible={false}
      >
        <TextArea
          label={<L>BRIEF_PROCESS_DESCRIPTION</L>}
          value={processDescription}
          onChange={actions.updateDescription}
          tooltip={briefProcessDescriptionTooltip}
        />
        <TextArea
          label={<L>INSTALLATION_ASPECTS</L>}
          value={installationEnvironmentConditions}
          onChange={actions.updateInstallationAspect}
          tooltip={installationAspectsTooltip}
        />
        <div className="checkbox-wrapper">
          <Checkbox
            label={<L>HAZARDOUS_AREA</L>}
            checked={isHazardousArea}
            onChange={actions.updateIsHazardousArea}
            tooltip={<L>HAZARDOUS_AREA_HELP</L>}
          />
        </div>

        {isHazardousArea && (
          <Section
            className="secondary-section"
            title={<L>HAZARDOUS_AREA_REQUIREMENTS</L>}
          >
            <CheckboxList
              label={<L>SPECIFY_EX_STANDARD_APPLIED</L>}
              options={exStandardOptions}
              values={selectedExStandards}
              onChange={actions.updateSelectedExStandards}
            />

            <CheckboxList
              label={<L>LIKELY_CONTROLS_LOCATION</L>}
              options={controlLocationOptions}
              values={selectedControlLocations}
              onChange={actions.updateSelectedControlLocations}
            />
          </Section>
        )}
      </Section>
    </div>
  );
}

export default ProcessAspects;
