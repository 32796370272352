import React from 'react';
import { getPerformanceTarget } from '../../utilities/summary';
import PropertyList from '../propertyList';
import { ProcessDataState } from '../../store/processData/constants';
import { ProcessInfo } from '../../store/process/constants';
import L from '../literal';

interface PropertyListProps {
  layoutDirection?: 'horizontal-two-columns' | 'vertical';
  propertyLayout?: 'flat' | 'stacked' | 'stacked-indented';
}

interface PerformanceTargetSummaryProps {
  activeProcess: ProcessInfo;
  className?: string;
  includeOtherPerformanceAspects?: boolean;
  processData: ProcessDataState;
  propertyListProps?: PropertyListProps;
  separabilityPropertyListProps?: PropertyListProps;
  showHeader?: boolean;
}

const PerformanceTargetSummary: React.SFC<PerformanceTargetSummaryProps> = ({
  activeProcess,
  className,
  includeOtherPerformanceAspects,
  processData,
  propertyListProps,
  separabilityPropertyListProps,
  showHeader
}) => (
  <>
    {showHeader && <h3 className="summary-header"><L>PERFORMANCE_TARGET</L></h3>}
    <PropertyList
      {...propertyListProps}
      properties={getPerformanceTarget(activeProcess, processData, { includeOtherPerformanceAspects })}
    />
  </>
);

PerformanceTargetSummary.defaultProps = {
  showHeader: true
};

export default PerformanceTargetSummary;
