import React from 'react';

const storageKey = 'cookies-approved'

class CookieConsent extends React.Component<{}, { approved: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = {
      approved: Boolean(localStorage.getItem(storageKey))
    };
  }

  onClick = () => {
    localStorage.setItem(storageKey, true.toString())
    this.setState({
      approved: true
    });
  }

  render() {
    if (this.state.approved) {
      return null;
    }
    return (
      <div className="cookieConsent">
        <div>
          <div>
            We use Cookies - by using this site or closing this message you are
            agreeing to our cookies policy. <a
              target="_blank" // eslint-disable-line react/jsx-no-target-blank
              href="http://www.alfalaval.com/legal/cookies-policy"
              rel="noopener"
            >
              Read more
            </a>
          </div>
          <button onClick={this.onClick}>OK</button>
        </div>
      </div>
    );
  }
}

export default CookieConsent;
