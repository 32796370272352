import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../store/processData/actions';
import pageComponent from './page';
import { StoreState } from '../../store';

export default connect(
  (state: StoreState) => ({
    ...state.processData
  }),
  dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
)(pageComponent);
